import { useDispatch } from "react-redux";
import InputField from "../forms/InputField";
import {
  addBeneficiary,
  addTransferCountry,
  setBeneFound,
} from "../../store/features/Transfer/TransferSlice";
import * as Sentry from "@sentry/browser";

import { toast } from "material-react-toastify";
import { buildString } from "../../utilities/help";
import { addContact } from "../../store/features/Auth/Auth";
import { useState } from "react";

const AddBeneficiary = (props: any) => {
  const {
    t,
    typeOfUser,
    handleTypeOfUserSelection,
    selectedContactType,
    setContactDetails,
    contacts_details,
    setCompanyContactDetails,
    company_contacts_details,
    loading,
    setPhone,
    setCountry,
    transfer,
    country,
    phone,
    setLoading,
    setEmailError,
  } = props;

  const dispatch = useDispatch();

  const removeBeneficiary = () => {
    dispatch(addBeneficiary(null));
    dispatch(addTransferCountry(null));
    dispatch(setBeneFound(false));
    setPhone("");
    setCountry({ dialCode: "" });
  };

  const createNewContact = () => {
    try {
      const selectedUser: any = typeOfUser.find((user: any) => user.active);

      const { first_name, last_name, email } = contacts_details;
      const {
        company_name,
        first_name: company_first_name,
        last_name: company_last_name,
        email: company_email,
      } = company_contacts_details;

      const contact_country = transfer.danaPayCountries.find(
        (val: any) => val.country_code === country["dialCode"]
      );

      if (
        (contact_country?.preferred_notification_channel === "mail" &&
          !/^\S+@\S+\.\S+$/.test(email) &&
          selectedUser.value == "individual") ||
        (contact_country?.preferred_notification_channel === "mail" &&
          !/^\S+@\S+\.\S+$/.test(company_email) &&
          selectedUser.value == "business")
      ) {
        toast.error(t("email_not_valid"));
        return;
      } else {
        setEmailError("");
      }

      if (
        (selectedUser.value == "individual" &&
          (last_name.length === 0 || first_name.length === 0)) ||
        (selectedUser.value == "business" &&
          (company_first_name.length === 0 ||
            company_last_name.length === 0 ||
            company_name.length === 0))
      ) {
        toast.error(t("fields_required"));
        return;
      }

      let payload_data: any;

      if (selectedUser.value == "individual") {
        payload_data = {
          first_name,
          last_name,
          email,
          is_individual: true,
          country_code: country["dialCode"],
          phone_number: buildString(phone) || "",
        };
      } else {
        payload_data = {
          first_name: company_first_name,
          last_name: company_last_name,
          email: company_email,
          company_name,
          is_individual: false,
          country_code: country["dialCode"],
          phone_number: buildString(phone) || "",
        };
      }

      if (!payload_data["email"]) {
        delete payload_data["email"];
      }
      setLoading(true);
      addContact(payload_data)
        .then((data: any) => {
          setLoading(false);
          dispatch(setBeneFound(true));
          dispatch(addBeneficiary(data.beneficiary));
          dispatch(addTransferCountry(data.transferCountry));
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          setLoading(false);
          if (error.status === 422 || error.status === 500) {
            const err: any = Object.values(error.data.errors)[0];
            toast.error(err[0]);
          }
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="row flex justify-center">
      <div className="shadow-lg p-4 col-md-6">
        <div className="flex flex-col mb-1 rounded-lg items-center justify-center">
          <h3 className="text-2xl font-bold">{t("beneficiary_details")}</h3>
        </div>

        <small className="block !text-center text-gray-400">
          {t("new_contact")}
        </small>

        {/*  new ui */}
        <label className="mt-3">
          <small>{t("Account_Type")}</small>
        </label>
        <div className="bacnkCheckBoxWrapper bacnkCheckBoxWrapper flex flex-col gap-2 p-2  ">
          {typeOfUser.map((usertype: any, index: any) => {
            return (
              <div
                key={index}
                className="wrapperBankCheckBox m-0 h-[50px] innerBankBox"
              >
                <div
                  className="wrapperBankCheckBoxwrapper"
                  style={{ height: "30px" }}
                >
                  <input
                    type="checkbox"
                    className="default:ring-2 m-0"
                    name={usertype.value}
                    style={{ margin: 0, padding: 0 }}
                    onChange={(e: any) => {
                      handleTypeOfUserSelection(usertype.value);
                    }}
                    checked={usertype.active}
                  />
                  <span className="checkmark"></span>
                </div>
                <label>{t(usertype?.title)}</label>
              </div>
            );
          })}
        </div>

        {/* individual menu */}
        {(selectedContactType == "" || selectedContactType == "individual") && (
          <>
            <InputField
              extraClasses="mb-3 customInputStyle"
              name="firstname"
              handleChange={(e: any) => {
                setContactDetails((prev: any) => {
                  return { ...prev, first_name: e.target.value };
                });
              }}
              placeholder={t("FirstName")}
              value={contacts_details.first_name}
              label={t("FirstName")}
            />
            <InputField
              extraClasses="mb-3 customInputStyle"
              name="lastname"
              handleChange={(e: any) => {
                setContactDetails((prev: any) => {
                  return { ...prev, last_name: e.target.value };
                });
              }}
              placeholder={t("LastName")}
              value={contacts_details.last_name}
              label={t("LastName")}
            />

            <InputField
              extraClasses="mb-3 customInputStyle"
              name="email"
              handleChange={(e: any) => {
                setContactDetails((prev: any) => {
                  return { ...prev, email: e.target.value };
                });
              }}
              placeholder={t("email")}
              value={contacts_details.email}
              label={t("Email")}
            />
          </>
        )}

        {/* company menu */}
        {selectedContactType == "business" && (
          <>
            <InputField
              extraClasses="mb-3 customInputStyle"
              name="companyName"
              handleChange={(e: any) => {
                setCompanyContactDetails((prev: any) => {
                  return {
                    ...prev,
                    company_name: e.target.value,
                  };
                });
              }}
              placeholder={t("CompanyName")}
              value={company_contacts_details.company_name}
              label={t("CompanyName")}
            />

            <InputField
              extraClasses="mb-3 customInputStyle"
              name="firstname"
              handleChange={(e: any) => {
                setCompanyContactDetails((prev: any) => {
                  return {
                    ...prev,
                    first_name: e.target.value,
                  };
                });
              }}
              placeholder={t("contact_first_name")}
              value={company_contacts_details.first_name}
              label={t("contact_first_name")}
            />

            <InputField
              extraClasses="mb-3 customInputStyle"
              name="lastname"
              handleChange={(e: any) => {
                setCompanyContactDetails((prev: any) => {
                  return {
                    ...prev,
                    last_name: e.target.value,
                  };
                });
              }}
              placeholder={t("contact_last_name")}
              value={company_contacts_details.last_name}
              label={t("contact_last_name")}
            />

            <InputField
              extraClasses="mb-3 customInputStyle"
              name="email"
              handleChange={(e: any) => {
                setCompanyContactDetails((prev: any) => {
                  return {
                    ...prev,
                    email: e.target.value,
                  };
                });
              }}
              placeholder={t("contact_email")}
              value={company_contacts_details.email}
              label={t("contact_email")}
            />
          </>
        )}
        {loading ? (
          <button
            className="w-full my-4 rounded-lg btn btn-dark"
            disabled
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
          >
            <span className="text-white capitalize">{t("loading")}...</span>
          </button>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <button
                onClick={() => removeBeneficiary()}
                className="w-full px-10 my-6 rounded-lg btn btn-dark"
              >
                <span className="text-white capitalize">{t("cancel")}</span>
              </button>
            </div>
            <div className="col-md-6">
              <button
                onClick={createNewContact}
                data-transfer-payment-details="paymentdetails"
                className="w-full px-10 my-6 rounded-lg btn"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
              >
                <span
                  className="text-white capitalize"
                  data-transfer-payment-details="paymentdetails"
                >
                  {t("next")}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddBeneficiary;
