import { Form, Formik } from "formik";
import { editCompany } from "../../store/features/Auth/Auth";
import InputField from "../forms/InputField";
import * as Sentry from "@sentry/browser";

const CompanyInfo = (props: any) => {
  const { t, user, country, toast, dispatch, yup } = props;

  const formSchema = yup.object().shape({
    registration_id: yup.string().required("RNE"),
    company_name: yup.string().required("CNE"),
    address_information: yup.string().required("AIE"),
    country: yup.string().required("CE"),
    city: yup.string().required("CIE"),
  });

  return (
    <div>
      <div className="row p-2 pt-4 overflow-y-scroll">
        <div className="p-4 col-md-12">
          <p className="font-bold text-gray-600">{t("comp_info")}</p>
          <small>{t("comp_info_text")}</small>
        </div>

        <div className="p-4 bg-white col-md-5">
          <Formik
            key="form2"
            initialValues={{
              registration_id: user?.company?.registered_id,
              company_name: user?.company?.name,
              address_information: user?.company?.address,
              country: user?.country,
              city: user?.company?.city,
            }}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              const companyData = {
                name: data.company_name,
                location: data.address_information,
                registered_id: data.registration_id,
                city: data.city,
                quarter: data.address_information,
                address: data.address_information,
                country_code: country,
              };

              try {
                editCompany(companyData, user?.company.id)
                  .then((res: any) => {
                    setSubmitting(false);
                    toast.success(t("success_business_update"));
                  })
                  .catch((error: any) => {
                    setSubmitting(false);
                    // toast.success(t("err_business_update"));
                    toast.warning(t(error.data.message));

                    Sentry.captureException(error);
                  });
              } catch (error: any) {
                Sentry.captureException(error);
              }
            }}
            validationSchema={formSchema}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              isSubmitting,
              touched,
            }) => (
              <Form className="company-registration">
                <InputField
                  extraClasses="mb-3 customInputStyle"
                  name="registration_id"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("RegistrationID")}
                  value={values.registration_id}
                  label={t("RegistrationID")}
                  error={t(`${errors.registration_id}`)}
                  touched={touched.registration_id}
                />
                <InputField
                  extraClasses="mb-3 customInputStyle"
                  name="company_name"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("CompanyName")}
                  value={values.company_name}
                  label={t("CompanyName")}
                  error={t(`${errors.company_name}`)}
                  touched={touched.company_name}
                />
                <InputField
                  extraClasses="mb-3 customInputStyle"
                  name="address_information"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("AddressInformation")}
                  value={values.address_information}
                  label={t("AddressInformation")}
                  error={t(`${errors.address_information}`)}
                  touched={touched.address_information}
                />

                <div className="flex">
                  <div className="flex-1 mr-4">
                    <small className="text-gray-700">{t("Country")}</small>
                    <select
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="w-full p-2 mt-1 text-sm bg-white border-2 rounded-2"
                    >
                      <option value={user?.country}>{user?.country}</option>
                      {/* {countries.map((pays: any) => (
                <option value={pays.name}>{pays.name}</option>
              ))} */}
                    </select>
                    {errors.country && touched.country && (
                      <small className="text-sm text-red-500">
                        {t(`${errors.country}`)}
                      </small>
                    )}
                  </div>
                  <div className="flex-1 ml-4">
                    <InputField
                      name="city"
                      extraClasses="mb-3 customInputStyle"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("City")}
                      value={values.city}
                      label={t("City")}
                      error={t(`${errors.city}`)}
                      touched={touched.city}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 30,
                  }}
                >
                  <button
                    className="px-12 rounded-lg btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    type="submit"
                    disabled={user?.is_active || isSubmitting}
                  >
                    <span className="text-white capitalize">{t("save")}</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
