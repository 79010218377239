import { useLayoutEffect, useState } from "react";
import bg from "../../images/danapay_login.png";
import bg_en from "../../images/danapay_login_en.png";
import InputField from "../../components/forms/InputField";
import { HashLink as Link } from "react-router-hash-link";
import LangSwitch from "../../components/LangSwitch";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import { requestPasswordResetLink } from "../../store/features/Auth/Auth";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import { extractError } from "../../utility";
import { useAppSelector } from "../../store/hooks";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { lang } = useAppSelector((state) => state.persistedReducer.auth);
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [size, setSize] = useState([0, 0]);
  const [error, setError] = useState(" ");

  const HandleBtnClick = () => {
    setProcessing(true);
    requestPasswordResetLink({ email })
      .then(() => {
        setProcessing(false);
        toast.success(t("passwordLinkSent"));
      })
      .catch((error: any) => {
        setProcessing(false);
        toast.error(extractError(error));

        Sentry.captureException(error);
      });
  };

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="w-screen h-screen m-0 overflow-hidden row">
      <div className="h-full p-0 col-md-5">
        <div className="flex flex-row justify-between pt-4 pl-4">
          <LangSwitch />
        </div>
        <div className="flex items-center justify-center h-screen lg:px-10 md:px-4 sm:px-2">
          <div
            className="p-4 m-auto bg-white rounded-lg lg:mx-10 md:mx-4 sm:mx-2"
            style={{ width: size[0] > 760 ? "95%" : "100%" }}
          >
            <br />
            <h2 className="text-2xl font-bold">{t("forgotPass")}</h2>
            <p className="mb-3 text-base">{t("fptext")}</p>
            <form>
              <InputField
                extraClasses="mb-3 customInputStyle"
                placeholder={t("email")}
                label={t("email")}
                name="Email Address"
                handleChange={(value: any) => {
                  setEmail(value.target.value);
                  if (!/^\S+@\S+\.\S+$/.test(value.target.value)) {
                    setError(t("email_not_valid"));
                  } else {
                    setError("");
                  }
                }}
                onBlur={() => {
                  if (!/^\S+@\S+\.\S+$/.test(email)) {
                    setError(t("email_not_valid"));
                  } else {
                    setError("");
                  }
                }}
                value={email}
                error={error}
              />

              <div className="flex flex-row items-center justify-between mt-10">
                {processing ? (
                  <button
                    className="rounded-lg btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    disabled
                  >
                    <small className="font-bold text-white">
                      {t("processing")}
                    </small>
                  </button>
                ) : (
                  <button
                    onClick={HandleBtnClick}
                    className="rounded-lg btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    disabled={error.length !== 0}
                  >
                    <small className="font-bold text-white">
                      {t("RequestLink")}
                    </small>
                  </button>
                )}
                <Link to={"/login"}>
                  <small>{t("Back_to_Login")}</small>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {}
      <div
        className="h-full p-0 shadow-lg col-md-7"
        style={{
          backgroundImage: `url(${lang === "en" ? bg_en : bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: size[0] < 800 ? 0 : "100%",
        }}
      ></div>
    </div>
  );
};

export default ForgotPassword;
