import { FormControlLabel, Switch } from "@material-ui/core";
import BeneficiarySummary from "../BeneficiarySumary";
import CashLayout from "../CashLayout";
import * as Sentry from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";
import {
  getBankAccountsById,
  getUsersMFIAccountsById,
} from "../../store/features/Transfer/Transfer";
import { fetchMMAccountsById } from "../../store/features/Auth/Auth";
import WithdrawMethodDescription from "../TransferPaymentWithdrawModal/WithdrawMethodDescription";
import TransferInfo from "../TransferPaymentWithdrawModal/TransferInfo";
import MobileWithdrawBox from "../TransferPaymentWithdrawModal/MobileWithdrawBox";
import BankWithdrawBox from "../TransferPaymentWithdrawModal/BankWithdrawBox";
import MfiWithdrawBox from "../TransferPaymentWithdrawModal/MfiWithdrawBox";
import DeliveryWithdrawBox from "../TransferPaymentWithdrawModal/DeliveryWithdrawBox";

const TransferPaymentWithdrawModal = (props: any) => {
  const {
    transfer,
    userBalance,
    t,
    paymentMethod,
    checked,
    amountInEuros,
    spread_config,
    errorGot,
    fetchingFees,
    withdrawalFees,
    fees,
    widthdrawMethod,
    hideWithdrawModal,
    processingPayment,
    submitTransaction,
    feesCalculated,
    setcustomMobileOperator,
    setinstitutionId,
    setwidthdrawMethod,
    mobileOperatorList,
    selectedMobileOperator,
    setpaymentActiveStep,
    setselectedMobileOperator,
    setSelectedCountryReset,
    phone,
    setPhone,
    customMobileOperator,
    setcustomPhoneNumber,
    setcustomBankName,
    bankSelectionList,
    customBankName,
    selectedBankName,
    accountHolderName,
    setIBAN,
    setcustomIban,
    numberInputInvalidChars,
    customPhoneNumber,
    setselectedBankName,
    iBAN,
    customIban,
    mfiSelectionList,
    setcustomMfiName,
    setcustomMfiOwnerId,
    setselectedMfiName,
    customMfiName,
    mfiAccountUserName,
    setmfiOwnerId,
    mfiOwnerId,
    setmfiAccountUserName,
    customMfiOwnerId,
    selectedMfiName,
    size,
    steps,
    allDirectPaymentMethods,
    checkingForWithdrawProviders,
    setChecked,
  } = props;

  // toggle check on toggle for widthdraw mobile
  const handleChange = (event: any) => {
    window.localStorage.setItem(
      "withdrawEnabled",
      event.target.checked.toString()
    );

    setChecked(event.target.checked);
    setPhone(transfer?.beneficiary?.phone_number);

    // set mobile money as default if not repeat
    if (
      transfer?.transactionToRepeat == undefined ||
      transfer?.transactionToRepeat == null
    ) {
      if (event.target.checked) {
        setwidthdrawMethod("Mobile Money");
      } else {
        setwidthdrawMethod("");
      }
    }
  };

  const bonus = useMemo(() => {
    return widthdrawMethod !== "Mobile Money"
      ? 0
      : spread_config.received_amount * 0.005;
  }, [spread_config, widthdrawMethod]);

  // all benefitiary accounts
  const [beneficiaryMfiAccounts, setbeneficiaryMfiAccounts] = useState([]);
  const [beneficiaryMMAccounts, setBeneficiaryMMAccounts] = useState([]);
  const [beneficiaryBankAccounts, setBeneficiaryBankAccounts] = useState([]);

  const [isDeliveryPayoutPresent, setisDeliveryPayoutPresent] = useState(false);

  useEffect(() => {
    if (
      allDirectPaymentMethods !== undefined &&
      allDirectPaymentMethods.length > 0
    ) {
      setisDeliveryPayoutPresent(
        allDirectPaymentMethods.some(
          (method: any) =>
            method?.cashout_method?.payment_type?.name == "delivery"
        )
      );
    }
  }, [allDirectPaymentMethods]);
  const [fetchingBeneficiaryAccounts, setfetchingBeneficiaryAccounts] =
    useState(false);

  // get and set all benefitiary account for selection
  const getAllBenefitiaryAccounts = async (id: string) => {
    try {
      const tasks: any = [
        getUsersMFIAccountsById(id),
        getBankAccountsById(id),
        fetchMMAccountsById(id),
      ];

      const other = [{ id: 99, name: "Other" }];
      setfetchingBeneficiaryAccounts(true);

      for (const [fnIndex, fn] of tasks.entries()) {
        const response = await fn;
        if (fnIndex == 2) {
          setfetchingBeneficiaryAccounts(false);
        }
        if (
          response?.data !== undefined &&
          response?.data !== null &&
          response?.data?.length > 0
        ) {
          if (fnIndex == 0) {
            setbeneficiaryMfiAccounts(
              response?.data
                ?.map((acc: any) => ({ name: acc?.owner_id, id: acc?.id }))
                .filter((itm: any) => itm?.name !== "Other")
                .concat(other)
            );
          } else if (fnIndex == 1) {
            setBeneficiaryBankAccounts(
              response?.data
                ?.map((acc: any) => ({ name: acc?.iban, id: acc?.id }))
                .filter((itm: any) => itm?.name !== "Other")
                .concat(other)
            );
          } else if (fnIndex == 2) {
            setBeneficiaryMMAccounts(
              response?.data
                .map((acc: any) => ({ name: acc?.phone_number, id: acc?.id }))
                .filter((itm: any) => itm?.name !== "Other")
                .concat(other)
            );
          }
        }
      }
    } catch (error: any) {
      setfetchingBeneficiaryAccounts(false);
      Sentry.captureException(error);
    }
  };

  // set recievers name from contact info
  useEffect(() => {
    try {
      if (
        transfer?.beneficiary?.id !== undefined &&
        transfer?.beneficiary?.id !== null
      ) {
        getAllBenefitiaryAccounts(transfer?.beneficiary?.id);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer?.beneficiary?.id]);

  return (
    <div className="container my-4" id="transferPaymentWithdrawContainer">
      {/* <div className="w-full py-3 mb-4 bg-white">
        {size[0] < 900 && <h3>{steps[1]}</h3>}
      </div> */}
      <div
        className="col-md-10 mx-auto shadow-lg row"
        id="transferPaymentWithdrawSubContainer"
      >
        <div
          className="flex flex-row items-center justify-between border-b-2 col-md-12"
          id="transferBeneDetails"
        >
          <div className="flex-1">
            <BeneficiarySummary
              name={transfer?.beneficiary?.full_name}
              company={
                transfer?.beneficiary?.company?.name ||
                transfer?.beneficiary?.email
              }
            />
          </div>
          <div style={{ textAlign: "right" }}>
            <p>{t("Balance")}</p>
            <CashLayout cash={userBalance} />
          </div>
        </div>
        <div className="p-0 col-md-6" id="withdrawInfoContainer">
          <div className={hideWithdrawModal ? "scrollDiv h-0" : "scrollDiv"}>
            <div className="px-4 pt-3">
              {/* direct transfer toggle */}
              {/* show toggle in case of mobile hub 2 preset */}
              {!hideWithdrawModal && (
                <div
                  className="flex flex-col mb-2"
                  style={{
                    border: " 1px solid #cccccc",
                  }}
                >
                  <FormControlLabel
                    className="wrapperSwitchAndLableNew"
                    control={
                      <Switch
                        disabled={true}
                        onChange={() => {
                          // temp disabled
                          // handleChange;
                        }}
                        color="primary"
                        checked={checked}
                      />
                    }
                    label={t("Checked_Toogle")}
                  />

                  <WithdrawMethodDescription
                    checked={checked}
                    widthdrawMethod={widthdrawMethod}
                    t={t}
                  />
                </div>
              )}

              {/* withdraw methods */}
              {checked === true && !hideWithdrawModal && (
                <div className="flex flex-col">
                  <label className="withdrawTitle">
                    {t("selectWithdrawMode")}
                  </label>

                  {/* no withdraw method error */}
                  {transfer.getAllMobileOperator?.find(
                    (val: any) => val.providers
                  ) === undefined && (
                    <small className="text-red-500">
                      {t("No_Withdraw_Mode_Found")}
                    </small>
                  )}

                  <div className="bacnkCheckBoxWrapper flex flex-col p-2 gap-2">
                    {/* mobile */}
                    <MobileWithdrawBox
                      mobileOperatorList={mobileOperatorList}
                      setwidthdrawMethod={setwidthdrawMethod}
                      widthdrawMethod={widthdrawMethod}
                      t={t}
                      transfer={transfer}
                      setselectedMobileOperator={setselectedMobileOperator}
                      setSelectedCountryReset={setSelectedCountryReset}
                      setinstitutionId={setinstitutionId}
                      setcustomMobileOperator={setcustomMobileOperator}
                      selectedMobileOperator={selectedMobileOperator}
                      customMobileOperator={customMobileOperator}
                      phone={phone}
                      setPhone={setPhone}
                      customPhoneNumber={customPhoneNumber}
                      setcustomPhoneNumber={setcustomPhoneNumber}
                      beneficiaryMMAccounts={beneficiaryMMAccounts}
                      numberInputInvalidChars={numberInputInvalidChars}
                    />

                    {/* bank account */}
                    <BankWithdrawBox
                      t={t}
                      transfer={transfer}
                      bankSelectionList={bankSelectionList}
                      setwidthdrawMethod={setwidthdrawMethod}
                      widthdrawMethod={widthdrawMethod}
                      setcustomBankName={setcustomBankName}
                      setinstitutionId={setinstitutionId}
                      setselectedBankName={setselectedBankName}
                      selectedBankName={selectedBankName}
                      customBankName={customBankName}
                      setIBAN={setIBAN}
                      iBAN={iBAN}
                      customIban={customIban}
                      setcustomIban={setcustomIban}
                      accountHolderName={accountHolderName}
                      beneficiaryBankAccounts={beneficiaryBankAccounts}
                      setSelectedCountryReset={setSelectedCountryReset}
                    />

                    {/* micro transaction */}
                    <MfiWithdrawBox
                      mfiSelectionList={mfiSelectionList}
                      widthdrawMethod={widthdrawMethod}
                      t={t}
                      transfer={transfer}
                      setcustomMfiName={setcustomMfiName}
                      setselectedMfiName={setselectedMfiName}
                      setwidthdrawMethod={setwidthdrawMethod}
                      setinstitutionId={setinstitutionId}
                      selectedMfiName={selectedMfiName}
                      customMfiName={customMfiName}
                      setmfiAccountUserName={setmfiAccountUserName}
                      mfiAccountUserName={mfiAccountUserName}
                      beneficiaryMfiAccounts={beneficiaryMfiAccounts}
                      setcustomMfiOwnerId={setcustomMfiOwnerId}
                      setmfiOwnerId={setmfiOwnerId}
                      mfiOwnerId={mfiOwnerId}
                      customMfiOwnerId={customMfiOwnerId}
                    />

                    {/* delivery */}
                    <DeliveryWithdrawBox
                      isDeliveryPayoutPresent={isDeliveryPayoutPresent}
                      setwidthdrawMethod={setwidthdrawMethod}
                      widthdrawMethod={widthdrawMethod}
                      t={t}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* button */}
          </div>
          <div className="px-4 mt-2 mb-4 flex justify-between gap-2">
            <button
              onClick={() => {
                setpaymentActiveStep(0);
              }}
              className="w-full rounded-lg btn btn-dark btn-block"
            >
              {t("back")}
            </button>
            <button
              className="w-full rounded-lg btn btn-block"
              id="maketransfersuccessbtn"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              onClick={submitTransaction}
              disabled={
                feesCalculated ||
                errorGot !== "" ||
                processingPayment ||
                fetchingBeneficiaryAccounts ||
                (checked === true && widthdrawMethod == "") ||
                (checked === true &&
                  widthdrawMethod == "Mobile Money" &&
                  selectedMobileOperator == "Other" &&
                  customMobileOperator == "") ||
                (checked === true &&
                  widthdrawMethod == "Mobile Money" &&
                  selectedMobileOperator == "" &&
                  customMobileOperator == "") ||
                (checked === true &&
                  widthdrawMethod == "Mobile Money" &&
                  selectedMobileOperator !== "Other" &&
                  phone == "") ||
                (checked === true &&
                  widthdrawMethod == "Mobile Money" &&
                  phone == "Other" &&
                  customPhoneNumber == "") ||
                checkingForWithdrawProviders
              }
            >
              <span className="text-white capitalize">
                {processingPayment ? t("processing") : t("continue")}
              </span>
            </button>
          </div>
        </div>

        {/* transfer info */}
        <TransferInfo
          amountInEuros={amountInEuros}
          fees={fees}
          fetchingFees={fetchingFees}
          withdrawalFees={withdrawalFees}
          t={t}
          spread_config={spread_config}
          paymentMethod={paymentMethod}
          transfer={transfer}
          widthdrawMethod={widthdrawMethod}
        />
      </div>
    </div>
  );
};

export default TransferPaymentWithdrawModal;
