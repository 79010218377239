import { Modal } from "@material-ui/core";
import InputField from "../forms/InputField";

const OtpModal = (props: any) => {
  const { open, onClose, t, otp, setotp, onSubmit } = props;

  const handleChange = (e: any) => {
    setotp(e.target.value);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div className="m-auto h-full overflow-y-scroll justify-center flex items-center row">
        <div className="p-3 bg-white flex flex-col rounded-md col-11 col-sm-8 col-md-6 col-lg-4  max-w-[500px]">
          <div className="flex flex-row items-center justify-between mb-2">
            <div>
              <b>{t("OTP_Verification")}</b>
            </div>
            <button
              onClick={onClose}
              style={{ height: 40, width: 40, borderRadius: 20 }}
              className="flex items-center justify-center shadow-lg"
            >
              <i className="m-0 fa fa-close"></i>
            </button>
          </div>
          <hr className="mb-2" />
          <div className="mb-4">{t("phone_verify_text")}</div>
          <InputField
            extraClasses="mb-3 customInputStyle"
            name="otp"
            type="number"
            handleChange={handleChange}
            placeholder={t("OTP")}
            value={otp}
            label={t("OTP")}
            error={otp.length == 6 ? "" : t("otp_invalid")}
          />
          <button
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            type="submit"
            disabled={/^(\d){6}$/.test(otp) == false}
            className="px-10 rounded-lg btn"
            onClick={onSubmit}
          >
            <span className="text-white capitalize">{t("submit")}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OtpModal;
