import React from "react";
import CustomDropdown from "../CustomDropdown";
import InputField from "../forms/InputField";

const MfiWithdrawBox = (props: any) => {
  const {
    mfiSelectionList,
    widthdrawMethod,
    t,
    transfer,
    setcustomMfiName,
    setselectedMfiName,
    setwidthdrawMethod,
    setinstitutionId,
    selectedMfiName,
    customMfiName,
    setmfiAccountUserName,
    mfiAccountUserName,
    beneficiaryMfiAccounts,
    setcustomMfiOwnerId,
    setmfiOwnerId,
    mfiOwnerId,
    customMfiOwnerId,
  } = props;
  return (
    <>
      {mfiSelectionList.length > 0 && (
        <>
          <div className="wrapperBankCheckBox m-0 innerBankBox">
            <div className="wrapperBankCheckBoxwrapper">
              <input
                type="checkbox"
                className="default:ring-2"
                onChange={(e) => {
                  e.target.checked && setwidthdrawMethod("Microfinance");
                }}
                name="Micro finance"
                checked={widthdrawMethod == "Microfinance"}
              />
              <span className="checkmark"></span>
            </div>
            <label>{t("Micro finance")}</label>
          </div>
        </>
      )}

      {widthdrawMethod == "Microfinance" && mfiSelectionList.length > 0 && (
        <div className="mb-3 flex flex-col gap-1 innerBankBox">
          <div className="innerBoxDesc mb-2">{t("microfinance_warning")}</div>
          <CustomDropdown
            setCustomProvider={setcustomMfiName}
            defaultValue={transfer?.transactionToRepeat?.data?.mfi_name}
            setinstitutionId={setinstitutionId}
            onchange={(val: any) => {
              setselectedMfiName(val);
            }}
            list={mfiSelectionList}
            customClasses="wrapperBankCheckBox innerBankBox m-0 !bg-white"
            placeholder={t("select_the_micro_finance")}
          />

          {selectedMfiName == "Other" && (
            <InputField
              handleChange={(e: any) => setcustomMfiName(e.target.value)}
              value={customMfiName}
              label=""
              type="text"
              name="customMfiName"
              placeholder="MFI Name"
            />
          )}

          <div className="relative flex">
            <div
              className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
              style={{
                borderRadius: "10px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#e8e7e7",
                border: "1px solid #e8e8e8",
                borderRight: "none",
                marginBlock: "7px",
              }}
            >
              {t("owner_tag")}
            </div>
            <input
              className="owner w-full"
              onChange={(e: any) => setmfiAccountUserName(e.target.value)}
              value={mfiAccountUserName}
              type="text"
              disabled={true}
              name="mfiAccountUserName"
              placeholder={t("receivers_fullname")}
            />
          </div>

          {/* mfi owner id dropdown */}
          {beneficiaryMfiAccounts.length > 0 ? (
            <CustomDropdown
              setCustomProvider={setcustomMfiOwnerId}
              defaultValue={transfer?.transactionToRepeat?.data?.mfi_owner_id}
              onchange={(val: any) => {
                setmfiOwnerId(val);
              }}
              list={beneficiaryMfiAccounts}
              customClasses="wrapperBankCheckBox innerBankBox m-0 !bg-white"
              placeholder={t("select_the_micro_finance")}
            />
          ) : (
            <InputField
              handleChange={(e: any) => setmfiOwnerId(e.target.value)}
              value={mfiOwnerId}
              label=""
              type="text"
              name="mfiOwnerId"
              placeholder="Account number"
            />
          )}

          {mfiOwnerId == "Other" && (
            <InputField
              handleChange={(e: any) => setcustomMfiOwnerId(e.target.value)}
              value={customMfiOwnerId}
              label=""
              type="text"
              name="mfiOwnerId"
              placeholder="Account number"
            />
          )}
        </div>
      )}
    </>
  );
};

export default MfiWithdrawBox;
