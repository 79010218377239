import { Alert } from "@material-ui/lab";
import { applyRate, buildLimitError, isEmpty } from "../../utilities/help";
import BeneficiarySummary from "../BeneficiarySumary";
import CashLayout from "../CashLayout";
import InputField from "../forms/InputField";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { toast } from "material-react-toastify";
import TransferInfo from "../TransferPaymentWithdrawModal/TransferInfo";

const PaymentMethods = ({ props, previous }: any) => {
  const {
    transfer,
    t,
    paymentMethod,
    errorGot,
    checkingForRestriction,
    isUserRestricted,
    handleCashMethod,
    fetchingFees,
    hideWithdrawModal,
    processingPayment,
    submitTransaction,
    feesCalculated,
    user,
    checkingForWithdrawProviders,
    googleSheetsLoading,
    reason,
    setpaymentActiveStep,
  } = props;

  // handle form to withdrawal page
  const proceedToWithdrawalMode = () => {
    if (reason !== "" && paymentMethod !== "") {
      setpaymentActiveStep(1);
    } else {
      if (reason == "") {
        toast.error(t("reasonError"));
      }
      if (paymentMethod == "") {
        toast.error(t("selectCashIn"));
      }
    }
  };

  return (
    <div className="scrollDiv">
      {/* cash in methods */}
      <div className="my-3 outerfieldBox mx-4">
        <div className="flex flex-row customeDesignwithdraw customeCacheDesign">
          <label className="withdrawTitle">{t("selectCashinMethod")}</label>
          <div
            className={`${
              checkingForRestriction ||
              checkingForWithdrawProviders ||
              feesCalculated ||
              fetchingFees ||
              googleSheetsLoading
                ? "pointer-events-none "
                : "pointer-events-auto "
            }bacnkCheckBoxWrapper p-2 flex flex-col gap-2 relative`}
          >
            {checkingForRestriction && (
              <div className="flex justify-center items-center z-50 w-full h-48">
                <CircularProgress />
              </div>
            )}
            {!checkingForRestriction &&
              transfer.loggedInUserCountry?.cash_in_methods &&
              transfer.loggedInUserCountry?.cash_in_methods
                .filter((itm: any) => {
                  if (isUserRestricted) {
                    return (
                      itm?.cash_in_method?.payment_type?.name == "bank_transfer"
                    );
                  } else {
                    return true;
                  }
                })
                ?.map((value: any, index: any) => {
                  return (
                    <>
                      <div
                        className="wrapperBankCheckBox m-0 innerBankBox"
                        key={"transferPaymentCheckbox" + index}
                      >
                        <div className="wrapperBankCheckBoxwrapper">
                          <input
                            type="checkbox"
                            className="default:ring-2"
                            onChange={(e: any) => {
                              handleCashMethod(
                                e.target.checked,
                                value.cash_in_method?.name.toLowerCase()
                              );
                            }}
                            name={value?.cash_in_method?.name}
                            checked={
                              value.cash_in_method?.name.toLowerCase() ===
                              paymentMethod
                            }
                          />
                          <span className="checkmark"></span>
                        </div>
                        <label>
                          {t(
                            value?.cash_in_method?.name
                              ?.toLowerCase()
                              .split(" ")
                              .join("_")
                          )}
                        </label>
                      </div>

                      {/* max limit error message */}
                      {errorGot &&
                        !fetchingFees &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="mb-2 innerBankBox text-red-500">
                            {t("max_amount_exceeded_cashin_message")}
                          </small>
                        )}

                      {value.cash_in_method?.name.toLowerCase() ===
                        paymentMethod && (
                        <small className="mb-2 innerBankBox">
                          {t("max_limit")}
                          {value?.cash_in_method?.max_amount} EUR
                        </small>
                      )}

                      {value?.cash_in_method?.name?.toLowerCase() ===
                        "bank transfer" &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="innerBoxDesc mb-2 innerBankBox">
                            <b>{t("bank_transfer_instructions_title")}</b>
                            {t("bank_transfer_instructions")}
                          </small>
                        )}
                      {value?.cash_in_method?.name?.toLowerCase() ===
                        "balance" &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="innerBoxDesc mb-2 innerBankBox">
                            <b>{t("balance_instructions_title")}</b>
                            {t("balance_instructions")}
                          </small>
                        )}
                      {value?.cash_in_method?.name?.toLowerCase() ===
                        "bank card" &&
                        value.cash_in_method?.name.toLowerCase() ===
                          paymentMethod && (
                          <small className="innerBoxDesc mb-2 innerBankBox">
                            <b>{t("bank_card_instructions_title")}</b>{" "}
                            {t("bank_card_instructions")}
                          </small>
                        )}
                    </>
                  );
                })}
          </div>
        </div>
      </div>
      <div className="px-4 mb-4 row">
        <div className="mt-2 col-md-6">
          <button
            onClick={previous}
            className="w-full px-10 rounded-lg btn btn-dark btn-block"
          >
            {t("back")}
          </button>
        </div>
        <div className="mt-2 col-md-6">
          <button
            className="w-full rounded-lg btn btn-block"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            onClick={() => {
              // temporary prod fix
              const disableDirectPayment: any =
                (!transfer?.beneficiary?.is_individual &&
                  user?.is_individual) ||
                (transfer?.beneficiary?.is_company_owner &&
                  user?.is_individual);
              hideWithdrawModal || disableDirectPayment
                ? submitTransaction()
                : proceedToWithdrawalMode();
            }}
            disabled={
              feesCalculated ||
              errorGot !== "" ||
              processingPayment ||
              checkingForWithdrawProviders ||
              googleSheetsLoading
            }
          >
            <span className="text-white capitalize">
              {processingPayment ? t("processing") : t("continue")}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const AmountAndReason = ({
  props,
  next,
  cashInMethod,
  sethasUserReachedPaymentPage,
}: any) => {
  const {
    toast,
    amountInEuros,
    reason,
    amountInCFA,
    t,
    setReason,
    setisCFAFirst,
    userBalance,
    setLimitError,
    paymentMethod,
    transfer,
    checkCashInLimit,
    setAmountInEuros,
    setAmountInCFA,
    fetchingFees,
    spread_config,
    errorGot,
    notReceivingCountry,
    setErrorGot,
  } = props;

  useEffect(() => {
    try {
      if (transfer?.loggedInUserCountry?.cash_in_methods) {
        let maxAmount: number = 0;
        transfer?.loggedInUserCountry?.cash_in_methods.forEach(
          (method: any) => {
            const limit: number = parseInt(method?.cash_in_method?.max_amount);
            if (limit > maxAmount) {
              maxAmount = limit;
            }
          }
        );

        if (parseInt(amountInEuros) > maxAmount) {
          toast.error(`${t("max_amount_exceeded")}${maxAmount}`);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer.loggedInUserCountry?.cash_in_methods, amountInEuros]);

  const validate = () => {
    if (reason.length === 0) {
      toast.error(t("reasonError"));
      return;
    }
    if (amountInEuros.length === 0) {
      toast.error(t("the_euro_amount_field_is_required"));
      return;
    }
    if (amountInCFA.length === 0) {
      toast.error(t("enterCfa"));
      return;
    }
    sethasUserReachedPaymentPage(true);
    next();
  };

  return (
    <div className="scrollDiv flex flex-col">
      <div className="px-4 my-3">
        <label className="withdrawTitle">{t("enter_reason")}</label>
        <div className="mb-3 ">
          <InputField
            extraClasses="transferPaymentFormInput"
            handleChange={(e: any) => setReason(e.target.value)}
            value={reason}
            label=""
            placeholder={t("reason")}
            type="text"
            name="reason"
          />
        </div>

        {/* payment amount divs */}
        <div className="mb-1">
          <label className="withdrawTitle">{t("enter_amount")}</label>
          <div className="flex ">
            <div
              className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
              style={{
                borderRadius: "10px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#e8e7e7",
                border: "1px solid #e8e8e8",
                borderRight: "none",
                marginBlock: "7px",
              }}
            >
              {t("EUR")}
            </div>
            <input
              name="Sent amount (EUR)"
              className="owner w-full text-sm"
              style={{
                background: "#fafafa",
                border: "none",
              }}
              placeholder={t("Sent_Amount_Eur_Only")}
              onChange={(e: any) => {
                setisCFAFirst(false);
                setLimitError(null);
                setErrorGot("");
                if (
                  Number(e.target.value) < 1 ||
                  Number(e.target.value) > Number(userBalance)
                ) {
                  if (paymentMethod === "balance") {
                    // toast.error(t("expectAmount") + userBalance);
                  }
                }
                if (checkCashInLimit(transfer, "min", e.target.value)) {
                  setLimitError(buildLimitError(transfer, "min", t));
                }
                if (checkCashInLimit(transfer, "max", e.target.value)) {
                  setLimitError(buildLimitError(transfer, "max", t));
                }
                setAmountInEuros(e.target.value);
                setAmountInCFA(
                  applyRate(e, spread_config.exchange_rate, "EUR")
                );
              }}
              value={amountInEuros}
              type="number"
              disabled={notReceivingCountry}
            />
          </div>
        </div>
        {notReceivingCountry && <Alert severity="error">{t(errorGot)}</Alert>}

        <div className="mb-3">
          <div className="flex">
            <div
              className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
              style={{
                borderRadius: "10px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#e8e7e7",
                border: "1px solid #e8e8e8",
                borderRight: "none",
                marginBlock: "7px",
              }}
            >
              {props.t("CFA")}
            </div>
            <input
              disabled={notReceivingCountry}
              className="owner w-full text-sm"
              style={{
                background: "#fafafa",
                border: "none",
              }}
              name="Amount received (CFA)"
              placeholder={props.t("Sent_Amount_Cfa_Only")}
              onChange={(e: any) => {
                props.setisCFAFirst(true);
                props.setAmountInCFA(e.target.value);
                props.setAmountInEuros(
                  applyRate(e, props.spread_config.exchange_rate, "CFA")
                );
              }}
              value={props.amountInCFA}
              type="number"
            />
          </div>
        </div>
      </div>
      <div className="px-4 mb-4 row">
        <div className="mt-2 col-md-6">
          <button
            onClick={() => {
              props.handleBack();
              props.handleChangeHasUserReachedPaymentPage(false);
            }}
            className="w-full px-10 rounded-lg btn btn-dark btn-block"
          >
            {props.t("back")}
          </button>
        </div>
        <div className="mt-2 col-md-6">
          <button
            className="w-full rounded-lg btn btn-block"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            disabled={fetchingFees || notReceivingCountry}
            onClick={validate}
          >
            <span className="text-white capitalize">
              {props.processingPayment
                ? props.t("processing")
                : props.t("continue")}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const TransferPaymentModal = (props: any) => {
  const {
    transfer,
    userBalance,
    t,
    paymentMethod,
    amountInEuros,
    spread_config,
    fetchingFees,
    withdrawalFees,
    fees,
    widthdrawMethod,
    size,
    steps,
    hasUserReachedPaymentPage,
    handleChangeHasUserReachedPaymentPage,
  } = props;

  const [internalStep, setInternalStep] = useState(0);
  const [cashInMethod, setcashInMethod] = useState(null);

  // const bonus = useMemo(() => {
  //   return widthdrawMethod !== "Mobile Money"
  //     ? 0
  //     : spread_config.received_amount * 0.005;
  // }, [spread_config, widthdrawMethod]);

  // find and set selected cash in method for the error showing for the amount, from payment method
  useEffect(() => {
    if (
      paymentMethod &&
      paymentMethod !== "" &&
      transfer.loggedInUserCountry?.cash_in_methods
    ) {
      const cashinmethod = transfer.loggedInUserCountry?.cash_in_methods.find(
        (cashinmethodItem: any) =>
          cashinmethodItem?.cash_in_method?.name?.toLowerCase() ==
          paymentMethod.toLowerCase()
      );
      if (cashinmethod) {
        setcashInMethod(cashinmethod);
      } else {
        setcashInMethod(null);
      }
    }
  }, [paymentMethod, transfer.loggedInUserCountry?.cash_in_methods]);

  return (
    <div className="container">
      {size[0] < 900 && (
        <div className="w-full py-3 mb-4 bg-white">
          <h3>{steps[1]}</h3>
        </div>
      )}
      <div className="flex my-3 justify-center h-full">
        <div className="col-md-10">
          <div className="shadow-lg row">
            <div className="flex flex-row items-center justify-between border-b-2 col-md-12">
              <div className="flex-1">
                <BeneficiarySummary
                  name={transfer?.beneficiary?.full_name}
                  company={
                    transfer?.beneficiary?.company?.name ||
                    transfer?.beneficiary?.email
                  }
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <p>{t("Balance")}</p>
                <CashLayout cash={userBalance} />
              </div>
            </div>

            <div className="p-0 col-md-6">
              {internalStep === 0 && (
                <AmountAndReason
                  sethasUserReachedPaymentPage={
                    handleChangeHasUserReachedPaymentPage
                  }
                  cashInMethod={cashInMethod}
                  props={props}
                  next={() => setInternalStep((prev) => prev + 1)}
                />
              )}

              {internalStep === 1 && (
                <PaymentMethods
                  props={props}
                  cashInMethod={cashInMethod}
                  previous={() => setInternalStep((prev) => prev - 1)}
                />
              )}
            </div>

            {/* transfer info */}
            <TransferInfo
              hasUserReachedPaymentPage={
                !isEmpty(transfer?.transactionToRepeat) ||
                hasUserReachedPaymentPage
              }
              amountInEuros={amountInEuros}
              fees={fees}
              fetchingFees={fetchingFees}
              withdrawalFees={withdrawalFees}
              t={t}
              spread_config={spread_config}
              paymentMethod={paymentMethod}
              transfer={transfer}
              widthdrawMethod={widthdrawMethod}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferPaymentModal;
