import { Modal } from "@material-ui/core";
import { Formik } from "formik";
import {
  addMMAccount,
  addUserBankAccount,
} from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import { extractErrorMessage } from "../../helpers";
import { buildString, getCountry } from "../../utilities/help";
import FormComp from "./form";

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";

const AddAccountModal = (props: any) => {
  const {
    open,
    onClose,
    sethub2BankAccounts,
    yup,
    type,
    transfer,
    selected_account,
    t,
    toast,
    setAddFormOpen,
    setProcessing,
    fetchBankAccount,
    fetchMMAccount,
    hub2BankAccounts,
    processing,
    fetchingBankNames,
    fetchHub2BanksAccordingToCountry,
  } = props;

  const validationSchema = yup.object().shape({
    title: yup.string().required("title_required"),
    customBankName:
      type === "bank"
        ? yup
            .string()
            .test(
              "yourTestCondition",
              "bank_name_required",
              function (value: any, context: any) {
                return (
                  (this.parent.bank_name !== "Other" &&
                    this.parent.bank_name !== "" &&
                    this.parent.bank_name !== undefined) ||
                  (this.parent.bank_name == "Other" &&
                    this.parent.customBankName !== undefined &&
                    this.parent.customBankName !== "")
                );
              }
            )
        : yup.string().nullable(),
    bank_name:
      type === "bank"
        ? yup
            .string()
            .required("bank_name_required")
            .test(
              "oneOfRequired",
              "bank_name_required",
              function (item: any, x: any) {
                if (
                  item == "Other" &&
                  (this.parent.customBankName == "" ||
                    this.parent.customBankName == undefined)
                ) {
                  return false;
                } else {
                  return true;
                }
              }
            )
        : yup.string().nullable(),
    bic:
      type === "bank"
        ? yup.string().required("bic_required")
        : yup.string().nullable(),
    iban:
      type === "bank"
        ? yup.string().required("iban_required")
        : yup.string().nullable(),
    owner: yup.string().required("owner_required"),
    phone_number:
      type === "bank"
        ? yup.string().nullable()
        : yup
            .string()
            .test(
              "phone_validation",
              "invalid_phone_number",
              function (val: any) {
                try {
                  const country = getCountry(transfer, this.parent?.country);

                  if (country && country?.country_code && val && val !== "") {
                    const receipentNumber =
                      "+" +
                      country?.country_code?.toString() +
                      " " +
                      val?.toString();
                    return (
                      isValidPhoneNumber(receipentNumber) &&
                      isPossiblePhoneNumber(receipentNumber) &&
                      validatePhoneNumberLength(receipentNumber) == undefined
                    );
                  } else {
                    return true;
                  }
                } catch (error) {
                  Sentry.captureException(error);
                }
              }
            )
            .required("phone_number_required"),
    operator:
      type === "bank"
        ? yup.string().nullable()
        : // : transfer?.getAllMobileOperator?.find(
          //     (val: any) =>
          //       val.cash_out_method.name ===
          //       transfer?.getAllMobileOperator?.find((val: any) => {
          //         if (
          //           val?.cash_out_method?.name === "Mobile Money" ||
          //           val.cash_out_method?.name === "Mobile Money by Hub2"
          //         ) {
          //           return val;
          //         }
          //       })?.cash_out_method?.name
          //   )?.providers !== undefined &&
          //   yup.string().required("operator_required"),
          yup.string().required("operator_required"),
    Other:
      type === "bank"
        ? yup.string().nullable()
        : transfer?.getAllMobileOperator?.find(
            (val: any) =>
              val.cash_out_method.name ===
              transfer?.getAllMobileOperator?.find((val: any) => {
                if (
                  val?.cash_out_method?.name === "Mobile Money" ||
                  val.cash_out_method?.name === "Mobile Money by Hub2"
                ) {
                  return val;
                }
              })?.cash_out_method?.name
          )?.providers === undefined && yup.string(),
    country: yup.string().required(),
    my_account: yup.string().nullable("my_account_required"),
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        sethub2BankAccounts([]);
      }}
      className="mx-3"
    >
      <div className="flex items-center justify-center h-screen overflow-y-scroll addaccountmodal">
        <div className="row lg:w-full md:w-full max-h-full">
          <div className="p-3 bg-white rounded col-md-6 addaccountmodal mx-auto">
            <div className="flex flex-row items-center justify-between">
              <h3>{t("Add_New_Account")}</h3>
              <button
                onClick={() => setAddFormOpen(false)}
                style={{ height: 40, width: 40, borderRadius: 20 }}
                className="flex items-center justify-center shadow-lg"
              >
                <i className="m-0 fa fa-close"></i>
              </button>
            </div>
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                title: "",
                bank_name: "",
                bic: "",
                iban: "",
                owner: "",
                phone_number: "",
                operator: "",
                Other: "",
                country: "",
                customBankName: "",
              }}
              onSubmit={(data: any) => {
                try {
                  setProcessing(true);
                  const selected_country = getCountry(transfer, data.country);
                  // const selected_country = JSON.parse(data.country);
                  if (type === "bank") {
                    delete data.operator;
                    delete data.phone_number;

                    const newAccount = {
                      title: data.title,
                      country: selected_country.name,
                      iban: data.iban,
                      bic: data.bic,
                      owner_name: data.owner,
                      bank_name:
                        data.bank_name == "Other"
                          ? data?.customBankName
                          : data.bank_name,
                      country_code: selected_country?.country_code,
                    };
                    addUserBankAccount(newAccount)
                      .then(() => {
                        setProcessing(false);
                        toast.success(t("Added_successfully"));
                        onClose();
                        fetchBankAccount();
                      })
                      .catch((error: any) => {
                        Sentry.captureException(error);

                        setProcessing(false);
                        const err = extractErrorMessage(error);
                        const errorArray = err.map((errorText: string) =>
                          buildString(errorText?.toLowerCase())
                        );
                        toast.error(t(errorArray.join(" ")));
                      })
                      .finally(() => sethub2BankAccounts([]));
                  } else {
                    delete data.bic;
                    delete data.iban;
                    delete data.bank_name;
                    const newAccount = {
                      title: data.title,
                      country: selected_country.name,
                      owner_name: data.owner,
                      country_code: selected_country?.country_code,
                      operator: data.operator,
                      phone_number: data.phone_number,
                    };
                    if (data.Other !== "") {
                      newAccount.operator = data?.Other;
                    }

                    addMMAccount(newAccount)
                      .then(() => {
                        setProcessing(false);

                        toast.success(t("Added_successfully"));
                        onClose();
                        fetchMMAccount();
                      })
                      .catch((error: any) => {
                        setProcessing(false);
                        Sentry.captureException(error);

                        const err = extractErrorMessage(error);
                        const errorArray = err.map((errorText: string) =>
                          buildString(errorText?.toLowerCase())
                        );
                        toast.error(t(errorArray.join(" ")));
                      });
                  }
                } catch (error: any) {
                  Sentry.captureException(error);
                }
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isValid,
              }) => (
                <FormComp
                  fetchingBankNames={fetchingBankNames}
                  selected_account={selected_account}
                  hub2BankAccounts={hub2BankAccounts}
                  fetchHub2BanksAccordingToCountry={
                    fetchHub2BanksAccordingToCountry
                  }
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  type={type}
                  t={t}
                  action="add"
                  processing={processing}
                  isValid={isValid}
                />
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAccountModal;
