import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import InputField from "../forms/InputField";
import * as Sentry from "@sentry/browser";
import * as Yup from "yup";
import { discoveryDelete } from "../../store/features/Auth/Auth";

const DiscoveryModal = ({
  onNext,
  extraclass,
  selectedValue,
  isDashboardModal,
}: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [otherMethod, setotherMethod] = useState("");
  const selectAccount = [
    {
      name: "LinkedIn",
      checked: false,
      custom: false,
    },
    {
      name: "Google",
      checked: false,
      custom: false,
    },
    {
      name: "Facebook",
      checked: false,
      custom: false,
    },
    {
      name: "Instagram",
      checked: false,
      custom: false,
    },
    {
      name: "Tiktok",
      checked: false,
      custom: false,
    },
    {
      name: "Event",
      checked: false,
      custom: false,
    },
    {
      name: "Reffered_by_someone",
      checked: false,
      custom: true,
    },
    {
      name: "Other",
      checked: false,
      custom: true,
    },
  ];
  const [discoveryMethods, setdiscoveryMethods] = useState(selectAccount);
  const [otherValue, setotherValue] = useState("");

  // set selected value if coming back
  useEffect(() => {
    try {
      if (
        selectedValue &&
        selectedValue !== undefined &&
        selectedValue !== null
      ) {
        setdiscoveryMethods((prev: any) => {
          const isOther = prev.every(
            (itm: any) => itm.name !== selectedValue?.user_origin
          );

          return prev.map((itm: any) => {
            if (isOther && itm.name == "Other") {
              setotherValue(selectedValue?.user_origin);
              return { ...itm, checked: true };
            } else if (
              selectedValue?.user_origin == "Referral" &&
              itm.name == "Reffered_by_someone"
            ) {
              return { ...itm, checked: true };
            } else if (selectedValue?.user_origin == itm.name) {
              return { ...itm, checked: true };
            } else {
              return { ...itm, checked: false };
            }
          });
        });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [selectedValue]);

  const discoverySchema = Yup.object().shape(
    {
      discoveryOption: Yup.string().test(
        "yourTestCondition",
        "Please select any one of options",
        function (value, io) {
          return (
            (value !== undefined && value !== "") ||
            (io.parent.otherOption !== undefined &&
              io.parent.otherOption !== "") ||
            (io.parent.referralCode !== undefined &&
              io.parent.referralCode !== "")
          );
        }
      ),
      otherOption: Yup.string().test(
        "yourTestCondition",
        "other_field_error",
        function (value, io) {
          return (
            (value !== undefined && value !== "") ||
            (io.parent.discoveryOption !== undefined &&
              io.parent.discoveryOption !== "") ||
            (io.parent.referralCode !== undefined &&
              io.parent.referralCode !== "")
          );
        }
      ),
      referralCode: Yup.string().test(
        "yourTestCondition",
        "referral_code_error",
        function (value, io) {
          return (
            (value !== undefined && value !== "") ||
            (io.parent.otherOption !== undefined &&
              io.parent.otherOption !== "") ||
            (io.parent.discoveryOption !== undefined &&
              io.parent.discoveryOption !== "")
          );
        }
      ),
      event: Yup.string().test(
        "yourTestCondition",
        "event_error",
        function (value, io) {
          return (
            (value !== undefined &&
              value !== "" &&
              io.parent.discoveryOption == "Event") ||
            (io.parent.otherOption !== undefined &&
              io.parent.otherOption !== "") ||
            (io.parent.discoveryOption !== undefined &&
              io.parent.discoveryOption !== "" &&
              io.parent.discoveryOption !== "Event") ||
            (io.parent.referralCode !== undefined &&
              io.parent.referralCode !== "")
          );
        }
      ),
    },
    [["discoveryOption", "otherOption"]]
  );

  const handleLogout = () => {
    confirmAlert({
      title: t("loggingOut"),
      message: t("loggingOutSure"),
      overlayClassName: "zIndexClass",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {},
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const onSubmit = () => {
    history.push("/dashboard/home");
  };

  return (
    <div
      className={
        extraclass ? `${extraclass} row discoveryModal` : "row discoveryModal"
      }
    >
      <div className="col-md-4 col-11">
        <div className="p-8 bg-white rounded-lg shadow-lg">
          <h2 className="font-bold text-center text-1xl">{t("discovery")}</h2>
          <small className="text-gray-700 text-center block mt-1 mb-6">
            {t("discoverysub")}
          </small>
          <Formik
            enableReinitialize
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{
              discoveryOption:
                (selectedValue?.user_origin && selectedValue?.user_origin) ||
                "",
              otherOption: (otherValue !== "" && otherValue) || "",
              referralCode:
                (selectedValue?.user_origin == "Referral" &&
                  selectedValue?.referral_code) ||
                "",
              event: (selectedValue?.event && selectedValue?.event) || "",
            }}
            validationSchema={discoverySchema}
            onSubmit={async (values) => {
              if (values.referralCode !== "") {
                onNext &&
                  onNext({
                    user_origin: "Referral",
                    referral_code: values.referralCode,
                  });
              } else if (
                values.discoveryOption !== "" &&
                values.discoveryOption !== "Event"
              ) {
                onNext && onNext({ user_origin: values.discoveryOption });
              } else if (
                values.discoveryOption == "Event" &&
                values.event !== ""
              ) {
                onNext && onNext({ user_origin: "Event", event: values.event });
              } else {
                onNext && onNext({ user_origin: values.otherOption });
              }
              // const finalvalue = values.discoveryOption !== "" ? values.discoveryOption : values.otherOption
              // onNext && onNext(finalvalue)
            }}
          >
            {({
              values,
              handleChange,
              errors,
              touched,
              handleBlur,
              isValid,
              handleSubmit,
            }) => (
              <form
                className="individual-registration"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="mb-6">
                  <div className="flex flex-row customeDesignwithdraw customeCacheDesign">
                    <div className="bacnkCheckBoxWrapper p-2 flex flex-col gap-2">
                      {discoveryMethods
                        ?.filter((itm: any) =>
                          isDashboardModal == "true"
                            ? itm.name !== "Reffered_by_someone"
                            : true
                        )
                        ?.map((value: any, discoveryIndex: any) => {
                          return (
                            <>
                              <div
                                className="wrapperBankCheckBox m-0  innerBankBox"
                                key={`${discoveryIndex}account`}
                              >
                                <div className="wrapperBankCheckBoxwrapper">
                                  <input
                                    type="checkbox"
                                    className="default:ring-2"
                                    name={"discover"}
                                    checked={value.checked}
                                    onChange={() => {
                                      // value.name !== 'Other' ? handleChange('discoveryOption')(value?.name) : handleChange('discoveryOption')(''); handleChange('referralCode')('')
                                      if (value.name == "Reffered_by_someone") {
                                        handleChange("discoveryOption")("");
                                        handleChange("otherOption")("");
                                        handleChange("referralCode")("");
                                      }
                                      if (value.name == "Other") {
                                        handleChange("discoveryOption")("");
                                        handleChange("otherOption")("");
                                        handleChange("referralCode")("");
                                      }
                                      if (
                                        value.name !== "Other" &&
                                        value.name !== "Reffered_by_someone"
                                      ) {
                                        handleChange("discoveryOption")(
                                          value?.name
                                        );
                                        handleChange("otherOption")("");
                                        handleChange("referralCode")("");
                                      }
                                      // setdiscoveryMethods(p => p.map((itm, itmind) => discoveryIndex == itmind ? ({ ...itm, checked: true }) : ({ ...itm, checked: false })))
                                      setdiscoveryMethods((p) =>
                                        p.map((itm, itmind) =>
                                          value.name == itm.name
                                            ? { ...itm, checked: true }
                                            : { ...itm, checked: false }
                                        )
                                      );
                                      // value.name !== 'Other' && handleChange('otherOption')('')
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                </div>
                                <label className="md:text-md max-sm:!text-sm">
                                  {t(value?.name)}
                                </label>
                              </div>
                              {/* <div className="wrapperBankCheckBox innerBankBox" key={`${discoveryIndex}account`}>
                                                            <div className="wrapperBankCheckBoxwrapper">
                                                                <input
                                                                    type="checkbox"
                                                                    className="default:ring-2"
                                                                    name={'discover'}
                                                                    checked={value.checked}
                                                                    onChange={(e) => { setdiscoveryMethods(p => p.map((itm, itmind) => discoveryIndex == itmind ? ({ ...itm, checked: true }) : ({ ...itm, checked: false }))) }}
                                                                />
                                                                <span className="checkmark"></span>
                                                            </div>
                                                            <label>{t(value?.name)}</label>

                                                        </div> */}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  {discoveryMethods.find(
                    (itm) => itm.name == "Other" && itm.checked
                  ) && (
                    <InputField
                      extraClasses="mb-3 customInputStyle"
                      name="otherOption"
                      type="text"
                      placeholder={t("discovery_other_heard")}
                      handleChange={(e: any) => {
                        handleChange("otherOption")(e.target.value);
                      }}
                      value={values.otherOption}
                      error={t(`${errors.otherOption}`)}
                      onBlur={() => {
                        handleBlur("otherOption");
                      }}
                      touched={touched.otherOption}
                    />
                  )}
                  {discoveryMethods.find(
                    (itm) => itm.name == "Reffered_by_someone" && itm.checked
                  ) && (
                    <InputField
                      extraClasses="mb-3 customInputStyle"
                      name="referralCode"
                      type="text"
                      placeholder={t("referral_code")}
                      handleChange={(e: any) => {
                        handleChange("referralCode")(e.target.value);
                      }}
                      value={values.referralCode}
                      error={t(`${errors.referralCode}`)}
                      onBlur={() => {
                        handleBlur("referralCode");
                      }}
                      touched={touched.referralCode}
                    />
                  )}
                  {discoveryMethods.find(
                    (itm) => itm.name == "Event" && itm.checked
                  ) && (
                    <InputField
                      name="event"
                      extraClasses="mb-3 customInputStyle"
                      type="text"
                      placeholder={t("event_placeholder")}
                      handleChange={(e: any) => {
                        handleChange("event")(e.target.value);
                      }}
                      value={values.event}
                      error={t(`${errors.event}`)}
                      onBlur={() => {
                        handleBlur("event");
                      }}
                      touched={touched.event}
                    />
                  )}
                </div>
                <div className="flex items-center justify-center">
                  <button
                    className="w-full rounded-lg btn rounded-lg-primary"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    disabled={
                      !(
                        values.referralCode !== "" ||
                        values.otherOption !== "" ||
                        values.discoveryOption !== ""
                      )
                    }
                  >
                    <span className="text-white capitalize md:text-md max-sm:!text-sm">
                      {t("next")}
                    </span>
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default DiscoveryModal;
