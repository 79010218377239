import React, { useState, useEffect, useLayoutEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import CashLayout from "./CashLayout";
import { CircularProgress } from "@material-ui/core";
import Avatar from "react-avatar";
import InputField from "./forms/InputField";
import * as Sentry from "@sentry/browser";
import {
  getLoggedInUserReceivingCountries,
  translateError,
} from "../utilities/help";
import { addContact, checkIfUserExists } from "../store/features/Auth/Auth";
import BeneficiaryCard from "./BeneficiaryCard/BeneficiaryCard";
import { toast } from "material-react-toastify";
import {
  getAppliedFeesForTransfers,
  initTransfer,
} from "../store/features/Transfer/Transfer";
import { extractError } from "../utility";
import { isArray } from "util";
import { useHistory } from "react-router-dom";
import BeneficiarySummary from "./BeneficiarySumary";
import CountryPicker from "./CountryPicker";
import Contacts from "./Contacts";

const RATE = 655.957;

const PayByLink: React.FC<any> = ({ closePayByLInk, t }) => {
  const {
    transfer,
    auth: { user, lang, favorites },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [receivingCountries, setReceivingCountries] = useState([]);
  const [errorGot, setErrorGot] = React.useState("");
  const [fees, setFees] = useState(0);
  const [processingPayment, setProcessingPayment] = React.useState(false);
  const [fetchingFees, setFetchingFees] = React.useState(false);
  const [selectedCountryReset, setSelectedCountryReset] =
    useState<boolean>(false);
  const [benefactor, setBenefactor] = React.useState<any>({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    found: false,
    country: "",
    country_code: "",
    full_phone_number: "",
    isNew: false,
    transferCountry: null,
  });
  const [payment, setPayment] = React.useState<any>({
    reason: "",
    payMethod: "",
    amountCfa: "",
    amountEuro: "",
    receiving_country_id: "",
    sending_country_id: "",
    cashin_method_id: "",
    phone_number: "",
  });
  const [response, setResponse] = React.useState<any>(null);
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [size, setSize] = React.useState<any>([0, 0]);
  const steps = [t("beneficiary"), t("Amount"), t("Confirmation")];
  const history = useHistory();
  const [spread_config, setSpreadConfig] = useState({
    spread: 0,
    net_exchange_rate: 655.957,
    exchange_rate: 655.957,
    received_amount: 0,
  });

  const fetchAppliesFees = () => {
    try {
      if (!benefactor.transferCountry) return;
      const cashin_method = benefactor.transferCountry.cash_in_methods.find(
        (method: any) =>
          method.cash_in_method.payment_type.name === "bank_transfer"
      );
      if (!cashin_method) return;
      getAppliedFeesForTransfers(
        {
          euro_amount: payment?.amountEuro,
          sending_country_id: benefactor?.transferCountry?.id,
          receiving_country_id: transfer.loggedInUserCountry.id,
          cashin_method_id: cashin_method.cash_in_method.id,
        },
        benefactor?.id
      )
        .then((response: any) => {
          setErrorGot("");
          setFees(0);
          setFees(response.fee);
          setFetchingFees(false);
          const s_config = response.fee_calculation.find((value: any) =>
            value.hasOwnProperty("spread")
          );

          if (s_config) {
            setSpreadConfig(s_config);
          }
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          setFetchingFees(false);
          const err = extractError(error);
          if (isArray(err)) {
            const errormsg = err
              .map((e: any) => translateError(e.message, t))
              .join("\n");
            setErrorGot(errormsg);
          } else {
            setErrorGot(translateError(err, t));
          }
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const fetchBeneficiary = () => {
    try {
      setFetching(true);
      checkIfUserExists({
        country_code: parseInt(benefactor?.country_code),
        phone_number: benefactor.phone_number,
      })
        .then((result: any) => {
          setFetching(false);
          if (result.exists) {
            setBenefactor((prev: any) => {
              return {
                ...prev,
                found: true,
                ...result.customer,
                transferCountry: transfer.danaPayCountries.find(
                  (country: any) =>
                    country?.country_code === benefactor?.country_code
                ),
              };
            });
          } else {
            setBenefactor((prev: any) => {
              return { ...prev, found: false, isNew: true };
            });
          }
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const createNewContact = () => {
    try {
      const contact_country = transfer.danaPayCountries.find(
        (val: any) => val?.country_code === benefactor?.country_code
      );

      if (
        contact_country?.preferred_notification_channel === "mail" &&
        !/^\S+@\S+\.\S+$/.test(benefactor.email)
      ) {
        toast.error(t("email_not_valid"));
        return;
      }

      if (
        benefactor.last_name.length === 0 ||
        benefactor.first_name.length === 0
      ) {
        toast.error(t("fields_required"));
        return;
      }

      const payload_data = {
        last_name: benefactor.last_name,
        first_name: benefactor.first_name,
        email: benefactor.email,
        country_code: parseInt(benefactor?.country_code),
        phone_number: benefactor.phone_number,
      };

      addContact(payload_data)
        .then((data: any) => {
          setBenefactor((prev: any) => {
            return {
              ...prev,
              ...data.beneficiary,
              found: true,
              transferCountry: transfer.danaPayCountries.find(
                (country: any) =>
                  country.name.toLowerCase() ===
                  benefactor.country.toLowerCase()
              ),
            };
          });
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
          if (error.status === 422 || error.status === 500) {
            const err: any = Object.values(error.data.errors)[0];
            toast.error(err);
          }
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const submitRequest = () => {
    try {
      if (!benefactor.transferCountry) return;
      const cashin_method = benefactor?.transferCountry?.cash_in_methods.find(
        (method: any) =>
          method.cash_in_method.payment_type.name === "bank_transfer"
      );
      if (!cashin_method) return;
      const payload = {
        cashin_method_id: cashin_method.cash_in_method.id,
        amount_without_fees_in_euro: payment.amountEuro,
        payment_delivery: false,
        recipient_first_name: user.first_name,
        recipient_last_name: user.last_name,
        phone_number: benefactor.phone_number,
        country_code: benefactor?.country_code,
        sending_country_id: benefactor?.transferCountry?.id,
        receiving_country_id: transfer.loggedInUserCountry.id,
        destination_city: user.birth_city,
        destination_quarter: user.birth_location,
        is_escrowed: false,
        reason: payment.reason,
        return_link: true,
      };

      initTransfer(payload)
        .then((res: any) => {
          setResponse(res);
          setActiveStep((prev) => prev + 1);
        })
        .catch((error: any) => {
          setProcessingPayment(false);

          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const renderForms = () => {
    if (activeStep === 0) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-4"></div>
            {benefactor.found ? (
              <div className="mt-4 rounded-lg col-md-4 lg:p-4 md:p-1 sm:p-1 ">
                <div className="flex flex-col items-center justify-center">
                  <h3 className="my-3 text-2xl font-bold text-center">
                    {t("beneficiary")}
                  </h3>
                  <small className="text-center">
                    {t("text_bene_details")}:{" "}
                    <b>{benefactor.full_phone_number}</b>
                  </small>
                </div>

                <BeneficiaryCard beneficiary={benefactor} />
                <div className="row">
                  <div className="col-md-6">
                    <button
                      onClick={() =>
                        setBenefactor({
                          first_name: "",
                          last_name: "",
                          phone_number: "",
                          email: "",
                          found: false,
                          country: "",
                          country_code: "",
                          full_phone_number: "",
                          isNew: false,
                          transferCountry: null,
                        })
                      }
                      className="w-full px-10 my-2 rounded-lg btn btn-dark"
                    >
                      <span className="text-white capitalize">
                        {t("cancel")}
                      </span>
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      onClick={() => setActiveStep((prev) => prev + 1)}
                      className="w-full px-10 my-2 rounded-lg btn"
                      style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    >
                      <span className="text-white capitalize">{t("next")}</span>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {benefactor.isNew ? (
                  <div className="mt-20 rounded-lg shadow-lg col-md-4 lg:p-4 md:p-1 sm:p-1 ">
                    <div className="flex flex-col items-center justify-center">
                      <h3 className="my-3 text-2xl font-bold">
                        {t("beneficiary")}
                      </h3>
                      <p className="text-center">
                        <b>{`${benefactor.full_phone_number}`}</b>{" "}
                        {t("text_tell_us_more")}
                      </p>
                    </div>
                    <label>
                      <small>{t("FirstName")}</small>
                    </label>
                    <input
                      type="text"
                      className="mb-1 form-control"
                      onChange={(e: any) =>
                        setBenefactor((prev: any) => {
                          return { ...prev, first_name: e.target.value };
                        })
                      }
                      value={benefactor.first_name}
                    />
                    <label>
                      <small>{t("LastName")}</small>
                    </label>
                    <input
                      type="text"
                      className="mb-1 form-control"
                      onChange={(e: any) =>
                        setBenefactor((prev: any) => {
                          return { ...prev, last_name: e.target.value };
                        })
                      }
                      value={benefactor.last_name}
                    />

                    <label>
                      <small>{t("email")}</small>
                    </label>
                    <input
                      type="text"
                      className="mb-1 form-control"
                      onChange={(e: any) =>
                        setBenefactor((prev: any) => {
                          return { ...prev, email: e.target.value };
                        })
                      }
                      value={benefactor.email}
                    />

                    <div className="row">
                      <div className="col-md-6">
                        <button
                          onClick={() =>
                            setBenefactor({
                              first_name: "",
                              last_name: "",
                              phone_number: "",
                              email: "",
                              found: false,
                              country: "",
                              country_code: "",
                              full_phone_number: "",
                              isNew: false,
                            })
                          }
                          className="w-full px-10 my-6 rounded-lg btn btn-dark"
                        >
                          <span className="text-white capitalize">
                            {t("cancel")}
                          </span>
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          onClick={createNewContact}
                          className="w-full px-10 my-6 rounded-lg btn"
                          style={{ backgroundColor: "rgb(3, 115, 117)" }}
                        >
                          <span className="text-white capitalize">
                            {t("next")}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-4 mt-20 rounded-lg shadow-lg col-md-4 ">
                    <h3 className="my-3 text-2xl font-bold text-center">
                      {t("beneficiary")}
                    </h3>
                    <p className="text-center">{t("enterPhoneNumber")}</p>
                    <br />
                    <div className="mb-3">
                      <CountryPicker
                        setCountry={(text: any) => {
                          setBenefactor((prev: any) => {
                            return {
                              ...prev,
                              country: text.name,
                              country_code: text.mobileCode,
                            };
                          });
                        }}
                        cash_Out_Method_Name={""}
                        country={benefactor.country}
                        onChange={(text: string) => {
                          setBenefactor((prev: any) => {
                            return {
                              ...prev,
                              full_phone_number: `${benefactor?.country_code}${text}`,
                              phone_number: text,
                            };
                          });
                        }}
                        value={benefactor.phone_number}
                        withPhoneInput={true}
                        withChildren={true}
                        setSelectedCountry={(e: any) =>
                          setSelectedCountryReset(e)
                        }
                      >
                        <div
                          style={{
                            minHeight: 70,
                            padding: 10,
                            marginBottom: 10,
                          }}
                        >
                          <small>
                            Select the beneficiary into the contact list or
                            enter a phone number
                          </small>

                          {favorites.length > 0 && (
                            <>
                              <div className="flex flex-row items-center overflow-x-scroll">
                                <a>
                                  <Contacts buttonOnly={true} />
                                </a>
                                {favorites
                                  .filter(
                                    (usr: any) =>
                                      parseInt(usr.favorite?.country_code) ===
                                      parseInt(benefactor?.country_code)
                                  )
                                  .filter(
                                    (usr: any) =>
                                      usr.favorite.first_name !== null
                                  )
                                  .map((contact: any, index: number) => (
                                    <a
                                      onClick={() =>
                                        setBenefactor(contact.favorite)
                                      }
                                      className="flex flex-col items-center justify-center mx-3 cursor-pointer"
                                      key={index}
                                    >
                                      <Avatar
                                        name={contact?.favorite?.full_name}
                                        size="55"
                                        round
                                        textSizeRatio={3}
                                      />
                                      <small
                                        style={{
                                          fontSize: 10,
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {contact?.favorite?.first_name}
                                      </small>
                                    </a>
                                  ))}
                              </div>
                            </>
                          )}
                        </div>
                      </CountryPicker>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        {fetching ? (
                          <div className="flex items-center justify-center w-full">
                            <CircularProgress />
                          </div>
                        ) : (
                          <button
                            onClick={() => fetchBeneficiary()}
                            className="w-full px-10 my-6 rounded-lg btn"
                            style={{ backgroundColor: "rgb(3, 115, 117)" }}
                          >
                            <span className="text-white capitalize">
                              {t("next")}
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="col-md-4"></div>
          </div>
        </div>
      );
    }
    if (activeStep === 1) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 lg:p-4 md:p-0 sm:px-0">
              <div className="pt-2 shadow-lg row lg:m-4 sm:m-1">
                <div className="border-b-2 col-md-12">
                  <BeneficiarySummary
                    name={benefactor?.full_name}
                    company={benefactor.full_phone_number}
                  />
                </div>
                <div className="px-4 col-md-6">
                  <div className="mb-3">
                    <InputField
                      handleChange={(e: any) =>
                        setPayment((prev: any) => {
                          return { ...prev, reason: e.target.value };
                        })
                      }
                      value={payment.reason}
                      label={t("reason")}
                      type="text"
                      name="reason"
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      handleChange={(text: any) => {
                        setPayment((prev: any) => {
                          return {
                            ...prev,
                            amountCfa: parseFloat(text.target.value) * RATE,
                            amountEuro: parseFloat(text.target.value),
                          };
                        });
                      }}
                      value={payment.amountEuro}
                      label={t("eur_amount")}
                      type="number"
                      name=""
                      error={errorGot}
                    />
                  </div>

                  <div className="mb-3">
                    <InputField
                      handleChange={(text: any) => {
                        setPayment((prev: any) => {
                          return {
                            ...prev,
                            amountEuro: parseFloat(text.target.value) / RATE,
                            amountCfa: parseFloat(text.target.value),
                          };
                        });
                      }}
                      value={payment.amountCfa}
                      label={t("cfa_amount")}
                      type="number"
                      name=""
                      error=""
                    />
                  </div>

                  <div className="py-3">
                    <button
                      onClick={() => setActiveStep((prev) => prev - 1)}
                      className="px-10 bg-gray-600 rounded-md btn"
                    >
                      <small className="mx-2 font-bold text-white">
                        {t("Back")}
                      </small>
                    </button>
                    <button
                      onClick={() => submitRequest()}
                      className="px-10 mx-3 rounded-md btn"
                      style={{ backgroundColor: "rgb(3, 115, 117)" }}
                      disabled={processingPayment}
                    >
                      <small className="mx-2 font-bold text-white">
                        {t("continue")}
                      </small>
                    </button>
                  </div>
                </div>
                <div className="px-4 col-md-6">
                  <div>
                    <div className="flex flex-row justify-between my-4">
                      <p>{t("amount")}</p>
                      <b>
                        <CashLayout cash={payment.amountEuro} />
                      </b>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <p>{t("fees")}</p>
                      <b>
                        {fetchingFees ? (
                          <CircularProgress size={14} />
                        ) : (
                          <small>
                            <CashLayout cash={fees} />
                          </small>
                        )}
                      </b>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <p>{t("total")}</p>
                      <b>
                        <CashLayout cash={payment.amountEuro + fees} />
                      </b>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <p>{t("exchange_rate")}</p>
                      <b>1EUR = {spread_config.exchange_rate} CFA</b>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <p>{t("PaymentMode")}</p>
                      <b>{"Bank transfer"}</b>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <p>{t("transaction_type")}</p>
                      <b>{t("Instant")}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      );
    }
    if (activeStep === 2) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="flex flex-col items-center justify-center py-20 my-10 text-center shadow-md col-md-8">
              <h1 className="mb-2 text-4xl font-bold">
                {t("Your_payment_has_been_title")}
              </h1>
              {lang === "en" && (
                <>
                  <p>
                    Your payment request have been taken in account. We have
                    sent an email to your payer. As soon as he makes the
                    payment, we will let you know.
                  </p>

                  <small className="mt-4 text-green-500">
                    You can also send this link to the payer so he proceed to
                    the payment
                  </small>
                </>
              )}

              {lang === "fr" && (
                <>
                  <p>
                    Votre demande de paiement a été prise en compte. Nous avons
                    envoyé un email à votre payeur. Dès qu'il aura effectué le
                    paiement, nous vous le ferons savoir.
                  </p>
                  <small className="mt-4 text-green-500">
                    Vous pouvez également envoyer ce lien au payeur pour qu'il
                    procède au paiement.
                  </small>
                </>
              )}
              <br />
              <div className="flex flex-row items-center justify-between">
                <small className="p-2 bg-gray-200">
                  {response.details.link}
                </small>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(response.details?.link);
                    toast.info(t("link_copied"));
                  }}
                  className="px-2 rounded-lg btn "
                  style={{ backgroundColor: "rgb(3, 115, 117)", color: "#fff" }}
                >
                  <small className="text-white">{t("copy_link")}</small>
                </button>
              </div>
              <br />
              <br />
              <button
                className="px-10 my-2 mr-10 rounded-lg btn"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                onClick={() => history.push("/")}
              >
                <small className="text-white">{t("BackHome")}</small>
              </button>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    const countries = getLoggedInUserReceivingCountries(transfer, user);
    setReceivingCountries(countries);
  }, []);

  useEffect(() => {
    fetchAppliesFees();
  }, [payment.amountEuro]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div>
      <div
        className="shadow-md"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <div className="container flex flex-row items-center justify-between py-2 text-white">
          <p className="font-bold">{t("Pay_By_Link")}</p>
          <button
            onClick={() => closePayByLInk()}
            style={{ height: 40, width: 40, borderRadius: 20 }}
            className="flex items-center justify-center shadow-lg"
          >
            <i className="m-0 text-white fa fa-close"></i>
          </button>
        </div>
      </div>
      <div className="container">
        {size[0] > 500 && (
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        {activeStep === steps.length ? (
          <div className="flex flex-col justify-center w-full p-10 m-auto md:w-1/2 "></div>
        ) : (
          <div>{renderForms()}</div>
        )}
      </div>
    </div>
  );
};

export default PayByLink;
