import React, { useCallback, useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import IndividualAccount from "./IndividualAccount";
// import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import {
  fetchUserBankAccounts,
  fetchMMAccounts,
} from "../../store/features/Auth/Auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearSelectedBank,
  setAccounts,
  setMFIAccounts,
  setMMAccounts,
  setSelectedBank,
  updateDanaPayBankAccounts,
} from "../../store/features/Auth/AuthSlice";
import yup from "@raisin/yup-validations";
import MfiAccountFormModal from "../MfiAccountFormModal";
import {
  getDanapayBanks,
  getHub2BankAccounts,
  getUsersMFIAccounts,
} from "../../store/features/Transfer/Transfer";
import AddAccountModal from "./AddAccountModal";
import EditAccountModal from "./EditAccountModal";
import { CircularProgress } from "@material-ui/core";

type Props = {
  selected_account: any;
  type?: string;
  isDanapay?: boolean;
  dp_accounts?: any;
};

const responsive = {
  768: { items: 1 },
  1024: { items: 2 },
};

const Accounts: React.FC<Props> = ({
  selected_account,
  type = "bank",
  isDanapay = false,
  dp_accounts = null,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    transfer,
    auth: { bank_accounts, mobile_accounts, mfi_accounts, user },
  } = useAppSelector((state) => state.persistedReducer);
  const [processing, setProcessing] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [addFormOpen, setAddFormOpen] = React.useState<boolean>(false);

  // mfi accounts
  const [showMfiAccountModal, setshowMfiAccountModal] = useState(false);

  // all hub2 available bank names/account
  // custom bank account name
  // bank names loading
  const [hub2BankAccounts, sethub2BankAccounts] = useState([]);
  const [fetchingBankNames, setfetchingBankNames] = useState(false);

  // fetch hub2 accounts according to country code
  const fetchHub2BanksAccordingToCountry = (countryCode: Number) => {
    try {
      const other = [{ id: 99, name: "Other" }];

      setfetchingBankNames(true);
      getHub2BankAccounts(countryCode)
        .then((res: any) => {
          const bankAccounts = res.data.filter(
            (account: any) => account.institution.type == "bank_transfer"
          );
          if (bankAccounts.length > 0) {
            sethub2BankAccounts(
              bankAccounts
                .map((acc: any) => ({ name: acc.name, id: acc.institution_id }))
                .filter((itm: any) => itm?.name !== "Other")
                .concat(other)
            );
          } else {
            sethub2BankAccounts([]);
          }
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        })
        .finally(() => setfetchingBankNames(false));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // get hub 2 bank accounts
  useEffect(() => {
    if (
      selected_account !== undefined &&
      selected_account !== null &&
      (addFormOpen || editModalOpen)
    ) {
      fetchHub2BanksAccordingToCountry(Number(selected_account?.country_code));
    }
  }, [selected_account, editModalOpen, addFormOpen]);

  const fetchBankAccount = useCallback(() => {
    try {
      setloadingCarousalListing(true);
      fetchUserBankAccounts()
        .then((response: any) => {
          dispatch(setAccounts(response.data));
          setcarousalListing(response.data);
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        })
        .finally(() => setloadingCarousalListing(false));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  const fetchMMAccount = useCallback(() => {
    try {
      setloadingCarousalListing(true);
      fetchMMAccounts()
        .then((response: any) => {
          dispatch(setMMAccounts(response.data));
          setcarousalListing(response.data);
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        })
        .finally(() => setloadingCarousalListing(false));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  const setActive_select = (account: any) => {
    dispatch(setSelectedBank(account));
  };

  // fetch user mfi accounts
  const handleFetchUserMFIAccounts = async () => {
    try {
      setloadingCarousalListing(true);
      getUsersMFIAccounts()
        .then((res: any) => {
          dispatch(setMFIAccounts(res.data));
          setcarousalListing(res.data);
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        })
        .finally(() => setloadingCarousalListing(false));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // carousal listing
  const [carousalListing, setcarousalListing] = useState([]);
  const [loadingCarousalListing, setloadingCarousalListing] = useState(false);

  useEffect(() => {
    if (isDanapay && type == "bank") {
      setloadingCarousalListing(true);
      getDanapayBanks(user?.country_code)
        .then((response: any) => {
          setcarousalListing(response?.bank_accounts);
          dispatch(updateDanaPayBankAccounts(response?.bank_accounts));
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        })
        .finally(() => setloadingCarousalListing(false));
    } else if (!isDanapay && type == "bank") {
      fetchBankAccount();
    } else if (type === "mfi") {
      handleFetchUserMFIAccounts();
    } else if (type === "mobile") {
      fetchMMAccount();
    }
  }, [type]);

  return (
    <>
      <div className="mx-2 lg:mx-4 md:mx-2 sm:mx-2 accountsdiv">
        <div className="flex flex-row items-center justify-between p-2 lg:mx-10 sm:mx-1 md:mx-2">
          <p style={{ color: "rgb(3, 115, 117)" }} className="font-bold">
            {type === "bank" &&
              `${!isDanapay ? bank_accounts?.length : dp_accounts.length} ${t(
                "bank_accounts"
              )}`}
            {type === "mobile" &&
              `${mobile_accounts?.length} ${t("mobile_money_accounts")}`}
            {type === "mfi" && `${mfi_accounts?.length} ${t("mfi accounts")}`}
          </p>

          {!isDanapay && (
            <button
              className="btn btn-md text-[12.6px] leading-6"
              style={{ backgroundColor: "rgb(3, 115, 117)", color: "#fff" }}
              onClick={() => {
                if (type == "mfi") {
                  setshowMfiAccountModal(true);
                  setActive_select(null);
                } else {
                  setAddFormOpen(true);
                  dispatch(clearSelectedBank());
                }
              }}
            >
              {type === "bank" && <>{t("Add_bank_account")}</>}
              {type === "mfi" && <>{t("Add_MFI_account")}</>}
              {type === "mobile" && <>{t("Add_Mobile_account")}</>}
            </button>
          )}
        </div>
        <div className="flex flex-row overflow-x-scroll bg-white lg:p-3 md:p-1 sm:p-1 202">
          {loadingCarousalListing ? (
            <div className="flex justify-center w-full h-48 items-center">
              <CircularProgress />
            </div>
          ) : (
            carousalListing.length > 0 && (
              <AliceCarousel
                activeIndex={index}
                mouseTracking
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls
                renderPrevButton={() => {
                  return (
                    <div className="absolute p-2 rounded-md cursor-pointer -bottom-3 right-12 border-1">
                      <i className="m-0 fa fa-arrow-left"></i>
                    </div>
                  );
                }}
                renderNextButton={() => {
                  return (
                    <div className="absolute right-0 p-2 rounded-md cursor-pointer -bottom-3 border-1">
                      <i className="m-0 fa fa-arrow-right"></i>
                    </div>
                  );
                }}
              >
                {carousalListing?.map((value: any, index: number) => {
                  return (
                    <IndividualAccount
                      account={value}
                      setcarousalListing={setcarousalListing}
                      setEditModalOpen={() => {
                        if (type !== "mfi") {
                          setEditModalOpen(true);
                        } else {
                          setshowMfiAccountModal(true);
                        }
                      }}
                      type={type}
                      key={index}
                      isDanapay={isDanapay}
                      setActive={(acc: any) => setActive_select(acc)}
                    />
                  );
                })}
              </AliceCarousel>
            )
          )}
        </div>
      </div>

      <MfiAccountFormModal
        selected_account={selected_account}
        type={type}
        t={t}
        open={showMfiAccountModal}
        onCloseCallback={() => {
          setshowMfiAccountModal(false);
          handleFetchUserMFIAccounts();
        }}
      />

      {/* edit account modal */}
      <EditAccountModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        sethub2BankAccounts={sethub2BankAccounts}
        t={t}
        selected_account={selected_account}
        hub2BankAccounts={hub2BankAccounts}
        setProcessing={setProcessing}
        transfer={transfer}
        type={type}
        toast={toast}
        fetchBankAccount={fetchBankAccount}
        yup={yup}
        fetchMMAccount={fetchMMAccount}
        fetchingBankNames={fetchingBankNames}
        fetchHub2BanksAccordingToCountry={fetchHub2BanksAccordingToCountry}
        processing={processing}
      />

      {/* add account modal */}
      <AddAccountModal
        open={addFormOpen}
        onClose={() => {
          setAddFormOpen(false);
        }}
        sethub2BankAccounts={sethub2BankAccounts}
        yup={yup}
        type={type}
        transfer={transfer}
        selected_account={selected_account}
        t={t}
        toast={toast}
        setAddFormOpen={setAddFormOpen}
        setProcessing={setProcessing}
        fetchBankAccount={fetchBankAccount}
        fetchMMAccount={fetchMMAccount}
        hub2BankAccounts={hub2BankAccounts}
        processing={processing}
        fetchingBankNames={fetchingBankNames}
        fetchHub2BanksAccordingToCountry={fetchHub2BanksAccordingToCountry}
      />
    </>
  );
};

export default Accounts;
