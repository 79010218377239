import React, { useState, useEffect, useLayoutEffect } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import {
  getKYCSumSubToken,
  getSumSubKYBToken,
  getSumSubToken,
} from "../store/features/Auth/Auth";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../store/hooks";
import * as Sentry from "@sentry/react";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { isEmpty } from "../utilities/help";

type Props = {
  finish?: any;
  phone?: string;
  back?: any;
  residencyLevelName?: any;
  isKYB?: any;
  toast?: any;
  isModal?: any;
};

const SumSub = ({
  finish,
  phone,
  back = null,
  residencyLevelName,
  isKYB,
  toast,
  isModal,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    auth: { user, lang, signUpData },
  } = useAppSelector((state) => state.persistedReducer);

  const [sumSubConfig, setSumSubConfig] = useState<any>({
    token: null,
  });
  const [activeLang, setActiveLang] = useState<string>("fr");
  const [isFetchingToken, setisFetchingToken] = useState(false);

  const setSumSUbToken = async (callback: any) => {
    try {
      setisFetchingToken(true);
      const tkn: any = await callback;
      if (tkn) {
        const newConfig = { ...sumSubConfig, token: tkn?.token };
        setSumSubConfig(newConfig);
        setisFetchingToken(false);
      }
    } catch (error: any) {
      toast.error(t(error?.data?.message || error?.message || error));
      setisFetchingToken(false);
      setSumSubConfig({
        token: null,
      });
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!isKYB) {
      if (
        (residencyLevelName !== undefined && residencyLevelName !== "") ||
        !isEmpty(signUpData?.residency)
      ) {
        setSumSUbToken(
          getSumSubToken({
            level_name: residencyLevelName || signUpData?.residency,
          })
        );
      } else {
        setSumSUbToken(getKYCSumSubToken());
      }
    } else {
      setSumSUbToken(getSumSubKYBToken());
    }
  }, [residencyLevelName, signUpData]);

  useLayoutEffect(() => {
    const userLang = lang === "en" ? "en" : "fr";
    setActiveLang(userLang);
  }, []);

  const [isPendingVerification, setisPendingVerification] = useState(false);

  return (
    <>
      <h2 className="text-2xl font-bold">{t("sumSubTitle")}</h2>
      <p>{t("sumSubDescription")}</p>
      {sumSubConfig.token !== null && (
        <SumsubWebSdk
          accessToken={sumSubConfig.token}
          expirationHandler={() => sumSubConfig.token}
          config={{
            lang: activeLang,
            email: user?.email,
            phone,
            i18n: {
              document: {
                subTitles: {
                  IDENTITY: t("sumsubText"),
                },
              },
            },
            uiConf: {
              customCssStr:
                ":root {\n  --black: rgb(3, 115, 117);\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}",
            },
          }}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          onMessage={(type: any, payload: any) => {
            if (
              type == "idCheck.moduleResultPresented" &&
              (payload?.answer == "GREEN" || payload?.answer == "YELLOW")
            ) {
              history.push("/dasboard");
            }

            if (
              type == "idCheck.applicantStatus" &&
              (payload?.reviewStatus == "pending" ||
                payload?.reviewStatus == "approved" ||
                payload?.reviewStatus == "completed" ||
                payload?.reviewStatus == "success")
            ) {
              setisPendingVerification(true);
            }
          }}
          onError={(data: any) => null}
          className="mb-3"
        />
      )}
      {isFetchingToken && (
        <div className="w-full h-48 my-10 flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
      {sumSubConfig.token == null && !isFetchingToken && (
        <div className="w-full h-48 my-10 flex justify-center items-center">
          <div>
            <i
              style={{
                fontSize: 100,
                height: 90,
                margin: 0,
                color: "#777",
              }}
              className="text-4xl fa fa-frown-o"
            ></i>
            <div>{t("sumsub_server_error")}</div>
          </div>
        </div>
      )}
      <div className="mb-5">
        {back && !isPendingVerification && (
          <button className="mx-2 btn btn-dark" onClick={back}>
            <small className="mx-10 font-bold">{t("back")}</small>
          </button>
        )}

        <button
          className={
            !isModal
              ? "mx-2 btn btn-light"
              : `font-bold ${
                  isPendingVerification
                    ? "bg-[#317174] text-white"
                    : "bg-[#F0F0F0]"
                } px-12 py-3 rounded-[5px]`
          }
          onClick={() => {
            finish();
            window.heap.track("Selfy and document uploaded", {
              email: user.email,
              country: user.country,
              phone: user.full_phone_number,
              name: user.full_name,
            });
          }}
        >
          {!isModal ? (
            <small className="mx-10 font-bold">
              {isPendingVerification ? t("sumsub_finish") : t("sumsub_skip")}
            </small>
          ) : (
            <>{isPendingVerification ? t("sumsub_finish") : t("sumsub_skip")}</>
          )}
        </button>
      </div>
    </>
  );
};

export default SumSub;
