import { useState } from "react";
import { sendResendCode } from "../../store/features/Transfer/Transfer";
import { translateError } from "../../utilities/help";
import { extractError } from "../../utility";
import * as Sentry from "@sentry/browser";

const WithdrawVerificationModal = (props: any) => {
  const {
    size,
    steps,
    t,
    c_channel,
    setCommunicationChannel,
    setOTP,
    lang,
    handlePrev,
    cashoutResponse,
    confirmOTP,
    toast,
  } = props;

  const [resending, setResending] = useState<boolean>(false);

  const resendCode = () => {
    try {
      setResending(true);
      sendResendCode({ channel: c_channel }, cashoutResponse?.cashout.id)
        .then((res: any) => {
          setResending(false);
          toast.info(t("code_sent"));
        })
        .catch((error: any) => {
          setResending(false);
          toast.warning(t(error?.data?.message));
          Sentry.captureException(error);
          // toast.error(translateError(extractError(error), t));
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        {size[0] < 900 && (
          <div className="py-4 col-md-12">
            <h2>{steps[2]}</h2>
          </div>
        )}
        <div className="col-md-3"></div>
        <div className="my-3 text-center rounded-lg col-md-6">
          <div style={{ marginTop: 10 }} className="p-10 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold">
              {t("Type_verification_code")}
            </h2>
            <div className="py-4 row g-3">
              <div
                className={`col-md-6 ${
                  c_channel === "mail" ? "border-b-4 border-green-700" : ""
                }`}
              >
                <a
                  onClick={() => setCommunicationChannel("mail")}
                  className={`cursor-pointer`}
                >
                  <small
                    className={`text-sm ${
                      c_channel === "mail"
                        ? "font-bold text-green-700"
                        : "text-gray-600"
                    }`}
                  >
                    {t("send_by_mail")}
                  </small>
                </a>
              </div>
              <div
                className={`col-md-6 ${
                  c_channel === "sms" ? "border-b-4 border-green-700" : ""
                }`}
              >
                <a
                  onClick={() => setCommunicationChannel("sms")}
                  className={` cursor-pointer`}
                >
                  <small
                    className={`text-xs ${
                      c_channel === "sms"
                        ? "font-bold text-green-700"
                        : "text-gray-600"
                    }`}
                  >
                    {t("send_by_sms")}
                  </small>
                </a>
              </div>
            </div>

            <div className="flex flex-row items-center bg-gray-200 rounded-lg">
              <input
                type="text"
                className="flex flex-1 placeholder-gray-500 bg-gray-200 border-none rounded-lg focus:outline-none form-control placeholder:text-sm laceholder:italic"
                required
                maxLength={6}
                onChange={(e: any) => setOTP(e.target.value)}
                placeholder="code"
              />
              {resending ? (
                <small className="px-3 mr-4 text-green-800">
                  {t("sending")}
                </small>
              ) : (
                <a className="flex flex-row items-center mr-4 bg-gray-200 rounded-lg cursor-pointer">
                  <button
                    className="px-4 mt-3 mb-3 rounded-lg btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    onClick={() => resendCode()}
                  >
                    <small className="font-bold text-white">
                      {t("send_code")}
                    </small>
                  </button>
                </a>
              )}
            </div>

            {lang === "fr" ? (
              <small className="text-gray-700">
                Cliquez sur <b>Envoyer le code</b> pour recevoir un nouveau
                code.
              </small>
            ) : (
              <small className="text-gray-700">
                Click on <b>Send code</b> to receive a new code
              </small>
            )}

            {resending ? (
              <div className="flex items-center justify-center p-4">
                <small className="font-bold">{t("resending")}...</small>
              </div>
            ) : (
              <>
                <div>
                  <button
                    className="px-4 mx-3 mt-3 mb-4 border rounded-lg btn"
                    onClick={handlePrev}
                  >
                    <small className="font-bold text-black">{t("back")}</small>
                  </button>
                  <button
                    className="px-4 mt-3 mb-4 rounded-lg btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    onClick={() => confirmOTP()}
                  >
                    <small className="font-bold text-white">
                      {t("ConfirmCashout")}
                    </small>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default WithdrawVerificationModal;
