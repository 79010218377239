import React, { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import * as Sentry from "@sentry/browser";
import { TransitionProps } from "@material-ui/core/transitions";
import SumSub from "./Sumsub";
import { toast } from "material-react-toastify";
import { currentBalance } from "../store/features/Transfer/Transfer";
import { checkLoggedIn, isEmpty } from "../utilities/help";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import IndividualBanners from "./Dashboard/IndividualBanners";
import CompanyBanners from "./Dashboard/CompanyBanners";
import { editUser } from "../store/features/Auth/AuthSlice";
import { useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
type Props = {
  t: any;
  setWithDrawOpen: any;
  startTransfer: any;
  startBulkPayments: any;
  size?: any;
  openPayByLink?: any;
  startDeposit?: any;
  showLoader?: any;
};

const NotActiveComp = ({
  t,
  setWithDrawOpen,
  startBulkPayments,
  startTransfer,
  size,
  openPayByLink,
  startDeposit,
  showLoader,
}: Props) => {
  const { signUpData } = useAppSelector((state) => state.persistedReducer.auth);
  const [userDataFetching, setuserDataFetching] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [toggleUiUpdate, settoggleUiUpdate] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const residencyList = [
    {
      id: 1,
      name: t("residency_option_1"),
      val: "Non EU Person",
    },
    {
      id: 2,
      name: t("residency_option_2"),
      val: "EU Person",
    },
    {
      id: 3,
      name: t("residency_option_3"),
      val: "WAEMU/CEMAC Person",
    },
  ];

  const [residencyLevelName, setresidencyLevelName] = useState("");
  const notAllowBulkTransferCountry = [
    //"France",
    //"Italy",
    "Madagascar",
  ];

  const [user, setuser]: any = useState(null);
  // set correct residency level from response
  useEffect(() => {
    try {
      if (user?.residency !== undefined && user?.residency !== null) {
        const residency = residencyList.find(
          (item: any) =>
            item.val.toLowerCase() == user?.residency.toLowerCase() ||
            item.name.toLowerCase() == user?.residency.toLowerCase()
        );
        if (residency !== undefined) {
          setresidencyLevelName(residency.val);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [user]);

  useEffect(() => {
    setuserDataFetching(true);
    currentBalance()
      .then((response: any) => {
        setuser(response);
        dispatch(editUser(response));
      })
      .finally(() => setuserDataFetching(false));
  }, []);

  const canPerformActions = () => {
    if (user?.is_individual) {
      if (user?.is_active) {
        return true;
      } else {
        return false;
      }
    } else {
      if (user?.is_active && user?.company?.is_active) {
        return true;
      } else {
        return false;
      }
    }
  };

  const redirectToMeeting = () => {
    window.open(
      `https://calendly.com/danapay_pro/ouverture-de-compte-danapay-pro-clone`,
      "_blank"
    );
  };
  const redirectToWhatsapp = () => {
    window.open(`https://wa.me/message/CN4NWDBLAQUBL1`, "_blank");
  };

  const handleBusinessVerification = () => {
    checkLoggedIn(user, signUpData, isEmpty, history);
  };

  return (
    <>
      {userDataFetching && (
        <div className="mx-auto w-min">
          {" "}
          {showLoader && <CircularProgress />}
        </div>
      )}
      <div className={userDataFetching ? "hidden" : ""}>
        {canPerformActions() ? (
          <>
            {setWithDrawOpen !== null && (
              <div
                className={`${
                  size[0] > 500 ? "flex flex-row" : " flex flex-wrap flex-row"
                }`}
              >
                {/* <button
                  className={`btn   bg-blue-400 ${
                    size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2 "
                  }`}
                  onClick={() => startDeposit()}
                  style={{ backgroundColor: "rgb(3, 115, 117)" }}
                >
                  <i className="mr-3 text-white fa fa-plus-square" />
                  <b className="font-bold text-white">
                    <small style={{ fontSize: 10 }}>
                      {t("make_a_deposit")}
                    </small>
                  </b>
                </button> */}
                <button
                  className={`btn bg-blue-400 font-bold h-full leading-6 flex items-center text-white ${
                    size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2 "
                  }`}
                  onClick={() => startDeposit()}
                  style={{ backgroundColor: "rgb(3, 115, 117)", fontSize: 10 }}
                >
                  <i className="mr-3 text-white fa fa-plus-square" />
                  {t("make_a_deposit")}
                </button>

                <button
                  className={`btn bg-blue-400 font-bold h-full leading-6 flex items-center text-white ${
                    size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2 "
                  }`}
                  onClick={() => startTransfer("instant")}
                  style={{ backgroundColor: "rgb(3, 115, 117)", fontSize: 10 }}
                >
                  <i className="mr-3 text-white fa fa-paper-plane-o" />
                  {t("make_a_transfer")}
                </button>

                {user?.is_company_owner === true &&
                  !notAllowBulkTransferCountry.includes(user?.country) && (
                    <button
                      className={`btn bg-blue-400 font-bold h-full leading-6 flex items-center text-white ${
                        size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2 "
                      }`}
                      onClick={() => startBulkPayments()}
                      style={{
                        backgroundColor: "rgb(3, 115, 117)",
                        fontSize: 10,
                      }}
                    >
                      <i className="mr-3 text-white fa fa-flash" />
                      {t("bulkPayment")}
                    </button>
                  )}

                {/* {!user?.is_individual && (
                  <button
                    className={`btn  bg-blue-400 ${
                      size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2  "
                    }`}
                    onClick={() => openPayByLink(true)}
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  >
                    <i className="mr-3 text-white fa fa-history" />
                    <b className="font-bold text-white">
                      <small style={{ fontSize: 10 }}>{t("pay_link")}</small>
                    </b>
                  </button>
                )} */}

                <button
                  className={`btn bg-blue-400 font-bold h-full leading-6 flex items-center text-white ${
                    size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2 "
                  }`}
                  onClick={() => setWithDrawOpen()}
                  style={{ backgroundColor: "rgb(3, 115, 117)", fontSize: 10 }}
                >
                  <i className="mr-3 text-white fa fa-minus-circle" />
                  {t("Withdraws")}
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            {!user?.is_individual && (
              <CompanyBanners
                user={user}
                redirectToWhatsapp={redirectToWhatsapp}
                setOpen={setOpen}
                t={t}
              />
            )}
            {user?.is_individual && (
              <IndividualBanners
                user={user}
                setOpen={setOpen}
                t={t}
                redirectToWhatsapp={redirectToWhatsapp}
              />
            )}
          </>
        )}

        {/* payment link error */}
        {localStorage.getItem("paymentError") !== null &&
          localStorage.getItem("paymentError") !== undefined &&
          window.location.href.includes("home") && (
            <div
              className="flex flex-row my-2 items-center justify-between px-3 py-3 rounded shadow-sm"
              style={{ backgroundColor: "#e09a01" }}
            >
              <p className="text-sm text-white text-wrap">
                <b>{localStorage.getItem("paymentError")}</b>
              </p>

              <button
                onClick={() => {
                  settoggleUiUpdate((p: any) => !p);
                  localStorage.removeItem("paymentError");
                }}
                className="px-3 text-white verifyBtn btn btn-sm"
                style={{ backgroundColor: "rgb(174, 120, 0)" }}
              >
                <small>{t("dismiss")}</small>
              </button>
            </div>
          )}

        <Dialog
          fullScreen
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}
        >
          <div className="flex flex-row items-center justify-between px-4 py-2 ">
            <span className="text-white">{t("start_Transfer")}</span>
            <button
              className="flex items-center justify-center px-4 rounded-lg shadow-lg btn"
              onClick={() => setOpen(false)}
              style={{ height: 50, width: 50, borderRadius: 25 }}
            >
              <i className="m-0 text-gray-600 fa fa-close"></i>
            </button>
          </div>
          <>
            <div className="row">
              <div className="mx-auto text-center col-md-6">
                <SumSub
                  isModal={true}
                  residencyLevelName={residencyLevelName}
                  isKYB={!user?.is_individual}
                  phone={user?.full_phone_number}
                  finish={() => {
                    // check if user is valid and then load a payment payment
                    try {
                      currentBalance()
                        .then((response: any) => {
                          dispatch(editUser(response));
                          if (response.is_active && response.is_verified) {
                            const external_link = localStorage.getItem(
                              "ext_mm_process_link"
                            );
                            if (external_link) {
                              fetch(external_link)
                                .then((res: any) => res.json())
                                .then((response: any) => {
                                  if (response.redirect_uri) {
                                    window.location?.replace(
                                      response.redirect_uri
                                    );
                                  } else {
                                    setOpen((prev) => !prev);
                                  }
                                })
                                .catch((error: any) => {
                                  Sentry.captureException(error);
                                  toast.error(t(error?.data?.message));
                                });
                            } else {
                              setOpen((prev) => !prev);
                            }
                          } else {
                            setOpen((prev) => !prev);
                          }
                        })
                        .catch((error: any) => {
                          Sentry.captureException(error);
                          toast.error(t(error?.data?.message));
                        });
                    } catch (error: any) {
                      Sentry.captureException(error);
                    }
                  }}
                />
              </div>
            </div>
          </>
        </Dialog>
      </div>
    </>
  );
};

export default NotActiveComp;
