import { CircularProgress } from "@material-ui/core";

const PaymentMethodComponent = ({ data, prev }: any) => {
  const {
    t,
    isUserRestricted,
    removeBalance,
    checkingForRestriction,
    paymentMethod,
    processingPayment,
    feesCalculated,
    errorGot,
    makePayIn,
    handleCashMethod,
  } = data;

  return (
    <>
      <div className="mt-2 mb-3 form-group">
        <p className="withdrawTitle">{t("Payment_Mode")}</p>
        <div
          className={`${
            checkingForRestriction
              ? "pointer-events-none"
              : "pointer-events-auto"
          } bacnkCheckBoxWrapper p-2 flex flex-col gap-2`}
        >
          {checkingForRestriction && (
            <div className="flex justify-center items-center z-50 w-full h-48">
              <CircularProgress />
            </div>
          )}
          {!checkingForRestriction &&
            removeBalance
              ?.filter((cashin: any) => {
                if (isUserRestricted) {
                  return (
                    cashin?.cash_in_method?.payment_type?.name ==
                    "bank_transfer"
                  );
                } else {
                  return true;
                }
              })
              .map((value: any, index: any) => {
                return (
                  <div key={"deposit" + index}>
                    <div className="wrapperBankCheckBox m-0 innerBankBox">
                      <div className="wrapperBankCheckBoxwrapper">
                        <input
                          type="checkbox"
                          data-deposit-method="method"
                          className="default:ring-2"
                          onChange={(e: any) =>
                            handleCashMethod(
                              e.target.checked,
                              value.cash_in_method?.payment_type?.name.toLowerCase()
                            )
                          }
                          name={value?.cash_in_method?.name}
                          checked={
                            value.cash_in_method?.payment_type?.name.toLowerCase() ===
                            paymentMethod
                          }
                        />
                        <span className="checkmark"></span>
                      </div>
                      <label>
                        {t(
                          value?.cash_in_method?.name
                            ?.toLowerCase()
                            .split(" ")
                            .join("_")
                        )}
                      </label>
                    </div>

                    {value?.cash_in_method?.name.toLowerCase() ==
                      "bank transfer" &&
                      value.cash_in_method?.payment_type?.name.toLowerCase() ===
                        paymentMethod && (
                        <div className="innerBoxDesc my-2 innerBankBox">
                          <p>
                            <b>{t("bank_transfer_instruction_title")} : </b>{" "}
                            {t("bank_transfer_instruction_description")}
                          </p>
                        </div>
                      )}

                    {value?.cash_in_method?.name.toLowerCase() == "bank card" &&
                      value.cash_in_method?.payment_type?.name.toLowerCase() ===
                        paymentMethod && (
                        <div className="innerBoxDesc my-2 innerBankBox">
                          <p>
                            <b>{t("bank_card_instruction_title")} : </b>{" "}
                            {t("bank_card_instruction_description")}
                          </p>
                        </div>
                      )}
                  </div>
                );
              })}
        </div>
      </div>
      <button
        className="mt-3 btn mr-2"
        style={{ backgroundColor: "rgb(2,2,2)" }}
        onClick={() => {
          prev();
        }}
      >
        <small className="px-4 font-bold text-white">{t("back")}</small>
      </button>
      <button
        className="mt-3 btn"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
        onClick={() => makePayIn()}
        disabled={
          processingPayment ||
          feesCalculated ||
          errorGot !== "" ||
          checkingForRestriction ||
          paymentMethod == ""
        }
      >
        <small className="px-10 font-bold text-white">
          {processingPayment ? t("processing") : t("continue")}
        </small>
      </button>
    </>
  );
};

export default PaymentMethodComponent;
