import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import BankDetails from "../../components/BankDetails";
import * as Sentry from "@sentry/browser";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  depositOnAccount,
  getAppliedFeesForDeposits,
} from "../../store/features/Transfer/Transfer";
import {
  addTransferResult,
  openDeposit,
  setDepositData,
} from "../../store/features/Transfer/TransferSlice";
import { extractError } from "../../utility";
import {
  findCashInMethodByProviderType,
  getCurrentDate,
  getGoogleDoc,
  getGoogleSheetData,
  getGoogleSheetRowData,
} from "../../utilities/help";
import { customerIoTrack } from "../../utilities/customeriohelpers";
import DepositInitiationModal from "../../components/Deposit/DepositInitiationModal";
import DepositBankSelector from "../../components/Deposit/DepositBankSelector";
import useGoogleSheets from "use-google-sheets";

const RATE = 655.957;

const Deposit: React.FC<any> = () => {
  const { t } = useTranslation();
  const dispatch: any = useAppDispatch();
  const {
    transfer,
    auth: {
      user: {
        full_name,
        company,
        id,
        email,
        payment_reference_number,
        full_phone_number,
        country,
        first_name,
        last_name,
      },
      userBalance,
      dana_pay_bank_accounts,
    },
  } = useAppSelector((state) => state.persistedReducer);
  const [paymentMethod, setPaymentMethod] = React.useState<any>(
    transfer?.deposit ? transfer?.deposit?.paymentMethod : ""
  );

  const [amountInCfa, setAmountInCfa] = React.useState<number>(
    transfer.deposit ? transfer?.deposit?.amountInCFA?.toFixed(2) : 0
  );
  const [amountInEURos, setAmountInEURos] = React.useState<any>(
    transfer?.deposit ? transfer?.deposit?.amountInEURos : 0
  );
  const [processingPayment, setProcessingPayment] = React.useState(false);
  const [limitError, setLimitError] = React.useState<any>(null);
  const [fees, setFees] = React.useState<any>(0);
  const [feesCalculated, setFeesCalculated] = React.useState<boolean>(false);
  const [nextStep, setNextStep] = React.useState("first");
  const [errorGot, setErrorGot] = React.useState("");
  const [selectedBank, setSelectedBank] = React.useState<any>(null);
  const [payInResponse, setPayInResponse] = React.useState<any>(null);
  const [fetchingFees, setFetchingFees] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [size, setSize] = React.useState<any>([0, 0]);

  // removed balance cash in methods
  const removeBalance: any =
    transfer?.loggedInUserCountry?.cash_in_methods.filter(
      (val: any) => val?.cash_in_method?.payment_type?.name !== "balance"
    );
  const steps = [
    t("Amount"),
    t("complete_payment"),
    t("select_bank"),
    t("bank_details"),
  ];

  // handle customer io events
  const depositFailDataRef = useRef<any>();
  const [depositFailData, setdepositFailData] = useState({
    channel: "web",
    email: email,
    "phone number": full_phone_number,
    "first name": first_name,
    "last name": last_name,
    "no of attemps":
      window.localStorage.getItem("depositattempts") == null
        ? 1
        : Number(window.localStorage.getItem("depositattempts")),
    "deposit start date": getCurrentDate(),
    "last page viewed": "Amount Confirmation Page",
    abandoned: false,
  });

  useEffect(() => {
    depositFailDataRef.current = depositFailData;
  }, [depositFailData]);

  useEffect(() => {
    if (
      amountInEURos !== "" &&
      amountInEURos !== 0 &&
      amountInEURos !== null &&
      amountInEURos !== undefined
    ) {
      setdepositFailData((prev: any) => {
        return { ...prev, amount: amountInEURos };
      });
    } else {
      setdepositFailData((prev: any) => {
        const { amount, ...rest } = prev;
        return { ...rest };
      });
    }
  }, [amountInEURos]);

  const depositFailIn10Mins = useRef<any>();

  useEffect(() => {
    depositFailIn10Mins.current = setTimeout(() => {
      customerIoTrack({
        name: "Deposit Unfinished In 10 Mins",
        data: depositFailDataRef.current,
      });
    }, 600000);
    return () => {
      clearDepositFailNotification();
    };
  }, []);

  const clearDepositFailNotification = () => {
    clearTimeout(depositFailIn10Mins.current);
  };

  // close deposit customerio event
  const onCloseDepositWindow = () => {
    const depositattempts = window.localStorage.getItem("depositattempts");
    if (depositattempts !== null && depositattempts !== undefined) {
      const attemps = Number(depositattempts) + 1;
      window.localStorage.setItem("depositattempts", attemps.toString());
    }
    clearDepositFailNotification();
  };

  useEffect(() => {
    const depositattempts = window.localStorage.getItem("depositattempts");
    if (depositattempts == null) {
      window.localStorage.setItem("depositattempts", "1");
    }
    return () => {
      clearDepositFailNotification();
    };
  }, []);

  // window or tab close events
  const unloadDepositFailNotification = () => {
    customerIoTrack({
      name: "Deposit Unfinished In 10 Mins",
      data: { ...depositFailData, abandoned: true },
    });
    dispatch(openDeposit(false));
    localStorage.removeItem("depositattempts");
    localStorage.removeItem("maketransferattempts");
    clearDepositFailNotification();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", unloadDepositFailNotification);

    return () => {
      window.removeEventListener("beforeunload", unloadDepositFailNotification);
    };
  }, []);

  // check if user is restriced
  const [isUserRestricted, setisUserRestricted] = useState(false);
  const [checkingForRestriction, setcheckingForRestriction] = useState(true);

  const {
    data: googleSheetData,
    loading: googleSheetsLoading,
    error,
  } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_SHEET_API_ID!,
    sheetId: process.env.REACT_APP_GOOGLE_SHEET_ID!,
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  // handle google sheet data
  // check for user restriction

  useEffect(() => {
    try {
      if (googleSheetsLoading) {
        return;
      } else {
        setcheckingForRestriction(true);
        let restrictedEmails: any = getGoogleSheetData(googleSheetData);
        if (
          restrictedEmails !== undefined &&
          restrictedEmails.includes(email)
        ) {
          setisUserRestricted(true);
          if (!(transfer?.deposit?.paymentMethod == "bank_transfer")) {
            setPaymentMethod("");
          }
        } else {
          setisUserRestricted(false);
        }
        setcheckingForRestriction(false);

        // const doc = getGoogleDoc();

        // if (doc !== undefined) {
        //   getGoogleSheetRowData(doc)
        //     .then((restrictedEmails: any) => {
        //       if (
        //         restrictedEmails !== undefined &&
        //         restrictedEmails.includes(email)
        //       ) {
        //         setisUserRestricted(true);
        //         if (!(transfer?.deposit?.paymentMethod == "bank_transfer")) {
        //           setPaymentMethod("");
        //         }
        //       } else {
        //         setisUserRestricted(false);
        //       }
        //     })
        //     .catch((error: any) => {
        //       setisUserRestricted(false);
        //       toast.warning(t(error?.data?.message));
        //       Sentry.captureException(error);
        //     })

        //     .finally(() => setcheckingForRestriction(false));
      }
    } catch (error: any) {
      setcheckingForRestriction(false);
      setisUserRestricted(false);
      toast.warning(t(error?.data?.message));
      Sentry.captureException(error);
    }
  }, [googleSheetsLoading]);

  useEffect(() => {
    try {
      if (transfer?.deposit?.paymentMethod !== "") {
        getSelectedCashInMethod();
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  const [selectedBankState, setselectedBankState] = useState([]);

  // get list of banks
  useEffect(() => {
    try {
      if (
        dana_pay_bank_accounts !== undefined &&
        dana_pay_bank_accounts.length > 0
      ) {
        setselectedBankState(
          dana_pay_bank_accounts.map((i: any) => ({ ...i, checked: false }))
        );
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [dana_pay_bank_accounts]);

  const makePayIn = () => {
    try {
      setProcessingPayment(true);
      if (paymentMethod === "") {
        toast.error(t("Please_select_cashin_Method"));
        return;
      }

      if (paymentMethod == "balance") {
        toast.error(t("cant_use_balance"));
        return;
      }

      if (amountInEURos <= 0) {
        toast.error(t("cant_deposit_zero"));
        return;
      }

      const cashinMethodId = findCashInMethodByProviderType(
        transfer,
        paymentMethod
      );

      if (cashinMethodId == undefined) {
        toast.error(t("Please_select_cashin_Method"));
        return;
      }
      const transactionData = {
        cashin_method_id: cashinMethodId.cash_in_method.id,
        amount_without_fees_in_euro: amountInEURos,
        country_id: transfer.loggedInUserCountry.id,
      };

      depositOnAccount(transactionData)
        .then((res: any) => {
          clearDepositFailNotification();
          window.heap.track("Deposit payment details confirmed", {
            email,
            full_phone_number,
            full_name,
            country,
          });
          window.heap.track("Deposit payment successfully initiated", {
            email,
            full_phone_number,
            full_name,
            country,
          });
          customerIoTrack({
            name: "Deposit Submitted",
            data: {},
          });
          dispatch(addTransferResult(res));
          setPayInResponse(res);
          if (paymentMethod === "bank_transfer") {
            if (selectedBank) {
              setNextStep("bank");
              setActiveStep(3);
            } else {
              setNextStep("selectBank");
              setActiveStep(2);
            }
          } else {
            window.location?.replace(res.connect_url);
            setActiveStep(1);
          }
        })
        .catch((error: any) => {
          setProcessingPayment(false);
          toast.error(extractError(error));

          Sentry.captureException(error);
        })
        .finally(() => setProcessingPayment(false));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handleCashMethod = (checked: any, value: any) => {
    setPaymentMethod(checked === false ? "" : value);
  };

  const renderSteps = () => {
    return (
      <>
        {nextStep === "first" && (
          <DepositInitiationModal
            t={t}
            full_name={full_name}
            email={email}
            company={company}
            userBalance={userBalance}
            checkingForRestriction={checkingForRestriction}
            removeBalance={removeBalance}
            isUserRestricted={isUserRestricted}
            transfer={transfer}
            RATE={RATE}
            setLimitError={setLimitError}
            paymentMethod={paymentMethod}
            errorGot={errorGot}
            limitError={limitError}
            setAmountInCfa={setAmountInCfa}
            setAmountInEURos={setAmountInEURos}
            handleCashMethod={handleCashMethod}
            amountInEURos={amountInEURos}
            amountInCfa={amountInCfa}
            feesCalculated={feesCalculated}
            fees={fees}
            fetchingFees={fetchingFees}
            payment_reference_number={payment_reference_number}
            makePayIn={makePayIn}
            processingPayment={processingPayment}
          />
        )}

        {nextStep === "web" && (
          <div className="container shadow-lg">
            <iframe
              src={transfer.transferResult.connect_url}
              width="100%"
              height="100%"
              title="description"
            ></iframe>
          </div>
        )}

        {nextStep === "selectBank" && (
          <DepositBankSelector
            selectedBankState={selectedBankState}
            setselectedBankState={setselectedBankState}
            setSelectedBank={setSelectedBank}
            dana_pay_bank_accounts={dana_pay_bank_accounts}
            t={t}
            setNextStep={setNextStep}
            setActiveStep={setActiveStep}
            full_name={full_name}
            full_phone_number={full_phone_number}
            email={email}
            country={country}
            selectedBank={selectedBank}
          />
        )}

        {nextStep === "bank" && (
          <div className="container">
            <div className="row flex justify-center">
              <div className="p-4 shadow-lg max-sm:mt-20 depositbankdetails col-md-8">
                <BankDetails
                  accountDetails={selectedBank}
                  payment_id={payInResponse.details.id}
                  amount={parseFloat(amountInEURos)}
                  fees={parseFloat(fees)}
                  isDeposit={true}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // set fees from amount and cash in id
  const getSelectedCashInMethod = () => {
    try {
      setFees(0);
      setFeesCalculated(true);
      setFetchingFees(true);

      const cashinMethodId = findCashInMethodByProviderType(
        transfer,
        paymentMethod
      );
      if (!amountInEURos) return;
      if (!cashinMethodId) return;
      setErrorGot("");
      getAppliedFeesForDeposits(
        {
          euro_amount: amountInEURos,
          sending_country_id: transfer.loggedInUserCountry.id,
          cashin_method_id: cashinMethodId.cash_in_method.id,
        },
        id
      )
        .then((response: any) => {
          setFees(response.fee);
          setFeesCalculated(false);
          setFetchingFees(false);
        })
        .catch((error: any) => {
          setFeesCalculated(false);
          const err = extractError(error);
          setErrorGot(err);
          setFetchingFees(false);

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    try {
      // set fees
      getSelectedCashInMethod();

      // set paymentMethod for customerio event
      if (
        paymentMethod !== undefined &&
        paymentMethod !== null &&
        paymentMethod !== ""
      ) {
        setdepositFailData((prev: any) => {
          return {
            ...prev,
            paymentMethod: paymentMethod,
          };
        });
      } else {
        setdepositFailData((prev: any) => {
          const { paymentMethod, ...rest } = prev;
          return { ...rest };
        });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [paymentMethod, amountInEURos]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "rgb(3, 115, 117)" }}>
        <div
          className="container"
          style={{ backgroundColor: "rgb(3, 115, 117)" }}
        >
          <div
            className="flex flex-row items-center justify-between py-2"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
          >
            <span className="font-bold text-white">{t("MakeDeposit")}</span>
            <div id="deposit-cancel">
              <button
                id="deposit-cancel"
                data-deposit-cancel="deposit-cancel"
                onClick={() => {
                  onCloseDepositWindow();
                  window.heap.track("Deposit canceled", {
                    full_name,
                    company,
                    email,
                    full_phone_number,
                    country,
                  });
                  dispatch(openDeposit(false));
                  dispatch(
                    setDepositData({
                      amountInEURos: 0,
                      amountInCFA: 0,
                      paymentMethod: "",
                    })
                  );
                }}
                style={{ height: 40, width: 40, borderRadius: 20 }}
                className="flex items-center justify-center shadow-lg"
              >
                <i
                  id="deposit-cancel"
                  className="m-0 text-white fa fa-close"
                  data-deposit-cancel="deposit-cancel"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {size[0] > 500 && (
        <div className="container">
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      )}

      <div className="container px-2 lg:pt-10 sm:p-2 md:p-2">
        {renderSteps()}
      </div>
    </>
  );
};

export default Deposit;
