import React from "react";
import BeneficiaryCard from "../BeneficiaryCard/BeneficiaryCard";
import {
  addBeneficiary,
  addTransferCountry,
  setBeneFound,
} from "../../store/features/Transfer/TransferSlice";
import { useDispatch } from "react-redux";

const BeneficiaryInfo = (props: any) => {
  const { t, transfer, onNext, setPhone, setCountry } = props;
  const dispatch = useDispatch();

  const removeBeneficiary = () => {
    dispatch(addBeneficiary(null));
    dispatch(addTransferCountry(null));
    dispatch(setBeneFound(false));
    setPhone("");
    setCountry({ dialCode: "" });
  };

  return (
    <div className="row flex justify-center BeneficiaryInfo">
      <div className="bg-white col-lg-6 col-md-8 col-10 rounded-lg lg:p-8 md:p-3 sm:p-3 ">
        <div className="flex flex-col items-center justify-center px-4">
          <h3 className="my-3 text-2xl font-bold text-center">
            {t("beneficiary")}
          </h3>
          <p className="text-center">
            {t("text_bene_details")}:{" "}
            <b>{transfer.beneficiary.full_phone_number}</b>
          </p>
        </div>

        <BeneficiaryCard beneficiary={transfer.beneficiary} />
        <div className="row">
          <div className="mb-2 col-md-6">
            <button
              className="w-full rounded-lg btn btn-dark"
              onClick={removeBeneficiary}
            >
              {t("cancel")}
            </button>
          </div>
          <div className="col-md-6">
            <button
              className="w-full rounded-lg btn"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              onClick={onNext}
              data-transfer-payment-details="paymentdetails"
            >
              <span
                className="text-white capitalize"
                data-transfer-payment-details="paymentdetails"
              >
                {t("continue")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryInfo;
