import { Modal } from "@material-ui/core";
import OtpInput from "react-otp-input";
import {
  edit_active_user_profile,
  verify_phone_otp,
} from "../../store/features/Auth/Auth";
import { extractErrorMessage } from "../../helpers";
import { editUser } from "../../store/features/Auth/AuthSlice";
import { getTransferCountries } from "../../store/features/Transfer/Transfer";
import { addLoggedInUserCountry } from "../../store/features/Transfer/TransferSlice";
import * as Sentry from "@sentry/browser";

const PhoneNumberModal = (props: any) => {
  const {
    t,
    open,
    onClose,
    otp_code,
    setOtpCode,
    toast,
    country,
    new_phone,
    dispatch,
    setEmailOtpCode,
    user,
  } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex justify-center">
        <div className="col-md-4">
          <div className="flex flex-col items-center justify-center p-4 bg-white rounded">
            <div className="mt-20" />
            <img
              src="/phone_verification.png"
              style={{ height: 100, width: 100, marginBottom: 30 }}
            />
            <p className="text-2xl text-center">
              <b>{t("phone_verify_header")}</b>
            </p>
            <p className="text-center">
              <small>{t("phone_verify_text")}</small>
            </p>
            <br />

            <OtpInput
              value={otp_code}
              onChange={(code: any) => setOtpCode(code)}
              numInputs={6}
              separator={<span> &nbsp; &nbsp; </span>}
              inputStyle={{
                height: 50,
                width: 50,
                borderWidth: 1,
                borderColor: "#aaa",
                color: "#282828",
                borderRadius: 10,
              }}
            />

            <br />
            <div className="row">
              <div className="my-2 col-md-6">
                <button
                  className="flex-1 px-12 bg-gray-300 rounded-lg btn btn-block"
                  onClick={onClose}
                >
                  <small>{t("cancel")}</small>
                </button>
              </div>
              <div className="my-2 col-md-6">
                <button
                  className="flex-1 px-12 rounded-lg btn btn-block"
                  style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  onClick={() => {
                    try {
                      verify_phone_otp({
                        verification_code: parseInt(otp_code),
                        country_code: parseInt(country),
                        phone_number: new_phone,
                      })
                        .then((res: any) => {
                          edit_active_user_profile(
                            {
                              country_code: parseInt(country),
                              phone_number: new_phone,
                              reason_for_modification: "Updating phone number",
                            },
                            user.id
                          )
                            .then((response: any) => {
                              setEmailOtpCode("");
                              dispatch(
                                editUser({
                                  country_code: parseInt(country),
                                  phone_number: new_phone,
                                  full_phone_number: `${country}${new_phone}`,
                                })
                              );
                              // change logged in user country in redux
                              getTransferCountries()
                                .then((resp: any) => {
                                  dispatch(
                                    addLoggedInUserCountry(
                                      resp?.data.find(
                                        (cc: any) =>
                                          cc.country_code ===
                                          response?.user?.country_code
                                      )
                                    )
                                  );
                                })
                                .catch((error: any) => {
                                  toast.warning(t(error.data.message));
                                  Sentry.captureException(error);
                                });

                              toast.success(t("phone_update_success_message"));
                              onClose();
                            })
                            .catch((error: any) => {
                              const err = extractErrorMessage(error).join(", ");
                              toast.error(err);

                              Sentry.captureException(error);
                            });
                        })
                        .then((res: any) => {
                          edit_active_user_profile(
                            {
                              country_code: parseInt(country),
                              phone_number: new_phone,
                              reason_for_modification: "Updating phone number",
                            },
                            user.id
                          )
                            .then((response) => {
                              dispatch(
                                editUser({
                                  country_code: parseInt(country),
                                  phone_number: new_phone,
                                  full_phone_number: `${country}${new_phone}`,
                                })
                              );
                              toast.success(t("phone_update_success_message"));
                              onClose();
                            })
                            .catch((error: any) => {
                              const err = extractErrorMessage(error).join(", ");
                              toast.error(err);

                              Sentry.captureException(error);
                            });
                        })
                        .catch((error: any) => {
                          const err = extractErrorMessage(error).join(", ");
                          toast.error(err);

                          Sentry.captureException(error);
                        });
                    } catch (error: any) {
                      Sentry.captureException(error);
                    }
                  }}
                >
                  <small className="font-bold text-white btn-block">
                    {t("phone_verify_btn_text")}
                  </small>
                </button>
              </div>
            </div>

            <div className="mt-20" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhoneNumberModal;
