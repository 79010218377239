import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import "react-phone-input-2/lib/style.css";
import * as Sentry from "@sentry/browser";
import { CircularProgress } from "@material-ui/core";
import {
  completePaymentOnDemandTransfer,
  getAppliedFeesForTransfers,
  getPaymentDetail,
  // completePayLineTransfer,
  // getMMAuthenticate,
  // getMMProvider,
  getDanapayBanks,
  currentBalance,
} from "../../store/features/Transfer/Transfer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import InputField from "../../components/forms/InputField";
import BeneficiarySummary from "../../components/BeneficiarySumary";
import CashLayout from "../../components/CashLayout";
import {
  buildSelectInputData,
  getCashInMethod,
  getReceivingCountryID,
} from "../../utilities/help";
import SelectField from "../../components/forms/SelectField";
import BankDetails from "../../components/BankDetails";
import { useHistory, useParams, useLocation } from "react-router";
import { toast } from "material-react-toastify";
import Modal from "@material-ui/core/Modal";
import {
  addLoggedInUserCountry,
  addTransferResult,
  resetTransfer,
} from "../../store/features/Transfer/TransferSlice";
import {
  editUser,
  refreshCaptchaToken,
  resetAuth,
  updateBalance,
  updateDanaPayBankAccounts,
} from "../../store/features/Auth/AuthSlice";
import MobileMoney from "./MobileMoney";
import PayOnDemandSelector from "../../components/PayOnDemandSelector";
import { logout } from "../../store/features/Auth/Auth";
import { resetDeveloper } from "../../store/features/Developer/DeveloperSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const RATE = 655.957;

const PayOnDemand = () => {
  const { t } = useTranslation();
  const { id }: any = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    transfer,
    auth: { user, dana_pay_bank_accounts, userBalance, lang, currency },
  } = useAppSelector((state) => state.persistedReducer);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const [fees, setFees] = useState(0);
  const [selectedBank, setSelectedBank] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [payment_details, setPaymentDetails] = useState<any>(null);
  const [notUser, setNotUser] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(true);
  const [isExternal, setIsExternal] = useState<any>(null);
  const [activeType, setActiveType] = useState<any>("normal");
  const [selectableMethods, setSelectableMethods] = useState<any[]>([]);
  const [payload, setPayload] = useState({
    reason: "",
    amount_in_euros: 0,
    amount_in_cfa: 0,
  });
  const [bankIndex, setBankIndex] = useState<any>(null);
  const [rate, setRate] = useState(655.957);
  const [availableProviders, setAvailableProviders] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [can_edit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const steps = ["beneficiary", "Amount", "payment", "Confirmation"];

  const [selectedPaymentMethod, setselectedPaymentMethod] = useState<any>({});

  const setLoggedUserCountry = (user_info: any) => {
    try {
      if (
        transfer.danaPayCountries.find(
          (cc: any) => cc.country_code === user_info?.country_code
        ) !== undefined
      ) {
        dispatch(
          addLoggedInUserCountry(
            transfer.danaPayCountries.find(
              (cc: any) => cc.country_code === user_info?.country_code
            )
          )
        );
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const calculateFees = () => {
    try {
      const selectedCashInMethodArray: any = getCashInMethod(
        transfer,
        paymentMethod
      );
      const receivingId = getReceivingCountryID(
        transfer,
        payment_details?.transfer?.destination_user?.country_code
      );
      const data = {
        euro_amount: payload.amount_in_euros,
        sending_country_id: transfer?.loggedInUserCountry?.id,
        receiving_country_id: receivingId,
        cashin_method_id: selectedCashInMethodArray?.cash_in_method?.id,
      };

      if (
        data?.euro_amount !== undefined &&
        data?.sending_country_id !== undefined &&
        data?.receiving_country_id !== undefined &&
        data?.cashin_method_id !== undefined
      ) {
        getAppliedFeesForTransfers(data, user?.id)
          .then((res: any) => {
            setFees(res.fee);
          })
          .catch((error: any) => {
            toast.warning(error?.data?.message);
            Sentry.captureException(error);
          });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const get_bank_accounts = () => {
    try {
      getDanapayBanks(user?.country_code)
        .then((res: any) => {
          dispatch(updateDanaPayBankAccounts(res.bank_accounts));
        })
        .catch((error: any) => {
          toast.warning(error?.data?.message);
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const complete = () => {
    try {
      const {
        transfer: { destination_user },
      } = payment_details;

      const receivingId = getReceivingCountryID(
        transfer,
        destination_user?.country_code
      );

      const selectedCashInMethodArray = getCashInMethod(
        transfer,
        paymentMethod
      );

      if (!paymentMethod) {
        toast.error(t("selectCashIn"));
        return;
      }
      // check balance if balance used
      if (
        selectedCashInMethodArray.cash_in_method?.payment_type?.name ===
        "balance"
      ) {
        if (parseInt(user?.client?.euro_balance) < payload.amount_in_euros) {
          toast.error(t("insufficient"));
          return;
        }
      }
      const PaymentMethodType =
        selectedCashInMethodArray?.cash_in_method?.payment_type?.name?.toLowerCase();

      const payloadObj = {
        cashin_method_id: selectedCashInMethodArray?.cash_in_method.id,
        amount_without_fees_in_euro: payload.amount_in_euros,
        payment_delivery: false,
        is_escrowed: false,
        sending_country_id: transfer.loggedInUserCountry.id,
        receiving_country_id: receivingId,
        phone_number: destination_user.phone_number,
        country_code: destination_user?.country_code,
        reason: payload.reason,
      };

      setLoading(true);
      completePaymentOnDemandTransfer(payloadObj, payment_details.id)
        .then((res: any) => {
          setResponse(res);
          setLoading(false);
          dispatch(addTransferResult(res));
          const { payment } = res;
          if (PaymentMethodType === "mobile_money") {
            localStorage.setItem(
              "mobile_response",
              JSON.stringify({
                ...res,
                details: payment,
              })
            );
            setIsModalOpen(true);

            // history.push("/mobile_money");
            return;
          }
          if (PaymentMethodType === "bank_transfer") {
            setActiveStep(3); // if its bank transfer used
            return;
          } else if (PaymentMethodType === "balance") {
            setActiveStep(3); // if its bank transfer used
            return;
          } else if (
            ["card", "direct_debit", "instant_bank_transfer"].includes(
              PaymentMethodType
            )
          ) {
            // dispatch(setStep(1));
            localStorage.setItem("online_payment_id", res.payment.id);
            window.location?.replace(res.connect_url);
            return;
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          setLoading(false);
          toast.error(
            t(
              err?.data?.message
                .toLowerCase()
                .split(" ")
                .join("_")
                .replaceAll(".", "")
            )
          );
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const fetchCurrentUserState = () => {
    try {
      currentBalance()
        .then((res: any) => {
          dispatch(updateBalance(res.client.euro_balance));
          dispatch(editUser(res));
        })
        .catch((error: any) => {
          toast.warning(error?.data?.message);
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const buildSelectableMethods = (setMethod: any, canSelect: boolean) => {
    try {
      const methods = buildSelectInputData(transfer);

      // set payment method coming from api
      try {
        setselectedPaymentMethod(setMethod);
      } catch (error: any) {
        Sentry.captureException(error);
      }

      if (!canSelect && "type" in setMethod && "provider" in setMethod) {
        setPaymentMethod(setMethod.provider);
        setSelectableMethods(
          methods.filter(
            (val: any) =>
              val.type.toLowerCase() === setMethod.type.toLowerCase() &&
              val.provider.toLowerCase() === setMethod.provider.toLowerCase()
          )
        );
      } else {
        setPaymentMethod(setMethod.provider);
        setSelectableMethods(methods);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <div></div>;
      case 1:
        return (
          <>
            <div className="mt-10 row">
              <div className="col-md-1"></div>
              <div className="px-4 shadow-lg col-md-10">
                <div className="mt-4 row">
                  <div className="flex flex-col items-center justify-center border-b col-md-12">
                    <span>{t("Balance")}</span>
                    <span className="text-2xl">
                      <CashLayout cash={userBalance} />
                    </span>
                  </div>
                  <div className="p-4 col-md-6">
                    <InputField
                      name="reason"
                      label={t("reason")}
                      handleChange={(e: any) =>
                        setPayload((prev: any) => {
                          return { ...prev, reason: e.target.value };
                        })
                      }
                      value={payload.reason}
                      type="text"
                      disabled={true}
                    />

                    <div className="mb-3">
                      <PayOnDemandSelector
                        selectedPaymentMethod={selectedPaymentMethod}
                        value={paymentMethod}
                        data={selectableMethods}
                        setData={(value: string) => setPaymentMethod(value)}
                        width={true}
                        label={t("selectCashinMethod")}
                        hasOptionalInput={true}
                        classApply={true}
                      />
                      {/* <SelectField
                        value={paymentMethod}
                        data={selectableMethods}
                        setData={(value: string) => setPaymentMethod(value)}
                        width={true}
                        label={t("selectCashinMethod")}
                        hasOptionalInput={true}
                        classApply={true}
                      /> */}
                    </div>

                    <InputField
                      name="amount_in_euros"
                      label={t("Sent_amount_EUR")}
                      handleChange={(e: any) =>
                        setPayload((prev: any) => {
                          return {
                            ...prev,
                            amount_in_euros: e.target.value,
                            amount_in_cfa: e.target.value * RATE,
                          };
                        })
                      }
                      value={payload.amount_in_euros}
                      type="number"
                      disabled={true}
                    />

                    <InputField
                      name="amount_in_cfa"
                      label={t("Sent_amount_Cfa")}
                      handleChange={(e: any) =>
                        setPayload((prev: any) => {
                          return {
                            ...prev,
                            amount_in_cfa: e.target.value,
                            amount_in_euros: e.target.value / RATE,
                          };
                        })
                      }
                      value={payload.amount_in_cfa}
                      type="number"
                      disabled={true}
                    />

                    <div className="mt-4 row">
                      <div className="my-2 col-md-6">
                        <button
                          className="w-full rounded-lg btn btn-dark btn-block"
                          onClick={() => history.push("/dashboard/home")}
                        >
                          <small className="text-white">{t("back")}</small>
                        </button>
                      </div>
                      <div className="my-2 col-md-6">
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <CircularProgress size={24} />
                          </div>
                        ) : (
                          <button
                            className="w-full rounded-lg btn btn-block"
                            style={{ backgroundColor: "rgb(3, 115, 117)" }}
                            onClick={() => {
                              try {
                                if (
                                  activeType === "mobile_money" &&
                                  paymentMethod === "mobile_money"
                                ) {
                                  if (
                                    payment_details?.data?.actions &&
                                    payment_details?.data?.actions[0] ===
                                      "select_provider"
                                  ) {
                                    const provider_array =
                                      payment_details.data.available_providers;
                                    setAvailableProviders(provider_array);
                                    setIsModalOpen(true);
                                  } else {
                                    complete();
                                  }
                                } else {
                                  complete();
                                }
                              } catch (error: any) {
                                Sentry.captureException(error);
                              }
                            }}
                          >
                            <small className="text-white">
                              {t("continue")}
                            </small>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="border-b-2">
                      <BeneficiarySummary
                        name={
                          payment_details?.transfer.destination_user.full_name
                        }
                        company={
                          payment_details?.transfer?.destination_user?.company
                            ?.name ||
                          payment_details?.transfer?.destination_user?.email
                        }
                      />
                    </div>

                    <div className="flex flex-row items-center justify-between my-3">
                      <small>{t("amount")}</small>
                      <CashLayout cash={payload.amount_in_euros} />
                    </div>

                    <div className="flex flex-row items-center justify-between my-3">
                      <small>{t("fees")}</small>
                      <CashLayout cash={fees} />
                    </div>

                    <div
                      className="flex flex-row items-center justify-between my-3"
                      style={{ color: "rgb(3, 115, 117)" }}
                    >
                      <small>{t("total")}</small>
                      <CashLayout cash={fees + payload.amount_in_euros} />
                    </div>

                    <div className="flex flex-row items-center justify-between my-3">
                      <small>{t("exchange_rate")}</small>
                      <b className="font-bold">1 EUR = {RATE} CFA</b>
                    </div>

                    <div
                      className="flex flex-row items-center justify-between my-3"
                      style={{ color: "rgb(3, 115, 117)" }}
                    >
                      <small>{t("received_amount")}</small>
                      <CashLayout cash={payload.amount_in_cfa} />
                    </div>

                    <div className="flex flex-row justify-between my-3">
                      <small>{t("PaymentMode")}</small>
                      <b className="font-bold">{t(paymentMethod)}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </>
        );
      case 2:
        return (
          <div>
            <div className="mt-10 row">
              <div className="col-md-4"></div>
              <div className="shadow-lg col-md-4">
                <div className="my-4 mb-4 text-center">
                  <h3 className="text-2xl font-bold">
                    {t("payment_instruction")}
                  </h3>
                  <p>{t("pi_text")}</p>

                  <div style={{ height: 40 }} />

                  <select
                    className={`border-b-2 rounded bg-white w-full mb-4`}
                    onChange={(v: any) => setSelectedBank(v.target.value)}
                  >
                    <option>{t("select_bank")}</option>
                    {dana_pay_bank_accounts.map((bank: any, index: number) => (
                      <option value={index}>{bank.bank_name}</option>
                    ))}
                  </select>

                  <button
                    onClick={() => {
                      setActiveStep((prev) => prev + 1);
                    }}
                    disabled={!selectedBank}
                    className="w-full rounded-md btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  >
                    <small className="mx-10 font-bold text-white">
                      {t("continue")}
                    </small>
                  </button>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            {["balance", "danapay"].includes(paymentMethod.toLowerCase()) && (
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="flex flex-col items-center justify-center p-4 m-10 text-center bg-white shadow-lg">
                    <img
                      src="../images/checked.png"
                      style={{ height: 60, width: 60, margin: 20 }}
                    />
                    {lang === "en" ? (
                      <p>
                        We are sending{" "}
                        <b>
                          <CashLayout
                            cash={
                              currency === "EUR"
                                ? parseFloat(
                                    payload.amount_in_euros.toString()
                                  ).toFixed(2)
                                : parseFloat(
                                    payload.amount_in_euros.toString()
                                  ) * rate
                            }
                          />
                        </b>{" "}
                        to{" "}
                        <b>
                          {transfer.beneficiary &&
                            transfer.beneficiary.full_name}
                        </b>
                        <br />. Your beneficiary will receive a message with the
                        necessary details for the withdrawal.
                      </p>
                    ) : (
                      <p>
                        Nous envoyons{" "}
                        <b>
                          <CashLayout
                            cash={
                              currency === "EUR"
                                ? parseFloat(
                                    payload.amount_in_euros.toString()
                                  ).toFixed(2)
                                : parseFloat(
                                    payload.amount_in_euros.toString()
                                  ) * rate
                            }
                          />
                        </b>{" "}
                        à{" "}
                        <b>
                          {transfer.beneficiary &&
                            transfer.beneficiary.full_name}{" "}
                        </b>
                        <br />
                        Votre bénéficiaire recevra un message avec les détails
                        nécessaires pour le retrait.
                      </p>
                    )}
                    <button
                      onClick={() => {
                        const ext_pay_id = localStorage.getItem("payment_id");
                        const ext_link = localStorage.getItem(
                          "ext_mm_process_link"
                        );
                        if (ext_pay_id || ext_link) {
                          localStorage.removeItem("payment_id");
                          localStorage.removeItem("ext_mm_process_link");
                          localStorage.removeItem("payment_details");
                          localStorage.removeItem("user:key");
                          dispatch(resetAuth());
                          window.location?.replace(
                            `${payment_details?.data?.redirect_url}?payment_id=${payment_details.id}`
                          );
                        } else {
                          history.push("/");
                        }
                      }}
                      className="my-4 btn"
                      style={{ backgroundColor: "rgb(3, 115, 117)" }}
                    >
                      <small className="font-bold text-white ">
                        {t("BackHome")}
                      </small>
                    </button>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            )}

            {["bank_transfer", "sepa"].includes(
              paymentMethod.toLowerCase()
            ) && (
              <div className="pt-10 row">
                <div className="col-md-1" />
                <div className="p-4 col-md-10">
                  {selectedBank ? (
                    <div className="p-4 shadow-lg">
                      <BankDetails
                        isDeposit={false}
                        accountDetails={selectedBank}
                        payment_id={response.details.id}
                        amount={`${
                          parseFloat(payload.amount_in_euros.toString()) + fees
                        }`}
                        redirect={() => {
                          const ext_pay_id = localStorage.getItem("payment_id");
                          const ext_link = localStorage.getItem(
                            "ext_mm_process_link"
                          );
                          if (ext_pay_id || ext_link) {
                            localStorage.removeItem("payment_id");
                            localStorage.removeItem("payment_details");
                            localStorage.removeItem("ext_mm_process_link");
                            localStorage.removeItem("user:key");
                            dispatch(resetAuth());
                            window.location?.replace(
                              `${payment_details?.data?.redirect_url}?payment_id=${payment_details.id}`
                            );
                          } else {
                            history.push("/");
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="p-10 shadow-lg col-md-6">
                          <div className="my-4 mb-4 text-center">
                            <h3 className="text-2xl font-bold">
                              {t("payment_instruction")}
                            </h3>
                            <p>{t("pi_text")}</p>
                          </div>
                          <>
                            <select
                              className={`border-b-2 rounded bg-white w-full mb-4`}
                              onChange={(v: any) =>
                                setBankIndex(v.target.value)
                              }
                            >
                              <option>{t("select_bank")}</option>
                              {dana_pay_bank_accounts.map(
                                (bank: any, index: number) => (
                                  <option value={index}>
                                    {bank.bank_name}
                                  </option>
                                )
                              )}
                            </select>
                            <button
                              onClick={() => {
                                if (bankIndex) {
                                  setSelectedBank(
                                    dana_pay_bank_accounts[bankIndex]
                                  );
                                }
                              }}
                              className="w-full rounded-md btn"
                              style={{ backgroundColor: "rgb(3, 115, 117)" }}
                            >
                              <small className="mx-10 font-bold text-white">
                                {t("continue")}
                              </small>
                            </button>
                          </>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-md-1" />
              </div>
            )}
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const fetch_details = () => {
    try {
      setFetching(true);
      const payment_id = id;
      getPaymentDetail(payment_id)
        .then((res: any) => {
          const { provider, type } = res;
          buildSelectableMethods({ provider, type }, res.data.select_payment);
          setFetching(false);
          // check validate of the link
          // if(t('link_expiry_text'))

          if (user.id === res.transfer.source_user_id) {
            setNotUser(false);
            setPaymentDetails(res);
            // save copy in local storage
            localStorage.setItem("payment_details", JSON.stringify(res));
            setResponse(res);

            setPayload((prev) => {
              return {
                ...prev,
                reason: res.transfer.reason,
                amount_in_euros: res.transfer.amount_without_fee,
                amount_in_cfa: parseFloat(
                  res.transfer.local_amount_without_fee
                ),
              };
            });
            // setRate(); set the applied from the spread config object
            //check payment method // mobile_money
            if (res.type === "mobile_money") {
              localStorage.setItem(
                "mobile_response",
                JSON.stringify({
                  ...res,
                  details: res.transfer,
                })
              );
              // setIsModalOpen(true);
            }
          }
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          setFetching(false);
          if (error.status === 403) {
            toast.error(t("not_your_payment"));
            localStorage.removeItem("payment_id");
            localStorage.removeItem("ext_mm_process_link");
            dispatch(resetAuth());
            history.push("/");
          }
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handleOnCloseClicked = () => {
    const payment_id = localStorage.getItem("payment_id");
    if (payment_id) {
      try {
        const { cancel_url } = response.data;
        localStorage.removeItem("ext_mm_process_link");
        localStorage.removeItem("payment_id");
        localStorage.removeItem("mobile_response");

        logout().then((response: any) => {
          localStorage.removeItem("user:key");
          dispatch(resetAuth());
          dispatch(resetTransfer());
          dispatch(resetDeveloper());
          dispatch(refreshCaptchaToken(false));
          // history.push("/login", { state: { isLogout: true } });
          window.location?.replace(`${cancel_url}`);
        });
      } catch (error: any) {
        Sentry.captureException(error);
      }
    } else {
      history.push("/dashboard/home");
    }
  };

  useEffect(() => {
    const ext_mm_process_link = localStorage.getItem("ext_mm_process_link");
    if (user && !ext_mm_process_link) {
      calculateFees();
    }
  }, [paymentMethod, payload?.amount_in_euros]);

  useEffect(() => {
    //check if your is logged in
    fetchCurrentUserState();
    get_bank_accounts();
    const user_token = localStorage.getItem("user:key");
    if (user_token) {
      get_bank_accounts();
      fetch_details();
      setLoggedUserCountry(user);
      localStorage.removeItem("payment_id");
      const ext_mm_process_link = localStorage.getItem("ext_mm_process_link");
      if (ext_mm_process_link) {
        setCanEdit(false);
        setIsExternal(ext_mm_process_link);
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      <div
        className="shadow-md"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <div className="container flex flex-row items-center justify-between py-2 text-white">
          <p className="font-bold">{t("payment_demand")}</p>
          <button
            onClick={handleOnCloseClicked}
            className="px-4 py-2 shadow-lg cursor-pointer"
            style={{ backgroundColor: "rgba(3, 115, 117, .6)" }}
          >
            <small className="font-bold">{t("close")}</small>
          </button>
        </div>
      </div>

      {notUser ? (
        <>
          <div className="py-10 mt-10 row">
            <div className="p-10 text-center shadow-lg col-md-4 mx-auto">
              <p className="text-4xl text-gray-700 ">Oops!</p>
              <p>
                <small>{t("notUser")}</small>
              </p>
              <button
                onClick={() => history.push("/dashboard/home")}
                className="px-10 my-4 bg-red-600 btn"
              >
                <small className="text-white">{t("back")}</small>
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {!fetching ? (
            <div className="container">
              <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{t(label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStep === steps.length ? (
                <div />
              ) : (
                <>{getStepContent(activeStep)}</>
              )}
            </div>
          ) : (
            <>
              <div
                className="flex flex-col items-center justify-center text-center bg-gray-100"
                style={{ height: "100vh" }}
              >
                <div className="w-1/3 p-10 mx-auto shadow-lg">
                  <h2 className="mb-3">{t("Please_wait")}...</h2>
                  <p>{t("fetching_transactions_details")}</p>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <Modal open={isModalOpen}>
        <MobileMoney response={response} />
      </Modal>
    </div>
  );
};

export default PayOnDemand;
