import { Dialog } from "@material-ui/core";
import Accounts from "../Accounts";

const AccountsScreen = (props: any) => {
  const {
    onClose,
    Transition,
    open,
    accountType,
    selected_account,
    dpOpen,
    onDPClose,
    dana_pay_bank_accounts,
  } = props;
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <>
          <div className="flex flex-row items-center justify-between p-3 mb-20">
            <button
              onClick={onClose}
              style={{ height: 50, width: 50, borderRadius: 25 }}
              className="ml-auto flex items-center justify-center shadow-lg"
            >
              <i className="m-0 fa fa-close"></i>
            </button>
          </div>
          <Accounts
            type={accountType}
            selected_account={selected_account}
            isDanapay={false}
          />
        </>
      </Dialog>

      <Dialog
        fullScreen
        open={dpOpen}
        onClose={onDPClose}
        TransitionComponent={Transition}
      >
        <div className="container">
          <div className="flex flex-row items-center justify-between p-3 mb-20">
            <button
              onClick={onDPClose}
              style={{ height: 50, width: 50, borderRadius: 25 }}
              className="ml-auto flex items-center justify-center shadow-lg"
            >
              <i className="m-0 fa fa-close"></i>
            </button>
          </div>
          <Accounts
            type="bank"
            selected_account={null}
            dp_accounts={dana_pay_bank_accounts}
            isDanapay={true}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AccountsScreen;
