import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import * as Sentry from "@sentry/browser";
import Modal from "@material-ui/core/Modal";

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";

import "react-phone-input-2/lib/style.css";
import {
  getAppliedFeesForTransfers,
  getDanapayBanks,
  initTransfer,
  getNewDirectPayment,
  getAllMobileOperatorWithCountryCode,
  getAppliedFeesForWithdraws,
  dynamicGetEndpoint,
} from "../../store/features/Transfer/Transfer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  addBeneficiary,
  addTransferResult,
  directPayment,
  getAllMobileOperatorWithCode,
  openBulkTransferDialog,
  setBeneFound,
  setStep,
  setTLError,
  setTransactionToRepeat,
  setTransferDialogOpen,
  updateBulkTData,
} from "../../store/features/Transfer/TransferSlice";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import BankDetails from "../../components/BankDetails";
import { toast } from "material-react-toastify";
import { useHistory } from "react-router";
import { extractError } from "../../utility";
import {
  getCashInMethod,
  getSendingCountry,
  checkCashInLimit,
  getReceivingCountryID,
  translateError,
  getLoggedInUserReceivingCountries,
  getCashInMethodName,
  getCurrentDate,
  getCashoutMethodIdForFees,
  getGoogleSheetData,
} from "../../utilities/help";
import { updateDanaPayBankAccounts } from "../../store/features/Auth/AuthSlice";
import MobileMoney from "./MobileMoney";
import { customerIoTrack } from "../../utilities/customeriohelpers";
import BalanceCashinModal from "../../components/MakeTransfer/BalanceCashinModal";
import BeneficiarySelector from "../../components/MakeTransfer/BeneficiarySelector";
import BeneficiaryInfo from "../../components/MakeTransfer/BeneficiaryInfo";
import TransferPaymentModal from "../../components/MakeTransfer/TransferPaymentModal";
import TransferPaymentWithdrawModal from "../../components/MakeTransfer/TransferPaymentWithdrawModal";
import AddBeneficiary from "../../components/MakeTransfer/AddBeneficiary";
import SelectBankModal from "../../components/MakeTransfer/SelectBankModal";
import AddBeneficiaryWithPhone from "../../components/MakeTransfer/AddBeneficiaryWithPhone";
import { isEmpty } from "lodash";
import useGoogleSheets from "use-google-sheets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);
const RATE = 655.957;

const MakeTransfer = () => {
  const { t } = useTranslation();
  const dispatch: any = useAppDispatch();
  const {
    transfer,
    auth: { user, currency, userBalance, dana_pay_bank_accounts, favorites },
  } = useAppSelector((state) => state.persistedReducer);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(transfer?.step || 0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(
    transfer?.beneficiary?.phone_number
      ? transfer?.beneficiary?.phone_number
      : ""
  );
  const [fees, setFees] = useState(0);
  const [withdrawalFees, setWithdrawalFees] = useState(0);
  const [cashoutMethod, setCashoutMethod] = useState<string>(
    transfer?.transactionToRepeat?.data?.cashout_method_id ?? ""
  );
  const [feesCalculated, setFeesCalculated] = useState<boolean>(false);
  const [countrySelected, setCounrtySelected] = useState<boolean>(false);
  const [country, setCountry] = useState<any>({ dialCode: "" });
  const [phoneValue, setPhoneValue] = useState("");
  const [countryCode, setCountryCode]: any = useState(
    transfer?.transactionToRepeat?.data?.country_code ??
      transfer?.beneficiary?.country_code ??
      ""
  );

  const [reason, setReason] = useState(
    transfer.transactionToRepeat ? transfer.transactionToRepeat.reason : ""
  );
  const [amountInCFA, setAmountInCFA] = useState<any>(
    transfer.transactionToRepeat
      ? transfer.transactionToRepeat.amountInCFA?.toFixed(2)
      : ""
  );
  const [amountInEuros, setAmountInEuros] = useState<any>(
    transfer.transactionToRepeat
      ? transfer.transactionToRepeat.amountInEuros
      : ""
  );
  const [paymentMethod, setPaymentMethod] = useState(
    transfer.transactionToRepeat
      ? transfer?.transactionToRepeat?.paymentMethod
      : ""
  );

  const [operatorList, setOperatorList] = useState(
    transfer.transactionToRepeat ? transfer.transactionToRepeat.operator : ""
  );
  const [bankName, setBankName] = useState();

  const [userCheck, setUserCheck] = useState<boolean>(false);
  const [steps, setSteps] = useState([
    "beneficiary",
    "Amount",
    "payment",
    "Confirmation",
  ]);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [limitError, setLimitError] = useState<any>(null);
  const [size, setSize] = useState<any>([0, 0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorGot, setErrorGot] = useState<any>("");
  const [response, setResponse] = useState<any>(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [fetchingFees, setFetchingFees] = useState(false);
  const [bankIndex, setBankIndex] = useState<any>(null);
  const [receivingCountries, setReceivingCountries] = useState<any>([]);
  const [checked, setChecked] = useState<boolean>(
    window.localStorage.getItem("withdrawEnabled") == "true" ? true : false
  );

  const [selectedCountryReset, setSelectedCountryReset] = useState<boolean>(
    transfer?.transactionToRepeat?.operator !== "" &&
      transfer?.transactionToRepeat?.operator !== undefined
      ? true
      : false
  );

  // check if user choose to write cfa first
  const [isCFAFirst, setisCFAFirst] = useState(false);

  // check if user is restriced
  const [isUserRestricted, setisUserRestricted] = useState(false);
  const [checkingForRestriction, setcheckingForRestriction] = useState(true);

  const {
    data: googleSheetData,
    loading: googleSheetsLoading,
    error,
  } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_SHEET_API_ID!,
    sheetId: process.env.REACT_APP_GOOGLE_SHEET_ID!,
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  // handle google sheet data
  // check for user restriction
  // const handleUserRestriction = async (handleWithdrawRepeatCallback?: any) => {
  //   if (paymentActiveStep == 1) {
  //     return;
  //   }
  //   try {
  //     setcheckingForRestriction(true);
  //     const doc = getGoogleDoc();

  //     if (doc !== undefined) {
  //       getGoogleSheetRowData(doc)
  //         .then((restrictedEmails: any) => {
  //           if (restrictedEmails && restrictedEmails.includes(user?.email)) {
  //             setisUserRestricted(true);
  //             setPaymentMethod("bank transfer");
  //           } else {
  //             setisUserRestricted(false);
  //           }
  //         })
  //         .catch((error: any) => {
  //           toast.warning(t(error?.data?.message));
  //           Sentry.captureException(error);
  //         })
  //         .finally(() => {
  //           setcheckingForRestriction(false);
  //           handleWithdrawRepeatCallback && handleWithdrawRepeatCallback();
  //         });
  //     }
  //   } catch (error: any) {
  //     setcheckingForRestriction(false);
  //     handleWithdrawRepeatCallback && handleWithdrawRepeatCallback();
  //   }
  // };

  const [contacts_details, setContactDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [company_contacts_details, setCompanyContactDetails] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [hideWithdrawModal, setHideWithdrawModal] = useState(false);

  // create contact
  const [typeOfUser, settypeOfUser] = useState([
    {
      title: "Business",
      value: "business",
      active: true,
    },
    {
      title: "Individual",
      value: "individual",
      active: false,
    },
  ]);
  const [selectedContactType, setselectedContactType] = useState("business");
  const handleTypeOfUserSelection = (value: any) => {
    try {
      setselectedContactType(value);
      settypeOfUser((prev: any) =>
        prev.map((itm: any) =>
          itm.value == value
            ? { ...itm, active: true }
            : { ...itm, active: false }
        )
      );
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const [spread_config, setSpreadConfig] = useState({
    spread: 0,
    net_exchange_rate: 655.957,
    exchange_rate: transfer.transactionToRepeat
      ? transfer.transactionToRepeat.rate
      : 655.957,
    received_amount: transfer.transactionToRepeat
      ? transfer.transactionToRepeat.amountInCFA
      : 0,
  });

  const [selectedBankState, setselectedBankState] = useState([]);

  // payment selection states
  const [paymentActiveStep, setpaymentActiveStep] = useState(0);
  const [widthdrawMethod, setwidthdrawMethod] = useState("");

  // receiving country state
  const [notReceivingCountry, setNotReceivingCountry] = useState(false);

  const handleUserRestriction = async (handleWithdrawRepeatCallback?: any) => {
    if (paymentActiveStep == 1) {
      return;
    }
    try {
      if (googleSheetsLoading) {
        return;
      } else {
        setcheckingForRestriction(true);
        handleWithdrawRepeatCallback && handleWithdrawRepeatCallback();
        let restrictedEmails: any = getGoogleSheetData(googleSheetData);

        if (restrictedEmails && restrictedEmails.includes(user?.email)) {
          setisUserRestricted(true);
          setPaymentMethod("bank transfer");
        } else {
          setisUserRestricted(false);
        }
        setcheckingForRestriction(false);
      }
    } catch (error: any) {
      setcheckingForRestriction(false);
      Sentry.captureException(error);
      handleWithdrawRepeatCallback && handleWithdrawRepeatCallback();
    }
  };

  // mobile
  const [selectedMobileOperator, setselectedMobileOperator] = useState("");
  const [customMobileOperator, setcustomMobileOperator] = useState("");
  const [customPhoneNumber, setcustomPhoneNumber] = useState("");

  // bank
  const [selectedBankName, setselectedBankName] = useState("");
  const [customBankName, setcustomBankName] = useState("");
  const [accountHolderName, setaccountHolderName] = useState("");
  const [iBAN, setIBAN] = useState();
  const [customIban, setcustomIban] = useState("");
  const [bIC, setBIC] = useState();

  // mfi
  const [selectedMfiName, setselectedMfiName] = useState("");
  const [customMfiName, setcustomMfiName] = useState("");
  const [mfiAccountUserName, setmfiAccountUserName] = useState("");
  const [mfiOwnerId, setmfiOwnerId] = useState("");
  const [customMfiOwnerId, setcustomMfiOwnerId] = useState("");

  // institution id
  const [institutionId, setinstitutionId] = useState("");

  // all direct payment dropdown listings
  const [mobileOperatorList, setmobileOperatorList] = useState([]);
  const [bankSelectionList, setbankSelectionList] = useState([]);
  const [mfiSelectionList, setMfiSelectionList] = useState([]);
  const [allProviderList, setallProviderList] = useState({
    mobileOperatorList: [],
    bankSelectionList: [],
    mfiSelectionList: [],
    checkingForWithdrawProviders: true,
  });

  const [emailError, setEmailError] = useState("");

  // set recievers name from contact info
  // get all benefitiary accounts
  useEffect(() => {
    try {
      if (
        transfer?.beneficiary?.full_name !== undefined &&
        transfer?.beneficiary?.full_name !== null
      ) {
        setmfiAccountUserName(transfer?.beneficiary?.full_name);
        if (
          transfer?.beneficiary?.company?.name !== undefined &&
          transfer?.beneficiary?.company !== null
        ) {
          setaccountHolderName(transfer?.beneficiary?.company?.name);
        } else {
          setaccountHolderName(transfer?.beneficiary?.full_name);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer?.beneficiary?.full_name]);

  // set withdraw toggle preference in localstorage
  useEffect(() => {
    if (window.localStorage.getItem("withdrawEnabled") == undefined) {
      window.localStorage.setItem("withdrawEnabled", "true");
    }
  }, []);

  // all direct payment methods
  const [allDirectPaymentMethods, setallDirectPaymentMethods] = useState([]);

  const enableDirectTransfer = () => {
    setChecked(true);
    setHideWithdrawModal(false);
  };

  const repeatTransactionMemoed = useMemo(
    () => transfer?.transactionToRepeat?.reason,
    [transfer?.transactionToRepeat?.reason]
  );

  // handle fees for repeat
  useEffect(() => {
    if (
      transfer?.transactionToRepeat !== undefined &&
      transfer?.transactionToRepeat?.data !== undefined &&
      activeStep < 2 &&
      !allProviderList.checkingForWithdrawProviders
    ) {
      fetchAppliesFees();
    }
  }, [
    paymentMethod,
    amountInEuros,
    widthdrawMethod,
    checked,
    allProviderList.checkingForWithdrawProviders,
  ]);

  // loader for getting withdraw providers
  const [checkingForWithdrawProviders, setcheckingForWithdrawProviders] =
    useState(true);

  const handleWithdrawData = async (endpointList: any) => {
    let mobileOperatorList: any = [];
    let bankList: any = [];
    let mfiList: any = [];

    if (isEmpty(countryCode)) {
      setallProviderList((prev: any) => ({
        ...prev,
        checkingForWithdrawProviders: false,
      }));
      setcheckingForWithdrawProviders(false);
      return;
    }

    if (endpointList.length > 0) {
      setcheckingForWithdrawProviders(true);
      setallProviderList((prev: any) => ({
        ...prev,
        checkingForWithdrawProviders: true,
      }));
      for (const endpoint of endpointList) {
        const result = await dynamicGetEndpoint(endpoint.provider, countryCode);

        if (result?.data && result?.data?.length > 0) {
          if (
            endpoint.type == "manual_bank_transfer" ||
            endpoint.type == "bank_transfer"
          ) {
            result?.data.forEach((data: any) => {
              if (
                data?.institution?.type == "bank_transfer" ||
                data?.institution?.type == "manual_bank_transfer"
              ) {
                bankList.push(data);
              }
            });
          }
          if (endpoint.type == "mfi") {
            result?.data.forEach((data: any) => {
              if (data?.institution?.type == "mfi") {
                mfiList.push(data);
              }
            });
          }
          if (endpoint.type == "mobile_money") {
            result?.data.forEach((data: any) => {
              if (data?.institution?.type == "mobile_money") {
                mobileOperatorList.push(data);
              }
            });
          }
        }
      }
      setcheckingForWithdrawProviders(false);

      setallProviderList({
        mobileOperatorList:
          mobileOperatorList.length > 0
            ? mobileOperatorList.filter((itm: any) => itm?.name !== "Other")
            : [],
        mfiSelectionList: mfiList.length > 0 ? mfiList : [],
        bankSelectionList:
          bankList.length > 0
            ? bankList.filter((itm: any) => itm?.name !== "Other")
            : [],
        checkingForWithdrawProviders: false,
      });

      if (mobileOperatorList.length > 0) {
        setmobileOperatorList(
          mobileOperatorList.filter((itm: any) => itm?.name !== "Other")
        );
      } else {
        setmobileOperatorList([]);
      }
      if (bankList.length > 0) {
        setbankSelectionList(
          bankList.filter((itm: any) => itm?.name !== "Other")
        );
      } else {
        setbankSelectionList([]);
      }
      if (mfiList.length > 0) {
        setMfiSelectionList(mfiList);
      } else {
        setMfiSelectionList([]);
      }
    }
  };

  // new withdraw logic
  useEffect(() => {
    try {
      if (
        transfer?.getAllMobileOperator !== undefined &&
        transfer?.getAllMobileOperator !== null
      ) {
        let endpointList: any = [];

        // create dynamic endpoints
        transfer?.getAllMobileOperator.forEach((cashout: any) => {
          endpointList.push({
            provider: cashout?.cashout_method?.payment_provider?.name,
            type: cashout?.cashout_method?.payment_type?.name,
          });
        });

        if (
          transfer?.getAllMobileOperator !== undefined &&
          transfer?.getAllMobileOperator?.length > 0
        ) {
          setallDirectPaymentMethods(transfer?.getAllMobileOperator);
        } else {
          setallDirectPaymentMethods([]);
        }
        handleWithdrawData(endpointList);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [transfer?.getAllMobileOperator, countryCode]);

  const nonRepeatWithdrawHandling = () => {
    if (
      allProviderList.bankSelectionList.length > 0 ||
      allProviderList.mobileOperatorList.length > 0 ||
      allProviderList.mfiSelectionList.length > 0
    ) {
      if (
        window.localStorage.getItem("withdrawEnabled") == "true" ||
        window.localStorage.getItem("withdrawEnabled") == undefined
      ) {
        setChecked(true);
      }
      setHideWithdrawModal(false);
      if (allProviderList.mobileOperatorList.length > 0) {
        setwidthdrawMethod("Mobile Money");
      } else if (allProviderList.bankSelectionList.length > 0) {
        setwidthdrawMethod("Bank Account");
      } else {
        setwidthdrawMethod("Microfinance");
      }
    } else {
      setwidthdrawMethod("");
      setChecked(false);
      setHideWithdrawModal(true);
    }
  };

  // handle withdraw method toggle correctly
  useEffect(() => {
    if (transfer?.isTransferDialogOpen == true) {
      handleUserRestriction();
    }
    if (allProviderList.checkingForWithdrawProviders) {
      return;
    }

    // non repeat handling
    if (
      transfer?.transactionToRepeat == undefined ||
      transfer?.transactionToRepeat == null
    ) {
      nonRepeatWithdrawHandling();
    } else {
      // repeat withdraw handling
      // to repeat > listing changes > if listing present then set states > or set for non repeat
      try {
        if (
          transfer?.transactionToRepeat !== undefined &&
          transfer?.transactionToRepeat !== null &&
          transfer?.transactionToRepeat?.data !== undefined &&
          transfer?.transactionToRepeat?.data !== null
        ) {
          // handle withdraw
          const handleTransferRepeat = () => {
            if (
              allProviderList.bankSelectionList.length > 0 ||
              allProviderList.mobileOperatorList.length > 0 ||
              allProviderList.mfiSelectionList.length > 0
            ) {
              const dataKeys = Object.keys(transfer?.transactionToRepeat?.data);

              try {
                if (
                  dataKeys.includes("mfi_name") &&
                  allProviderList.mfiSelectionList.length > 0
                ) {
                  setwidthdrawMethod("Microfinance");
                  enableDirectTransfer();
                  setinstitutionId(
                    transfer?.transactionToRepeat?.data?.institution_id
                  );

                  setmfiOwnerId(
                    transfer?.transactionToRepeat?.data?.mfi_owner_id
                  );
                  setmfiAccountUserName(
                    transfer?.transactionToRepeat?.data?.mfi_account_name
                  );
                } else if (
                  dataKeys.includes("iban") &&
                  allProviderList.bankSelectionList.length > 0
                ) {
                  setwidthdrawMethod("Bank Account");
                  enableDirectTransfer();

                  setIBAN(transfer?.transactionToRepeat?.data?.iban);
                  setaccountHolderName(
                    transfer?.transactionToRepeat?.data?.account_holder
                  );
                  setinstitutionId(
                    transfer?.transactionToRepeat?.data?.institution_id
                  );
                } else if (
                  dataKeys.includes("operator") &&
                  allProviderList.mobileOperatorList.length > 0
                ) {
                  setwidthdrawMethod("Mobile Money");
                  enableDirectTransfer();
                  setinstitutionId(
                    transfer?.transactionToRepeat?.data?.institution_id
                  );
                } else if (
                  dataKeys.includes("delivery_account_id") &&
                  allProviderList.mobileOperatorList.length > 0
                ) {
                  setwidthdrawMethod("delivery");
                  enableDirectTransfer();
                } else {
                  nonRepeatWithdrawHandling();
                }
              } catch (error: any) {
                nonRepeatWithdrawHandling();
              }
            } else {
              nonRepeatWithdrawHandling();
            }
          };
          if (!allProviderList.checkingForWithdrawProviders) {
            handleTransferRepeat();
          }
          // handleUserRestriction(handleTransferRepeat);
        }
        // } else if (transfer?.isTransferDialogOpen == true) {
        //   handleUserRestriction();
        // }
      } catch (error: any) {
        Sentry.captureException(error);
      }
    }
  }, [
    allProviderList.bankSelectionList,
    allProviderList.mfiSelectionList,
    allProviderList.mobileOperatorList,
    transfer?.transactionToRepeat,
    repeatTransactionMemoed,
    allProviderList.checkingForWithdrawProviders,
    googleSheetsLoading,
  ]);

  // reset all custom fields
  const handleResetAllCustomFields = () => {
    setcustomBankName("");
    setcustomMfiName("");
    setcustomMobileOperator("");
    setselectedBankName("");
    setselectedMfiName("");
    setselectedMobileOperator("");
  };

  useEffect(() => {
    // reset custom fields on payment method change only if its not repeat
    if (
      transfer?.transactionToRepeat == undefined ||
      transfer?.transactionToRepeat == null
    ) {
      handleResetAllCustomFields();
    }

    // set withdraw for heap

    if (
      hideWithdrawModal == false &&
      widthdrawMethod !== undefined &&
      widthdrawMethod !== null &&
      widthdrawMethod !== ""
    ) {
      settransferFailData((prev: any) => {
        return { ...prev, "withdraw method": widthdrawMethod };
      });
    } else {
      settransferFailData((prev: any) => {
        let newState = prev;
        delete newState["withdraw method"];
        return { ...newState };
      });
    }
  }, [widthdrawMethod]);

  useEffect(() => {
    try {
      if (
        dana_pay_bank_accounts !== undefined &&
        dana_pay_bank_accounts.length > 0
      ) {
        setselectedBankState(
          dana_pay_bank_accounts.map((i: any) => ({ ...i, checked: false }))
        );
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [dana_pay_bank_accounts]);

  const getAllMobileOperator = (code: any) => {
    try {
      const filterOperator: any = transfer.danaPayCountries.filter(
        (e: any) => e.country_code?.toString() === code.substring(1).toString()
      );
      getAllMobileOperatorWithCountryCode(filterOperator[0]?.code)
        .then((res: any) => {
          dispatch(getAllMobileOperatorWithCode(res));
        })
        .catch((error: any) => {
          toast.error(extractError(error));

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // transfer on repeat
  useEffect(() => {
    try {
      if (
        transfer?.transactionToRepeat?.paymentMethod == "" ||
        transfer?.transactionToRepeat?.paymentMethod == undefined
      ) {
        const paymentMethods = transfer?.loggedInUserCountry?.cash_in_methods;

        const isBalanceCashinPresent =
          paymentMethods &&
          paymentMethods.length > 0 &&
          paymentMethods.find(
            (paymentMethod: any) =>
              paymentMethod.cash_in_method.name.toString().toLowerCase() ==
              "balance"
          );

        if (
          !isNaN(user?.client?.euro_balance) &&
          Number(user?.client?.euro_balance) > 0 &&
          isBalanceCashinPresent
        ) {
          setPaymentMethod("balance");
        } else {
          if (
            paymentMethods !== undefined &&
            paymentMethods.length > 0 &&
            paymentMethods[0]?.cash_in_method?.name?.toLowerCase() !== undefined
          ) {
            const firstMethod =
              paymentMethods[0]?.cash_in_method?.name?.toLowerCase();
            setPaymentMethod(firstMethod);
          }
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  const history = useHistory();
  const [mm_modal, setMMModal] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    setPhone(transfer?.beneficiary?.phone_number);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    setErrorGot("");
  };

  const submitTransaction = () => {
    // temporary prod fix
    const disableDirectPayment: any =
      (!transfer?.beneficiary?.is_individual && user?.is_individual) ||
      (transfer?.beneficiary?.is_company_owner && user?.is_individual);
    // error handling
    try {
      if (parseFloat(amountInCFA) <= 0) {
        toast.error(t("enterCfa"));
        return;
      }
      if (parseFloat(amountInEuros) <= 0) {
        toast.error(t("enterEuros"));
        return;
      }
      if (!paymentMethod) {
        toast.error(t("selectCashIn"));
        return;
      }
      if (!reason) {
        toast.error(t("reasonError"));
        return;
      }

      // direct payment is checked and method is not balance and is not company
      if (checked === true && !disableDirectPayment) {
        // store all erorrs here
        let directTransferErrors = [];

        // new validations
        // widthdraw method required
        if (widthdrawMethod == "" || widthdrawMethod == undefined) {
          directTransferErrors.push("withdrawalError");
        }

        // valid phone number
        const countryCode = transfer?.beneficiary?.country_code
          ? transfer?.beneficiary?.country_code
          : country.dialCode.substring(1);
        const phoneNumber = phone == "Other" ? customPhoneNumber : phone;
        const receipentNumber =
          "+" + countryCode?.toString() + " " + phoneNumber?.toString();
        const otherReceipentNumber =
          "+" + countryCode?.toString() + " " + customPhoneNumber?.toString();

        if (
          widthdrawMethod == "Mobile Money" &&
          !(
            (isValidPhoneNumber(receipentNumber) &&
              isPossiblePhoneNumber(receipentNumber) &&
              validatePhoneNumberLength(receipentNumber) == undefined) ||
            (isValidPhoneNumber(otherReceipentNumber) &&
              isPossiblePhoneNumber(otherReceipentNumber) &&
              validatePhoneNumberLength(otherReceipentNumber) == undefined)
          )
        ) {
          directTransferErrors.push("validPhone");
        }

        // phone number required
        if (
          widthdrawMethod == "Mobile Money" &&
          (phone == "" || phone == undefined || phone == null) &&
          customPhoneNumber == ""
        ) {
          directTransferErrors.push("PhoneNumberError");
        }
        // phone operator required
        if (
          widthdrawMethod == "Mobile Money" &&
          ((selectedMobileOperator == "" && customMobileOperator == "") ||
            (selectedMobileOperator == "Other" && customMobileOperator == ""))
        ) {
          directTransferErrors.push("Select_Operator_Error");
        }

        // bank name required
        if (
          widthdrawMethod == "Bank Account" &&
          (selectedBankName == "" ||
            (selectedBankName == "Other" && customBankName == ""))
        ) {
          directTransferErrors.push("BankNameError");
        }
        // receiver full name required
        if (widthdrawMethod == "Bank Account" && accountHolderName == "") {
          directTransferErrors.push("ReceiverFullNameError");
        }
        // iban required
        if (
          widthdrawMethod == "Bank Account" &&
          (iBAN === undefined || (iBAN == "Other" && customIban == ""))
        ) {
          directTransferErrors.push("iban_required");
        }

        // mfi method required
        if (
          widthdrawMethod == "Microfinance" &&
          (selectedMfiName == "" ||
            (selectedMfiName == "Other" && customMfiName == ""))
        ) {
          directTransferErrors.push("Select_Mfi_Error");
        }
        // mfi account number required
        if (
          widthdrawMethod == "Microfinance" &&
          (mfiOwnerId == "" ||
            (mfiOwnerId == "Other" && customMfiOwnerId == ""))
        ) {
          directTransferErrors.push("MFIAccountNumberError");
        }
        // iban number validation
        if (
          widthdrawMethod == "Bank Account" &&
          iBAN == "Other" &&
          !/[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/gi.test(
            customIban
          )
        ) {
          directTransferErrors.push("Invalid IBAN");
        }

        // mfi account name
        if (widthdrawMethod == "Microfinance" && mfiAccountUserName == "") {
          directTransferErrors.push("ReceiverFullNameError");
        }

        // show all toast error messages
        if (directTransferErrors.length > 0) {
          directTransferErrors.forEach((itm: any) => toast.error(t(itm)));
          return;
        }
      }

      const selectedCashInMethodArrayName = getCashInMethodName(
        transfer,
        paymentMethod.replace("_", " ")
      );

      const selectedCashInMethodArray = getCashInMethod(
        transfer,
        paymentMethod
      );
      const amount_in_euros = +amountInEuros + 0;
      if (
        selectedCashInMethodArray?.cash_in_method?.payment_type?.name?.toLowerCase() ===
          "balance" &&
        amount_in_euros > userBalance
      ) {
        toast.error(t("insufficient"));
        return;
      }

      if (amount_in_euros == 0) {
        toast.error(t("expectAmount") + userBalance);
        return;
      }

      setProcessingPayment(true);

      const receivingId = getReceivingCountryID(
        transfer,
        transfer?.beneficiary?.country_code
      );

      // check configuration during making a payment
      if (!receivingId) {
        setErrorGot(t("no_receiving_country"));
        setNotReceivingCountry(true);
      } else {
        setNotReceivingCountry(false);
      }

      const sending_country_id = getSendingCountry(transfer);
      if (!sending_country_id) {
        toast.error(t("insufficient"));
        return;
      }

      // payloads
      const transactionData: any = {
        cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
        amount_without_fees_in_euro: amount_in_euros,
        payment_delivery: false,
        is_escrowed: false,
        sending_country_id: sending_country_id,
        receiving_country_id: receivingId,
        phone_number: transfer?.beneficiary?.phone_number,
        country_code: transfer?.beneficiary?.country_code,
        reason,
        is_payment_on_demand: false,
      };

      // params according to the payment method for direct transfer
      let params: any = {};
      if (widthdrawMethod == "Mobile Money") {
        params = {
          cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
          payment_delivery: false,
          is_escrowed: false,
          reason: reason,
          is_payment_on_demand: false,
          amount_without_fees_in_euro: amount_in_euros,
          verify: false,
          phone_number: phone == "Other" ? customPhoneNumber : phone,
          country_code: transfer?.beneficiary?.country_code
            ? transfer?.beneficiary?.country_code
            : country.dialCode.substring(1),
          operator:
            selectedMobileOperator == "Other"
              ? customMobileOperator
              : selectedMobileOperator,
          beneficiary_id: transfer?.beneficiary?.id,
          institution_id: institutionId,
        };
      } else if (widthdrawMethod == "Bank Account") {
        params = {
          cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
          payment_delivery: false,
          is_escrowed: false,
          reason: reason,
          is_payment_on_demand: false,
          amount_without_fees_in_euro: amount_in_euros,
          verify: false,
          country_code: transfer?.beneficiary?.country_code
            ? transfer?.beneficiary?.country_code
            : country.dialCode.substring(1),
          beneficiary_id: transfer?.beneficiary?.id,
          iban: iBAN == "Other" ? customIban : iBAN,
          bank_name:
            selectedBankName == "Other" ? customBankName : selectedBankName,
          account_holder: accountHolderName,
          institution_id:
            allProviderList.bankSelectionList.length > 0 ? institutionId : null,
        };
      } else if (widthdrawMethod == "Microfinance") {
        params = {
          cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
          payment_delivery: false,
          is_escrowed: false,
          reason: reason,
          is_payment_on_demand: false,
          amount_without_fees_in_euro: amount_in_euros,
          verify: false,
          country_code: transfer?.beneficiary?.country_code
            ? transfer?.beneficiary?.country_code
            : country.dialCode.substring(1),
          beneficiary_id: transfer?.beneficiary?.id,
          mfi_name:
            selectedMfiName == "Other" ? customMfiName : selectedMfiName,
          mfi_owner_id: mfiOwnerId == "Other" ? customMfiOwnerId : mfiOwnerId,
          mfi_account_name: mfiAccountUserName,
          institution_id: institutionId,
        };
      } else if (widthdrawMethod == "delivery") {
        params = {
          reason: reason,
          cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
          amount_without_fees_in_euro: amount_in_euros,
          cashout_method_id: cashoutMethod,
          receiver_fullname: transfer?.beneficiary?.full_name,
          receiver_country: transfer?.beneficiary?.country,
          receiver_phonenumber: transfer?.beneficiary?.phone_number,
          local_amount: isCFAFirst ? amount_in_euros * RATE : amountInEuros,
          local_currency: isCFAFirst ? "CFA" : "EUR",
          country_id: transfer?.beneficiary?.country_id,
          country_code: transfer?.beneficiary?.country_code
            ? transfer?.beneficiary?.country_code
            : country.dialCode.substring(1),
          is_escrowed: false,
          payment_delivery: false,
          // phone_number: phone,
        };
      } else {
        params = {
          cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
          payment_delivery: false,
          is_escrowed: false,
          reason: reason,
          is_payment_on_demand: false,
          amount_without_fees_in_euro: amount_in_euros,
          verify: false,
          phone_number: phone,
          country_code: transfer?.beneficiary?.country_code
            ? transfer?.beneficiary?.country_code
            : country.dialCode.substring(1),
          operator: selectedMobileOperator,
          beneficiary_id: transfer?.beneficiary?.id,
        };
      }

      const PaymentMethodType =
        selectedCashInMethodArrayName?.cash_in_method?.payment_type?.name?.toLowerCase();

      // append extra params in case of CFA is selected first
      if (isCFAFirst) {
        params.currency = "CFA";
        params.amount = Math.round(amountInCFA);
        transactionData.currency = "CFA";
        transactionData.amount = Math.round(amountInCFA);
      }

      const heapUserObject = {
        phone: user.full_phone_number,
        email: user.email,
        country: user.country,
        name: user.full_name,
      };

      // temporary prod fix
      if (checked === true && !disableDirectPayment) {
        getNewDirectPayment(params)
          .then((res: any) => {
            dispatch(getAllMobileOperatorWithCode(null));
            clearTransferFailNotification();
            window.heap.track(
              "Transfer payment details confirmed",
              heapUserObject
            );

            dispatch(directPayment(res));
            handleRedirect(
              res,
              PaymentMethodType,
              amount_in_euros,
              heapUserObject
            );
            customerIoTrack({
              name: "Transfer Submitted",
              data: {},
            });
          })
          .catch((error: any) => {
            setProcessingPayment(false);
            toast.error(extractError(error));

            Sentry.captureException(error);
          });
      } else {
        initTransfer(transactionData)
          .then((res: any) => {
            clearTransferFailNotification();
            dispatch(getAllMobileOperatorWithCode(null));
            window.heap.track(
              "Transfer payment details confirmed",
              heapUserObject
            );

            handleRedirect(
              res,
              PaymentMethodType,
              amount_in_euros,
              heapUserObject
            );
            customerIoTrack({
              name: "Transfer Submitted",
              data: {},
            });
          })
          .catch((error: any) => {
            setProcessingPayment(false);
            toast.error(extractError(error));

            Sentry.captureException(error);
          });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handleRedirect = (
    res: any,
    PaymentMethodType: any,
    amount_in_euros: any,
    heapUserObject: any
  ) => {
    try {
      dispatch(addTransferResult(res));
      dispatch(
        setTransactionToRepeat({
          local_amount: amount_in_euros * RATE,
          amount_in_euros: amount_in_euros,
          reason,
        })
      );
      dispatch(setTLError(null));
      setResponse(res);
      window.heap.track(
        "Transfer payment successfully initiated",
        heapUserObject
      );

      if (
        PaymentMethodType === "bank_transfer" ||
        PaymentMethodType === "balance"
      ) {
        setActiveStep(2); // if its bank transfer used
      } else if (
        ["card", "direct_debit", "instant_bank_transfer"].includes(
          PaymentMethodType
        )
      ) {
        localStorage.setItem("online_payment_id", res.payment.id);
        window.location?.replace(res.connect_url);
      } else if (PaymentMethodType === "mobile_money") {
        localStorage.setItem("mobile_response", JSON.stringify(res));
        setMMModal(true);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const setBeneficiary = (user: any) => {
    setCountry({ dialCode: user?.country_code });
    setPhone(user.phone_number);
  };

  const handleCashMethod = (checked: any, value: any) => {
    setPaymentMethod(checked === false ? "" : value);
  };
  const numberInputInvalidChars = ["-", "+", "e"];

  // handle customer io events
  const transferFailDataRef = useRef<any>();
  const [transferFailData, settransferFailData] = useState({
    channel: "web",
    email: user?.email,
    "phone number": user?.full_phone_number,
    "first name": user?.first_name,
    "last name": user?.last_name,
    "no of attemps":
      window.localStorage.getItem("maketransferattempts") == null
        ? 1
        : Number(window.localStorage.getItem("maketransferattempts")),
    "transfer start date": getCurrentDate(),
    "last page viewed": "Beneficiary confirmation page",
    abandoned: false,
  });
  useEffect(() => {
    transferFailDataRef.current = transferFailData;
  }, [transferFailData]);

  // track amount for heap
  useEffect(() => {
    if (
      amountInEuros !== "" &&
      amountInEuros !== 0 &&
      amountInEuros !== null &&
      amountInEuros !== undefined
    ) {
      settransferFailData((prev: any) => {
        return { ...prev, amount: amountInEuros };
      });
    } else {
      settransferFailData((prev: any) => {
        const { amount, ...rest } = prev;
        return { ...rest };
      });
    }
  }, [amountInEuros]);

  // track recipient for heap
  useEffect(() => {
    if (
      transfer?.beneficiary !== undefined &&
      transfer?.beneficiary !== null &&
      transfer?.beneficiary !== ""
    ) {
      settransferFailData((prev: any) => {
        return {
          ...prev,
          "recipient first name": transfer?.beneficiary?.first_name,
          "recipient last name": transfer?.beneficiary?.last_name,
          "recipient email": transfer?.beneficiary?.last_name,
          "recipient phone number": transfer?.beneficiary?.full_phone_number,
        };
      });
    } else {
      settransferFailData((prev: any) => {
        let newState = prev;
        delete newState["recipient first name"];
        delete newState["recipient phone number"];
        delete newState["recipient last name"];
        delete newState["recipient email"];
        return { ...newState };
      });
    }
  }, [transfer?.beneficiary]);

  const transferFailIn10Mins = useRef<any>();

  useEffect(() => {
    transferFailIn10Mins.current = setTimeout(() => {
      customerIoTrack({
        name: "Transfer Unfinished In 10 Mins",
        data: transferFailDataRef.current,
      });
    }, 600000);

    return () => {
      clearTransferFailNotification();
    };
  }, []);

  const clearTransferFailNotification = () => {
    clearTimeout(transferFailIn10Mins.current);
  };

  // close deposit customerio event
  const onCloseTransferWindow = () => {
    const maketransferattempts = window.localStorage.getItem(
      "maketransferattempts"
    );
    if (maketransferattempts !== null && maketransferattempts !== undefined) {
      const attemps = Number(maketransferattempts) + 1;
      window.localStorage.setItem("maketransferattempts", attemps.toString());
    }
    clearTransferFailNotification();
  };

  useEffect(() => {
    const makeTransferAttempts = window.localStorage.getItem(
      "maketransferattempts"
    );
    if (makeTransferAttempts == null) {
      window.localStorage.setItem("maketransferattempts", "1");
    }
    return () => {
      clearTransferFailNotification();
    };
  }, []);

  // window or tab close events
  const unloadTransferFailNotification = () => {
    customerIoTrack({
      name: "Transfer Unfinished In 10 Mins",
      data: { ...transferFailData, abandoned: true },
    });
    dispatch(setTransferDialogOpen(false));
    localStorage.removeItem("depositattempts");
    localStorage.removeItem("maketransferattempts");
    clearTransferFailNotification();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", unloadTransferFailNotification);

    return () => {
      window.removeEventListener(
        "beforeunload",
        unloadTransferFailNotification
      );
    };
  }, []);

  const [hasUserReachedPaymentPage, sethasUserReachedPaymentPage] =
    useState(false);

  const handleChangeHasUserReachedPaymentPage = (payload: any) => {
    sethasUserReachedPaymentPage(payload);
  };

  // new stepper forms
  const paymentScreens = (pageIndex: number) => {
    switch (pageIndex) {
      case 0:
        return (
          <TransferPaymentModal
            hasUserReachedPaymentPage={hasUserReachedPaymentPage}
            handleChangeHasUserReachedPaymentPage={
              handleChangeHasUserReachedPaymentPage
            }
            setErrorGot={setErrorGot}
            notReceivingCountry={notReceivingCountry}
            googleSheetsLoading={googleSheetsLoading}
            checkingForWithdrawProviders={
              allProviderList.checkingForWithdrawProviders
            }
            t={t}
            size={size}
            user={user}
            steps={steps}
            transfer={transfer}
            userBalance={userBalance}
            toast={toast}
            reason={reason}
            handleBack={handleBack}
            setReason={setReason}
            setisCFAFirst={setisCFAFirst}
            setLimitError={setLimitError}
            paymentMethod={paymentMethod}
            checkCashInLimit={checkCashInLimit}
            setAmountInEuros={setAmountInEuros}
            setAmountInCFA={setAmountInCFA}
            amountInEuros={amountInEuros}
            hideWithdrawModal={hideWithdrawModal}
            spread_config={spread_config}
            errorGot={errorGot}
            processingPayment={processingPayment}
            limitError={limitError}
            amountInCFA={amountInCFA}
            checkingForRestriction={checkingForRestriction}
            isUserRestricted={isUserRestricted}
            handleCashMethod={handleCashMethod}
            fetchingFees={fetchingFees}
            withdrawalFees={withdrawalFees}
            fees={fees}
            widthdrawMethod={widthdrawMethod}
            submitTransaction={submitTransaction}
            feesCalculated={feesCalculated}
            setpaymentActiveStep={setpaymentActiveStep}
          />
        );
        break;
      case 1:
        return (
          <TransferPaymentWithdrawModal
            allDirectPaymentMethods={allDirectPaymentMethods}
            checkingForWithdrawProviders={
              allProviderList.checkingForWithdrawProviders
            }
            transfer={transfer}
            userBalance={userBalance}
            t={t}
            setChecked={setChecked}
            paymentMethod={paymentMethod}
            checked={checked}
            amountInEuros={amountInEuros}
            spread_config={spread_config}
            errorGot={errorGot}
            fetchingFees={fetchingFees}
            withdrawalFees={withdrawalFees}
            fees={fees}
            widthdrawMethod={widthdrawMethod}
            hideWithdrawModal={hideWithdrawModal}
            processingPayment={processingPayment}
            submitTransaction={submitTransaction}
            feesCalculated={feesCalculated}
            setcustomMobileOperator={setcustomMobileOperator}
            setinstitutionId={setinstitutionId}
            setwidthdrawMethod={setwidthdrawMethod}
            mobileOperatorList={allProviderList.mobileOperatorList}
            selectedMobileOperator={selectedMobileOperator}
            setpaymentActiveStep={setpaymentActiveStep}
            setselectedMobileOperator={setselectedMobileOperator}
            setSelectedCountryReset={setSelectedCountryReset}
            phone={phone}
            setPhone={setPhone}
            customMobileOperator={customMobileOperator}
            setcustomPhoneNumber={setcustomPhoneNumber}
            setcustomBankName={setcustomBankName}
            bankSelectionList={allProviderList.bankSelectionList}
            customBankName={customBankName}
            selectedBankName={selectedBankName}
            accountHolderName={accountHolderName}
            setIBAN={setIBAN}
            setcustomIban={setcustomIban}
            numberInputInvalidChars={numberInputInvalidChars}
            customPhoneNumber={customPhoneNumber}
            setselectedBankName={setselectedBankName}
            iBAN={iBAN}
            customIban={customIban}
            mfiSelectionList={allProviderList.mfiSelectionList}
            setcustomMfiName={setcustomMfiName}
            setcustomMfiOwnerId={setcustomMfiOwnerId}
            setselectedMfiName={setselectedMfiName}
            customMfiName={customMfiName}
            mfiAccountUserName={mfiAccountUserName}
            setmfiOwnerId={setmfiOwnerId}
            mfiOwnerId={mfiOwnerId}
            setmfiAccountUserName={setmfiAccountUserName}
            customMfiOwnerId={customMfiOwnerId}
            selectedMfiName={selectedMfiName}
            steps={steps}
            size={size}
          />
        );

      default:
        break;
    }
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="mt-4 row">
            <div className="p-2 col-md-12">
              {transfer.beneficiary === null && !transfer.beneFound && (
                <BeneficiarySelector
                  t={t}
                  phone={phone}
                  isLast={activeStep === steps.length - 1}
                  activeStep={activeStep}
                  steps={steps}
                  transfer={transfer}
                  user={user}
                  phoneValue={phoneValue}
                  setUserCheck={setUserCheck}
                  setBeneficiary={setBeneficiary}
                  favorites={favorites}
                  setSelectedCountry={(e: any) => setSelectedCountryReset(e)}
                  country={country}
                  userCheck={userCheck}
                  countryPickerOnChange={(text: string) => {
                    setPhoneValue(`${country.dialCode}${text}`);
                    setPhone(text);
                  }}
                  setCountry={(text: any) => {
                    setCountryCode(text?.country_code);
                    setCountry({
                      dialCode: "" + parseInt(text.mobileCode),
                    });
                    setPhone(transfer?.beneficiary?.phone_number);
                    getAllMobileOperator(text.mobileCode);
                  }}
                />
              )}

              {transfer.beneficiary === null && transfer.beneFound && (
                <AddBeneficiaryWithPhone
                  t={t}
                  emailError={emailError}
                  country={country}
                  phone={phone}
                  firstName={firstName}
                  lastName={lastName}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  transfer={transfer}
                  setEmail={setEmail}
                  email={email}
                  loading={loading}
                  setPhone={setPhone}
                  setCountry={setCountry}
                  typeOfUser={typeOfUser}
                  contacts_details={contacts_details}
                  company_contacts_details={company_contacts_details}
                  setLoading={setLoading}
                  setEmailError={setEmailError}
                />
              )}

              {transfer.beneficiary && transfer.beneFound && (
                <BeneficiaryInfo
                  t={t}
                  setPhone={setPhone}
                  setCountry={setCountry}
                  transfer={transfer}
                  onNext={() => {
                    handleNext();
                    window.heap.track("Transfer receipent details confirmed", {
                      phone: user.full_phone_number,
                      email: user.email,
                      country: user.country,
                      name: user.full_name,
                    });
                  }}
                />
              )}

              {/* add new contact */}
              {transfer.beneficiary && !transfer.beneFound && (
                <AddBeneficiary
                  t={t}
                  typeOfUser={typeOfUser}
                  setPhone={setPhone}
                  setCountry={setCountry}
                  handleTypeOfUserSelection={handleTypeOfUserSelection}
                  selectedContactType={selectedContactType}
                  setContactDetails={setContactDetails}
                  contacts_details={contacts_details}
                  setCompanyContactDetails={setCompanyContactDetails}
                  company_contacts_details={company_contacts_details}
                  loading={loading}
                  transfer={transfer}
                  country={country}
                  phone={phone}
                  setLoading={setLoading}
                  setEmailError={setEmailError}
                />
              )}
            </div>
          </div>
        );

      // payment methods ui
      case 1:
        return paymentScreens(paymentActiveStep);
      case 2:
        return (
          <div className="h-full 5447 pt-10">
            {/* balance transfer modal */}
            {["balance", "danapay"].includes(paymentMethod?.toLowerCase()) && (
              <BalanceCashinModal
                t={t}
                email={transfer?.beneficiary?.email}
                countryCode={transfer?.beneficiary?.country_code}
                phoneNumber={transfer?.beneficiary?.phone_number}
                onClick={() => {
                  dispatch(setBeneFound(false));
                  dispatch(addBeneficiary(null));
                  dispatch(setStep(0));
                  dispatch(setTLError(null));
                  dispatch(setTransactionToRepeat(null));
                  dispatch(setTransferDialogOpen(false));
                  dispatch(openBulkTransferDialog("close"));
                  dispatch(updateBulkTData({ users: {} }));
                  history.push("/");
                }}
              />
            )}
            {["sepa", "bank transfer", "bank_transfer"].includes(
              paymentMethod.toLowerCase()
            ) && (
              <SelectBankModal
                t={t}
                selectedBankState={selectedBankState}
                setBankIndex={setBankIndex}
                setselectedBankState={setselectedBankState}
                bankIndex={bankIndex}
                setSelectedBank={setSelectedBank}
                setActiveStep={setActiveStep}
                dana_pay_bank_accounts={dana_pay_bank_accounts}
              />
            )}
          </div>
        );
      case 3:
        return (
          <div className="pt-10 flex justify-center row">
            <div className="p-4 col-md-10">
              <div className="p-4 max-sm:px-2 shadow-lg">
                <BankDetails
                  isDeposit={false}
                  bankAccountIban={iBAN == "Other" ? customIban : iBAN}
                  mobileMoneyPhone={
                    phone == "Other"
                      ? customPhoneNumber
                      : `+${transfer?.beneficiary?.country_code} ${phone}`
                  }
                  mfiAccountNumber={
                    mfiOwnerId == "Other" ? customMfiOwnerId : mfiOwnerId
                  }
                  isDirectTransfer={checked}
                  widthdrawMethod={widthdrawMethod}
                  accountDetails={selectedBank}
                  payment_id={response.details.id}
                  amount={`${parseFloat(amountInEuros) + fees} `}
                />
              </div>
            </div>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  // get fees on change of any fields
  const fetchAppliesFees = () => {
    try {
      const receivingId = getReceivingCountryID(
        transfer,
        transfer.beneficiary?.country_code
      );
      if (!receivingId) {
        setErrorGot(t("no_receiving_country"));
        setFeesCalculated(false);
        setNotReceivingCountry(true);
        return;
      } else {
        setNotReceivingCountry(false);
      }

      if (!transfer?.isTransferDialogOpen) {
        return;
      }
      if (!paymentMethod) {
        return;
      }
      if (!country) {
        return;
      }
      if (amountInEuros == "") {
        return;
      }
      if (allProviderList.checkingForWithdrawProviders) {
        return;
      }
      setFeesCalculated(true);
      setFetchingFees(true);

      const { loggedInUserCountry } = transfer;
      const { cash_in_methods } = loggedInUserCountry;
      const selectedCashInMethod = cash_in_methods.find(
        (value: any) =>
          value.cash_in_method.name.toLowerCase() ===
          paymentMethod.toLowerCase()
      );
      if (selectedCashInMethod == undefined || !selectedCashInMethod) {
        return;
      }

      getAppliedFeesForTransfers(
        {
          euro_amount: amountInEuros,
          sending_country_id: transfer.loggedInUserCountry.id,
          receiving_country_id: receivingId,
          cashin_method_id: selectedCashInMethod.cash_in_method.id,
        },
        user?.id
      )
        .then((response: any) => {
          setErrorGot("");
          setFees(0);
          setFees(response.fee);

          const s_config = response.fee_calculation.find((value: any) =>
            value.hasOwnProperty("spread")
          );

          if (s_config) {
            setSpreadConfig(s_config);
          }

          if (checked === true) {
            setCashoutMethod(
              getCashoutMethodIdForFees(
                widthdrawMethod,
                allDirectPaymentMethods
              )
            );
            getAppliedFeesForWithdraws(
              {
                euro_amount:
                  currency === "EUR" ? amountInEuros : amountInEuros / RATE,
                country_id: transfer?.beneficiary?.country_id,
                cashout_method_id:
                  getCashoutMethodIdForFees(
                    widthdrawMethod,
                    allDirectPaymentMethods
                  ) || cashoutMethod,
              },
              user?.id
            )
              .then((res: any) => {
                setWithdrawalFees(res.fee);
              })
              .catch((error: any) => {
                // toast.warning(t(error?.data?.message));
                Sentry.captureException(error);
              })
              .finally(() => {
                setFeesCalculated(false);
                setFetchingFees(false);
              });
          } else {
            setFeesCalculated(false);
            setFetchingFees(false);
          }
        })
        .catch((error: any) => {
          setFeesCalculated(false);
          setFetchingFees(false);
          const err = extractError(error);
          setErrorGot(translateError(err, t));
          Sentry.captureException(error);
        });
    } catch (error: any) {
      setFetchingFees(false);
      setFeesCalculated(false);
      Sentry.captureException(error);
    }
  };

  const get_bank_accounts = () => {
    getDanapayBanks(user?.country_code)
      .then((res: any) => {
        dispatch(updateDanaPayBankAccounts(res.bank_accounts));
      })
      .catch((error: any) => {
        toast.warning(t(error?.data?.message));
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    // set widthdraw to 0 in case of no methods
    if (checked === false) {
      setWithdrawalFees(0);
    }
  }, [paymentMethod, amountInEuros, cashoutMethod, checked]);

  useEffect(() => {
    // fetch fees when coming back to amount screen
    if (activeStep == 1 && transfer?.transactionToRepeat == null) {
      fetchAppliesFees();
    }
    // track events for heap
    if (
      activeStep == 1 &&
      hideWithdrawModal == false &&
      widthdrawMethod !== undefined &&
      widthdrawMethod !== null &&
      widthdrawMethod !== ""
    ) {
      settransferFailData((prev: any) => {
        return { ...prev, "withdraw method": widthdrawMethod };
      });
    } else {
      settransferFailData((prev: any) => {
        let newState = prev;
        delete newState["withdraw method"];
        return { ...newState };
      });
    }
    if (
      activeStep == 1 &&
      paymentMethod !== undefined &&
      paymentMethod !== null &&
      paymentMethod !== ""
    ) {
      settransferFailData((prev: any) => {
        return { ...prev, "payment method": paymentMethod };
      });
    } else {
      settransferFailData((prev: any) => {
        let newState = prev;
        delete newState["payment method"];
        return { ...newState };
      });
    }
    if (
      activeStep == 1 &&
      paymentMethod !== undefined &&
      paymentMethod !== null &&
      paymentMethod !== ""
    ) {
      settransferFailData((prev: any) => {
        return { ...prev, "last page viewed": "Amount confirmation page" };
      });
    } else {
      settransferFailData((prev: any) => {
        let newState = prev;
        delete newState["last page viewed"];
        return {
          ...newState,
          "last page viewed": "Beneficiary confirmation page",
        };
      });
    }
  }, [
    activeStep,
    paymentMethod,
    amountInEuros,
    checked,
    widthdrawMethod,
    allDirectPaymentMethods,
  ]);

  useLayoutEffect(() => {
    get_bank_accounts();
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    setReceivingCountries(getLoggedInUserReceivingCountries(transfer, user));
    if (transfer.trueLayerError !== null) {
      toast.error(transfer.trueLayerError);
    }
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <div
        id="mainMakeTransferModalContainer"
        className="flex flex-row absolute w-full items-center justify-between px-10 py-2"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <span className="text-white">{t("start_Transfer")}</span>

        <button
          data-transfer-cancel="transfer-cancel"
          onClick={() => {
            dispatch(getAllMobileOperatorWithCode(null));
            onCloseTransferWindow();
            window.heap.track("Transfer canceled", {
              phone: user.full_phone_number,
              email: user.email,
              country: user.country,
              name: user.full_name,
            });
            dispatch(setBeneFound(false));
            dispatch(setStep(0));
            dispatch(setTLError(null));
            dispatch(setTransactionToRepeat(null));
            dispatch(setTransferDialogOpen(false));
          }}
          style={{ height: 40, width: 40, borderRadius: 20 }}
          className="flex items-center justify-center shadow-lg data-transfer-cancel"
        >
          <i
            className="m-0 text-white fa fa-close fakebutton data-transfer-cancel"
            data-transfer-cancel="transfer-cancel"
          ></i>
        </button>
      </div>
      <div className={classes.root} id="mainMakeTransferBottonContainer">
        <div
          className="container flex flex-col 4625"
          style={{ height: "inherit" }}
          id="makeTransferStepper"
        >
          {size[0] < 900 ? null : (
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{t(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}

          {activeStep === steps.length ? (
            <div className="flex flex-col justify-center w-full p-10 m-auto md:w-1/2"></div>
          ) : (
            <div
              className="overflow-y-scroll flex flex-col flex-1"
              id="mainMakeTransferContainer"
            >
              {getStepContent(activeStep)}
            </div>
          )}
        </div>
        <Modal open={mm_modal}>
          <MobileMoney response={response} />
        </Modal>
      </div>
    </>
  );
};

export default MakeTransfer;
