import { completeCompanySubmission } from "../../store/features/Auth/Auth";
import { editUser, updateBalance } from "../../store/features/Auth/AuthSlice";
import { currentBalance } from "../../store/features/Transfer/Transfer";
import { customerIoTrack } from "../../utilities/customeriohelpers";
import FileUploadComp from "../FileUploadComp";
import * as Sentry from "@sentry/browser";

const CompanyDocumentsScreen = (props: any) => {
  const {
    progressBar,
    t,
    user,
    dispatch,
    setprogressBarData,
    setActiveStep,
    finish,
    toast,
  } = props;
  return (
    <div className="flex flex-col items-center justify-center mx-auto my-5 bg-white">
      <div className="col-md-4 col-10">{progressBar}</div>
      <div className="col-md-8 col-10">
        <div className="w-full p-4 bg-white rounded-lg shadow-lg">
          <h2 className="my-3 font-bold text-center text-1xl">
            {t("docsTitle")}
          </h2>
          <p className="text-center">{t("docsReason")}</p>
          <FileUploadComp
            company_id={user?.company?.id}
            t={t}
            type="signup"
            uploaded_documents={[]}
            setUploadedDocuments={(docs: any) => {
              {
                currentBalance()
                  .then((res: any) => {
                    dispatch(updateBalance(res.client.euro_balance));
                    dispatch(editUser(res));
                    if (
                      res?.company?.documents !== undefined &&
                      res?.company?.documents.length == 1 &&
                      user?.company?.documents !== undefined &&
                      user?.company?.documents.length < 1
                    ) {
                      customerIoTrack({
                        name: "Onboarding Submitted",
                        data: {},
                      });
                    }
                  })
                  .catch((error: any) => {
                    toast.warning(t(error?.data?.message));
                    Sentry.captureException(error);
                  });
              }
            }}
          />
          <br />
          <br />
          <div className="flex gap-3 max-md:justify-between">
            <button
              className="px-12 rounded-lg max-md:flex-1 btn"
              style={{ backgroundColor: "#666" }}
              onClick={() => {
                setActiveStep((prev: any) => Number(prev) - 1);
                setprogressBarData({
                  progress: 50,
                  title: "Company_Information",
                });
              }}
            >
              <span className="text-white">{t("back")}</span>
            </button>
            <button
              className="px-12 rounded-lg max-md:flex-1 btn"
              style={{ backgroundColor: "rgb(3, 115, 117)" }}
              onClick={() => {
                completeCompanySubmission()
                  .then(() => {})
                  .catch((error: any) => {
                    toast.warning(t(error?.data?.message));
                    Sentry.captureException(error);
                  })
                  .finally(() => {
                    finish();
                    window.heap.track("document upload", {
                      email: user?.email,
                    });
                  });
              }}
            >
              <span className="text-white">{t("finish")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDocumentsScreen;
