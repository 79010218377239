import * as React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormControl } from "@material-ui/core";
import * as Sentry from "@sentry/browser";

const CustomDropdown: React.FC<any> = ({
  customClasses,
  placeholder,
  list,
  setinstitutionId,
  setCustomProvider,
  onchange,
  defaultValue,
  isDisabled,
  otherValue = "Other",
  placeholderComponent,
  triggerOnChangeForDefaultValue = true,
}) => {
  // dropdown show value
  const [selectedValue, setselectedValue] = React.useState("");

  // set initial value on repeat
  React.useEffect(() => {
    try {
      if (
        defaultValue !== null &&
        defaultValue !== undefined &&
        defaultValue !== ""
      ) {
        if (list !== undefined && list !== null) {
          // find provider in existing list
          const isInList = list.find((itm: any) => itm.name == defaultValue);

          // if value is found in the list
          if (isInList !== undefined) {
            setselectedValue(defaultValue);
            triggerOnChangeForDefaultValue &&
              onchange &&
              onchange(defaultValue);

            // set custom provider empty if provider is not other
            setCustomProvider && setCustomProvider("");
          } else {
            // if not found set to other value
            setselectedValue(otherValue);
            onchange && onchange(otherValue);

            // set custom provider the other value
            setCustomProvider && setCustomProvider(defaultValue);
          }
        } else {
          setselectedValue("");
        }
      } else {
        setselectedValue("");
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [defaultValue, list]);

  const handleChange = (event: any) => {
    try {
      const {
        target: { value },
      } = event;

      // send selected value
      onchange && onchange(value);
      setselectedValue(value);
      if (value == "Other") {
        setCustomProvider && setCustomProvider("");
      }

      // send institution id
      const idItem = list.find((itm: any) => itm.name == value);
      if (idItem !== undefined) {
        if (idItem.id !== 99) {
          setinstitutionId && setinstitutionId(idItem.id);
        } else {
          setinstitutionId && setinstitutionId(null);
        }
      } else {
        setinstitutionId && setinstitutionId(null);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <FormControl className="w-full" onClick={(e) => e.stopPropagation()}>
      <Select
        disableUnderline={true}
        className={customClasses}
        displayEmpty
        disabled={isDisabled}
        style={{ fontSize: "14px" }}
        value={selectedValue}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selectedValue == "" && placeholderComponent == undefined) {
            return <div style={{ fontSize: "14px" }}>{placeholder}</div>;
          } else if (
            selectedValue == "" &&
            placeholderComponent !== undefined
          ) {
            return placeholderComponent;
          }

          return selectedValue;
        }}
        inputProps={{
          "aria-label": "Without label",
          className: "!text-wrap !pr-0 !text-base max-sm:!text-sm",
        }}
      >
        {list &&
          list.map((val: any) => (
            <MenuItem
              key={val.id}
              value={val.name}
              className="text-wrap"
              style={{ fontSize: "14px" }}
            >
              {val.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export default CustomDropdown;
