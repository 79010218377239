import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import "../helpers/i18n";
import { resetAuth } from "../store/features/Auth/AuthSlice";
import { resetTransfer } from "../store/features/Transfer/TransferSlice";
import { resetDeveloper } from "../store/features/Developer/DeveloperSlice";
import { paymentsAnonymous } from "../store/features/Transfer/Transfer";
import { toast } from "material-react-toastify";

const Loading: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const search = useLocation().search;

  useEffect(() => {
    const queryURL = new URLSearchParams(search);
    const appref: any = queryURL.get("appref") || null;
    try {
      if (appref) {
        localStorage.setItem("appref", appref);
      }

      if (localStorage.getItem("user:key")) {
        if (appref) {
          paymentsAnonymous(appref)
            .then((response: any) => {
              const id = response.details.id;
              history.push(`/payment/${id}?status=anonymous`);
            })
            .catch((error: any) => {
              toast.warning(t(error?.data?.message));
              Sentry.captureException(error);
              // if (error.status === 403) {
              if (error.status >= 400) {
                localStorage.removeItem("appref");
                toast.error(error?.data?.errors?.error[0]);
                localStorage.setItem(
                  "paymentError",
                  error?.data?.errors?.error[0]
                );
                // toast.error(error.data.message);
                if (localStorage.getItem("user:key")) {
                  history.push("/dashboard/home");
                } else {
                  dispatch(resetAuth());
                  dispatch(resetTransfer());
                  dispatch(resetDeveloper());
                  history.push("/login");
                }
              }
            });
        } else {
          history.push("/dashboard/home");
        }
      } else {
        dispatch(resetAuth());
        dispatch(resetTransfer());
        dispatch(resetDeveloper());
        history.push("/login");
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);
  return (
    <div className="loading" style={{ backgroundColor: "rgb(3, 115, 117)" }}>
      <h1>{t("welcome")}</h1>
      <small>{t("version")} 1.0.4</small>
    </div>
  );
};

export default Loading;
