import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import BankDetails from "./BankDetails";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import CashLayout from "./CashLayout";
import * as Sentry from "@sentry/browser";
import {
  bulkTransferPayment,
  calculateBulkTransferFees,
  currentBalance,
  getAppliedFeesForTransfers,
  makeBulkyPayment,
  verifyBulkCsv,
} from "../store/features/Transfer/Transfer";
import { extractError } from "../utility";
import SelectField from "./forms/SelectField";
import { toast } from "material-react-toastify";
import { getFavorites } from "../store/features/Auth/Auth";
import Avatar from "react-avatar";
import {
  bulkTransferCsvPayment,
  openBulkTransferDialog,
  resetPayment,
  updateBulkTData,
  updateUsersForBulkyTransfer,
  updateUsersForBulkyTransferFees,
  verifiedBulkCsv,
} from "../store/features/Transfer/TransferSlice";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import InputField from "./forms/InputField";
import BeneficiarySummary from "./BeneficiarySumary";
import {
  buildSelectInputData,
  calculateSumFromArray,
  calculateTotalFromArray,
  getCashInMethod,
  getLoggedInUserReceivingCountries,
  selectPerviousUsersList,
} from "../utilities/help";
import CountryPicker from "./CountryPicker";
import Contacts from "./Contacts";
import Contact from "./Contact";
import { MyComponent } from "./TransactionsCard/CsvTable";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { getReceivingCountryID } from "../utilities/help";
import { translateError } from "../utilities/help";
import { getCashInMethodName } from "../utilities/help";
import { confirmAlert } from "react-confirm-alert"; // Import
import FileUpload from "./FileUpload";

const RATE = 655.957;
const classApply = false;

const BulkPayment: React.FC<any> = ({ setBulkPaymentModal, t }) => {
  const dispatch = useAppDispatch();

  const {
    transfer,
    auth: { user, currency, lang, rate, dana_pay_bank_accounts },
  } = useAppSelector((state) => state.persistedReducer);
  const steps = [
    t("beneficiary"),
    t("Amount"),
    t("payment"),
    t("Confirmation"),
  ];
  const [size, setSize] = useState([0, 0]);
  const [activeStep, setActiveStep] = useState(0);
  const [fees, setFees] = useState(0);
  const [payMethod, setPayMethod] = useState(
    // transfer?.bulkTransferData
    transfer?.bulkTransferData?.paymentMethod !== undefined &&
      transfer?.bulkTransferData?.paymentMethod !== ""
      ? transfer?.bulkTransferData?.paymentMethod
      : ""
  );
  const [openDialog, setopenDialog] = useState(false);
  const [openFailDialog, setopenFailDialog] = useState(false);
  const [openFailDialogLoss, setopenFailDialogLoss] = useState(false);
  const [openSucessDialog, setopenSucessDialog] = useState(false);
  const [amountEuro, setAmountEuro] = useState(0);
  const [errorGot, setErrorGot] = React.useState("");
  const [responseObject, setResponseObject] = React.useState<any>(null);
  const [euroBalance, setEuroBalance] = React.useState(0);
  const [selectedBank, setSelectedBank] = React.useState("");
  const [favorites, setFavorites] = React.useState([]);
  const [processingPayment, setProcessingPayment] = React.useState(false);
  const [fetchingFees, setFetchingFees] = React.useState(false);
  const [wasBalance, setWasBalance] = React.useState<any>(false);
  const [fileCSV, setFileCSV] = React.useState<any>([]);
  const [dataCSV, setDataCSV] = React.useState<any>([]);
  const [uploadingCSV, setUploadingCSV] = React.useState<any>([]);
  const [dataSucessCSV, setDataSucessCSV] = React.useState<any>([]);
  const [showTableCSV, setshowTableCSV] = React.useState<boolean>(false);
  const [selectedCountryReset, setSelectedCountryReset] =
    useState<boolean>(false);
  const [checkedCashMethod, setCheckedcashMethod] = useState<boolean>(false);
  const [checkedCashMethodTwo, setCheckedcashMethodTwo] =
    useState<boolean>(false);
  const [checkedCashMethodThree, setCheckedcashMethodThree] =
    useState<boolean>(false);
  const [checkedCashMethodFour, setCheckedcashMethodFour] =
    useState<boolean>(false);
  const [checkedMobileBox, setCheckedMobileBox] = useState<boolean>(false);
  const [selectedCashInReset, setSelectedCashInReset] =
    useState<boolean>(false);
  const [balaceTransferForBulk, setbalaceTransferForBulk] = useState(0);

  const [selectedBankState, setselectedBankState] = useState([]);

  useEffect(() => {
    if (
      dana_pay_bank_accounts !== undefined &&
      dana_pay_bank_accounts.length > 0
    ) {
      setselectedBankState(
        dana_pay_bank_accounts.map((i: any) => ({ ...i, checked: false }))
      );
    }
  }, [dana_pay_bank_accounts]);

  const [spread_config, setSpreadConfig] = React.useState({
    spread: 0,
    net_exchange_rate: 655.957,
    exchange_rate: 655.957,
    received_amount: 0,
  });

  const [paymentMethod, setPaymentMethod] = React.useState<any>("");

  const history = useHistory();

  useEffect(() => {
    try {
      if (transfer?.usersForBulkyTransfer?.length > 0) {
        showTransfer(transfer?.usersForBulkyTransfer);
        if (transfer?.bulkTransferData?.paymentMethod !== "") {
          // for repeat get fees
          handleCashMethod(
            true,
            t(payMethod?.toLowerCase().split(" ").join("_")).toLowerCase(),
            window.localStorage.getItem("i18nextLng") == "fr" ? true : false
          );
        }
      }
      if (Object.keys(transfer?.usersForBulkyTransfer).length < 1) {
        // set balance as default if balance greater than 0
        // if (!isNaN(user?.client?.euro_balance) && Number(user?.client?.euro_balance) > 0) {
        //   dispatch(resetPayment("balance"));
        // } else {
        //   dispatch(resetPayment(""));
        // }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, []);

  // doesnt work
  const calculateFees = () => {
    setErrorGot("");
    setFetchingFees(true);
    const selectedCashInMethodArray = getCashInMethod(transfer, payMethod);

    if (!selectedCashInMethodArray) {
      return;
    }

    const receiving = transfer.loggedInUserCountry?.receiving_countries?.find(
      (receiving_country_data: any) =>
        receiving_country_data?.receiving_country?.name?.toLowerCase() ===
        transfer?.bulkTransferData?.country?.toLowerCase()
    );

    if (!receiving) return;
    const totals: any = Object.values(transfer.usersForBulkyTransfer)?.map(
      (user: any) => user.enteredValue
    );

    // const totals: any = calculateTotalFromArray(selectedUsers);
    interface IKeys {
      id: string;
      identification_number: number;
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
      amount_without_fees: string;
    }

    const beneficiaryArray =
      fileCSV?.data === undefined
        ? fileCSV.map(
            (val: any, index: any): IKeys => ({
              id: val.id,
              identification_number: index + 1,
              first_name: val.first_name,
              last_name: val.last_name,
              email: val.email,
              phone_number: val.phone_number,
              amount_without_fees: val.amount_without_fees,
            })
          )
        : fileCSV?.data.map(
            (val: any, index: any): IKeys => ({
              id: val.id,
              identification_number: index + 1,
              first_name: val.first_name,
              last_name: val.last_name,
              email: val.email,
              phone_number: val.phone_number,
              amount_without_fees: val.amount_without_fees,
            })
          );

    const filterOperator = transfer.danaPayCountries.filter(
      (e: any) =>
        e.name.toLowerCase() === transfer.bulkTransferData.country.toLowerCase()
    );
    calculateBulkTransferFees(
      {
        euro_amount:
          calculateSumFromArray(
            fileCSV?.data === undefined ? fileCSV : fileCSV.data
          )?.total.toString() ||
          calculateTotalFromArray(transfer.usersForBulkyTransfer)?.total,
        sending_country_id: transfer.loggedInUserCountry.id,
        receiving_country_id: receiving.receiving_country.id,
        // destination_user_ids: Object.keys(transfer.usersForBulkyTransfer),
        cashin_method_id: selectedCashInMethodArray.cash_in_method.id,
        beneficiaries: beneficiaryArray,
        source_user_id: user?.id,
        country_code:
          filterOperator.length > 0 ? filterOperator[0].country_code : "",
        reason: transfer?.bulkTransferData?.reason,
      },
      user?.id
    )
      .then((response: any) => {
        setErrorGot("");
        setFees(response.fee);

        // find spread config settings

        const s_config = response.fee_calculation.find((value: any) =>
          value.hasOwnProperty("spread")
        );

        if (s_config) {
          setSpreadConfig(s_config);
        }

        setFetchingFees(false);
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t(error?.data?.message));
        setFetchingFees(false);
        if (error) {
          const err = extractError(error);
          setErrorGot(err);
        }
      });
  };

  const getUserContacts = () => {
    getFavorites()
      .then((res: any) => {
        setFavorites(res);
        const cc = new Set(
          res.map((response: any) => response.favorite.country)
        );
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t(error?.data?.message));
      });
  };

  const submitRequest = async (data: any) => {
    try {
      interface IKeys {
        id: string;
        identification_number: number;
        first_name: string;
        last_name: string;
        email: string;
        phone_number: string;
        amount_without_fees: string;
      }
      const beneficiaryArray =
        fileCSV?.data === undefined
          ? fileCSV.map(
              (val: any, index: any): IKeys => ({
                id: val.id,
                identification_number: index + 1,
                first_name: val.first_name,
                last_name: val.last_name,
                email: val.email,
                phone_number: val.phone_number,
                amount_without_fees: val.amount_without_fees,
              })
            )
          : fileCSV?.data.map(
              (val: any, index: any): IKeys => ({
                id: val.id,
                identification_number: index + 1,
                first_name: val.first_name,
                last_name: val.last_name,
                email: val.email,
                phone_number: val.phone_number,
                amount_without_fees: val.amount_without_fees,
              })
            );
      const params = {
        beneficiaries: beneficiaryArray,
      };

      // verify all receipents have amount defined
      let isbeneficiaryArrayOk = true;
      if (beneficiaryArray?.length > 0) {
        beneficiaryArray.forEach((element: any) => {
          if (
            element?.amount_without_fees == "" ||
            element?.amount_without_fees == 0 ||
            element?.amount_without_fees == "0" ||
            !element.hasOwnProperty("amount_without_fees")
          ) {
            toast.error(t("Please_enter_amount"));
            isbeneficiaryArrayOk = false;
            return;
          }
        });
      }
      if (!isbeneficiaryArrayOk) {
        return;
      }
      verifyBulkCsv(params)
        .then((res: any) => {
          dispatch(verifiedBulkCsv(res));
          if (res.status === "success") {
            if (data === false) {
              window.heap.track("Bulk recepient details", {
                email: user.email,
                country: user.country,
                phone: user.full_phone_number,
                name: user.full_name,
              });
            }
            setActiveStep(1);
          }
          // toast.success(res.status);
        })
        .catch((error: any) => {
          setActiveStep(0);
          toast.error(extractError(error));
          Sentry.captureException(error);
        });

      const selectedCashInMethodArrayName = getCashInMethodName(
        transfer,
        payMethod
      );
      const selectedCashInMethodArray = getCashInMethod(transfer, payMethod);
      if (selectedCashInMethodArray !== undefined) return;
      const receiving = transfer.loggedInUserCountry?.receiving_countries?.find(
        (receiving_country_data: any) =>
          receiving_country_data?.receiving_country?.name?.toLowerCase() ===
          transfer.bulkTransferData.country.toLowerCase()
      );

      if (data === true && !transfer?.bulkTransferData?.reason) {
        toast.error(t("reasonError"));
        return;
      }

      if (data === true && !payMethod) {
        toast.error(t("selectCashIn"));
        return;
      }

      if (!receiving) {
        toast.error(t("country_not_configured"));
        return;
      }

      data === true && setProcessingPayment(true);

      const payload = {
        source_user_id: user.id,
        destination_user_ids: Object.keys(transfer.usersForBulkyTransfer),
        amount_without_fees_in_euro:
          Object.values(transfer.usersForBulkyTransfer)?.map(
            (user: any) => user.enteredValue
          ) || 0,
        cashin_method_id:
          checkedCashMethod === true
            ? selectedCashInMethodArrayName?.cash_in_method?.id
            : selectedCashInMethodArray?.cash_in_method?.id,
        local_currency: "cfa",
        is_escrowed: false,
        reason: transfer.bulkTransferData.reason,
      };
      // makeBulkyPayment(payload)
      //   .then((response: any) => {
      //     setResponseObject(response);
      //     if (payMethod.toLowerCase() === "balance") {
      //       setActiveStep(3);
      //       setWasBalance(true);
      //     } else if (
      //       payMethod.toLowerCase() === "direct_debit" ||
      //       payMethod.toLowerCase() === "card"
      //     ) {
      //       window.location?.replace(response.connect_url);
      //     } else {
      //       setActiveStep(2);
      //     }
      //     // setProcessingPayment(false);
      //   })
      //   .catch((error:any) => {
      //     // setProcessingPayment(false);
      //     // toast.error(extractError(error));
      //   });
      if (data === true) {
        const filterOperator = transfer.danaPayCountries.filter(
          (e: any) =>
            e.name.toLowerCase() ===
            transfer.bulkTransferData.country.toLowerCase()
        );
        // const params = {
        //   cashin_method_id: checkedCashMethod === true
        //     ? selectedCashInMethodArrayName?.cash_in_method?.id
        //     : selectedCashInMethodArray?.cash_in_method.id,
        //   beneficiaries: beneficiaryArray,
        //   source_user_id: user?.id,
        //   country_code: filterOperator.length > 0 ? filterOperator[0].country_code : '',
        //   reason: transfer?.bulkTransferData?.reason
        // };
        const params = {
          cashin_method_id: selectedCashInMethodArrayName?.cash_in_method?.id,
          beneficiaries: beneficiaryArray,
          source_user_id: user?.id,
          country_code:
            filterOperator.length > 0 ? filterOperator[0].country_code : "",
          reason: transfer?.bulkTransferData?.reason,
        };
        await bulkTransferPayment(params)
          .then((res: any) => {
            window.heap.track("Bulk payment details", {
              email: user.email,
              country: user.country,
              phone: user.full_phone_number,
              name: user.full_name,
            });
            window.heap.track("Bulk payment initiation", {
              email: user.email,
              country: user.country,
              phone: user.full_phone_number,
              name: user.full_name,
            });
            dispatch(bulkTransferCsvPayment(res));
            // toast.success(res.message);
            setProcessingPayment(false);
            if (
              payMethod.toLowerCase() === "bank card" ||
              payMethod.toLowerCase() === "instant sepa"
            ) {
              window.location?.replace(res.connect_url);
            } else if (payMethod.toLowerCase() === "balance") {
              setbalaceTransferForBulk(
                res.details.transfer.bulk_transfer_recipient_details
                  .reduce(
                    (total: any, accumulator: any): Number =>
                      parseFloat(total) +
                      parseFloat(accumulator.amount_in_euros),
                    0
                  )
                  .toFixed(2)
              );
              setActiveStep(3);
              setWasBalance(true);
            } else {
              setActiveStep(2);
              setbalaceTransferForBulk(
                res.details.transfer.bulk_transfer_recipient_details
                  .reduce(
                    (total: any, accumulator: any): Number =>
                      parseFloat(total) +
                      parseFloat(accumulator.amount_in_euros),
                    0
                  )
                  .toFixed(2)
              );
            }
          })
          .catch((error: any) => {
            toast.error(extractError(error));
            setProcessingPayment(false);
            Sentry.captureException(error);
          });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handelClickCsv = () => {
    setopenDialog(true);
    if (showTableCSV === true) {
      toast.error(t("Upload_Another_Csv_Toast"));
    }
    // setopenSucessDialog(true);
    // setopenFailDialog(true)
  };
  const handleClose = () => {
    setopenDialog(false);
  };

  // csv upload
  const upload = (e: React.SetStateAction<any>) => {
    setProcessingPayment(true);
    setUploadingCSV(e);
    if (e?.length > 0) {
      if (
        e[0]?.name?.split(".")[e[0]?.name?.split(".")?.length - 1] === "csv"
      ) {
        setProcessingPayment(false);
        setDataCSV(e[0]);
      }
    } else {
      toast.error(t("Drag_Drop_Csv_Error"));
      setProcessingPayment(false);
    }
    // setDataCSV(e?.target?.files[0]);
  };

  const showTransfer = (usersForBulkyTransfer: any) => {
    try {
      if (usersForBulkyTransfer) {
        if (usersForBulkyTransfer?.length > 999) {
          setopenFailDialog(true);
        } else {
          setProcessingPayment(false);
          interface IKeys {
            id: string;
            identification_number: number;
            first_name: string;
            last_name: string;
            email: string;
            phone_number: string;
            amount_without_fees: string;
          }
          const beneficiaryArray = usersForBulkyTransfer.map(
            (val: any, index: any): IKeys => ({
              id: index,
              identification_number: index + 1,
              first_name: val.first_name,
              last_name: val.last_name,
              email: val.email,
              phone_number: val.phone_number,
              amount_without_fees: val.amount_in_euros,
            })
          );
          setDataSucessCSV(beneficiaryArray);
          const params = {
            beneficiaries: beneficiaryArray,
          };
          verifyBulkCsv(params)
            .then((res: any) => {
              dispatch(verifiedBulkCsv(res));
              // toast.success(res.status);
            })
            .catch((error: any) => {
              toast.error(extractError(error));
              Sentry.captureException(error);
            });
          // bulkTransferPayment(params);
          setopenSucessDialog(true);
        }
      } else {
        Papa.parse(dataCSV, {
          header: true,
          complete: function (results: { data: any; errors: any }) {
            if (results?.data?.length > 999) {
              setopenFailDialog(true);
            } else if (results?.errors?.length > 0) {
              toast.error(t("Upload_Csv_Toast_Error"));
              setopenFailDialog(true);
            } else {
              setProcessingPayment(false);
              setDataSucessCSV(results);
              interface IKeys {
                id: string;
                identification_number: number;
                first_name: string;
                last_name: string;
                email: string;
                phone_number: string;
                amount_without_fees: string;
              }
              const beneficiaryArray = results.data.map(
                (val: any, index: any): IKeys => ({
                  id: val.id,
                  identification_number: index + 1,
                  first_name: val.first_name,
                  last_name: val.last_name,
                  email: val.email,
                  phone_number: val.phone_number,
                  amount_without_fees: val.amount_without_fees,
                })
              );
              // const filterOperator = transfer.danaPayCountries.filter((e: any) => e.name.toLowerCase() === transfer.bulkTransferData.country.toLowerCase());
              // const params = {
              //   cashin_method_id: 1,
              //   beneficiaries: beneficiaryArray,
              //   source_user_id: user?.id,
              //   country_code: filterOperator.length > 0 ? filterOperator[0].country_code : ''
              // };
              const params = {
                beneficiaries: beneficiaryArray,
              };
              verifyBulkCsv(params)
                .then((res: any) => {
                  dispatch(verifiedBulkCsv(res));
                  // toast.success(res.status);
                })
                .catch((error: any) => {
                  toast.error(extractError(error));
                  Sentry.captureException(error);
                });
              // bulkTransferPayment(params);
              setopenSucessDialog(true);
            }
          },
          // if (results?.data?.length > 499999) {
          //   setopenFailDialog(true);
          // } else if (results?.errors?.length > 0) {
          //   toast.error(t("Upload_Csv_Toast_Error"));
          //   setopenFailDialog(true);
          // } else {
          //   setDataSucessCSV(results);
          //   setopenSucessDialog(true);
          // }
        });
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const update_selected = (user: any) => {
    if (
      transfer.bulkTransferData.country !== "" &&
      user?.country?.toLowerCase() ===
        transfer.bulkTransferData.country?.toLowerCase()
    ) {
      dispatch(updateUsersForBulkyTransfer(user));
    }
    if (user?.length > 0) {
      user?.filter((val: any) => {
        if (
          transfer.bulkTransferData.country !== "" &&
          val?.favorite?.country?.toLowerCase() ===
            transfer.bulkTransferData.country?.toLowerCase()
        ) {
          dispatch(updateUsersForBulkyTransfer(val.favorite));
        }
      });
    }
  };

  const getBalance = async () => {
    try {
      const balance: any = await currentBalance();
      const bal = balance?.client.euro_balance;
      setEuroBalance(bal);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const handleCashMethod = (checked: any, value: any, selectCashIn: any) => {
    setSelectedCashInReset(selectCashIn);
    setCheckedcashMethod(checked);
    setPayMethod(checked === false ? "" : value);
    // if (value === 'bank transfer' || value === 'virement bancaire') {
    //   setSelectedCashInReset(selectCashIn);
    //   setCheckedcashMethod(checked);
    //   setPayMethod(value);
    //   setCheckedcashMethodTwo(false);
    //   setCheckedcashMethodThree(false);
    //   setCheckedcashMethodFour(false);
    // } else if (value === 'bank card' || value === 'carte bancaire') {
    //   setSelectedCashInReset(selectCashIn);
    //   setCheckedcashMethodTwo(checked);
    //   setPayMethod(value);
    //   setCheckedcashMethod(false);
    //   setCheckedcashMethodThree(false);
    //   setCheckedcashMethodFour(false);
    // } else if (value === 'balance' || value === 'solde') {
    //   setSelectedCashInReset(selectCashIn);
    //   setCheckedcashMethodThree(checked);
    //   setPayMethod(value);
    //   setCheckedcashMethod(false);
    //   setCheckedcashMethodTwo(false);
    //   setCheckedcashMethodFour(false);
    // } else if (value === 'instant sepa' || value === 'virement spea instantané') {
    //   setSelectedCashInReset(selectCashIn);
    //   setCheckedcashMethodFour(checked);
    //   setPayMethod(value);
    //   setCheckedcashMethod(false);
    //   setCheckedcashMethodTwo(false);
    //   setCheckedcashMethodThree(false);
    // }
  };

  const getStepContent = () => {
    if (activeStep === 0) {
      return (
        <div className="items-center justify-center w-full h-full">
          <div className="mt-4 row">
            <div className="py-2 col-md-12 col-sm-12 col-xs-12">
              <div className="flex flex-col items-center justify-center m-auto bg-white rounded-lg">
                <div className="w-full my-2 ">
                  <div className="row">
                    <div className="col-md-4 mx-auto">
                      <h2 className="mb-2 text-2xl font-bold text-center">
                        {t("Select_Beneficiaries")}
                      </h2>

                      <CountryPicker
                        setCountry={(text: any) => {
                          dispatch(updateBulkTData({ country: text.name }));

                          // clear bulk users when country change
                          dispatch(updateUsersForBulkyTransfer(null));
                        }}
                        country={
                          transfer?.usersForBulkyTransfer?.length > 0
                            ? transfer?.usersForBulkyTransfer[0]?.country
                            : transfer?.bulkTransferData?.country
                        }
                        onChange={null}
                        value={""}
                        cash_Out_Method_Name={""}
                        withPhoneInput={false}
                        setSelectedCountry={(e: any) =>
                          setSelectedCountryReset(e)
                        }
                      />
                      <p className="mb-3 text-center ">
                        {t("Beneficiary_selection")}
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-md-10 mx-auto ">
                    <div className="flex items-center justify-center">
                      <div className="flex flex-start">
                        {/* <a className="mr-3">
                          <Contacts buttonOnly={true} />
                        </a> */}
                        {/* <SelectField
                          value={paymentMethod}
                          data={selectPerviousUsersList(favorites, transfer)}
                          setData={(value: string) => { setPaymentMethod(value) }}
                          width={true}
                          label={t("selectPreviousUserList")}
                          classApply={true}
                          hasOptionalInput
                        /> */}
                        {/* {favorites
                  <div className="col-md-8 mx-auto ">
                    <div className="flex items-center justify-between">
                      <div className="flex flex-start">
                        <a className="mr-3">
                          <Contacts buttonOnly={true} />
                        </a>
                        {favorites
                          .filter(
                            (userObj: any) =>
                              userObj?.favorite?.full_name !== " "
                          )
                          .map((user: any, index: number) => {
                            if (
                              user?.favorite.country.toLowerCase() ===
                              transfer.bulkTransferData.country.toLowerCase()
                            ) {
                              return (
                                <Contact
                                  keyprop={index}
                                  contact={user}
                                  moveToTransfer={() =>
                                    update_selected(user.favorite)
                                  }
                                  is_active={
                                    transfer.usersForBulkyTransfer[
                                    user?.favorite?.id
                                    ]
                                  }
                                />
                              );
                            }
                          })} */}
                      </div>
                      <button
                        className={`btn   bg-blue-400
                   ${
                     // size[0] > 500 ? "mr-2 rounded-lg" : "mb-2 mr-2 "
                     "rounded-lg"
                   }`}
                        onClick={() => {
                          handelClickCsv();
                        }}
                        style={{
                          backgroundColor: "rgb(255, 255, 255)",
                          border: "1px solid #d1d1d1",
                        }}
                      >
                        <i className="fa fa-upload text-black" />
                        <b className="font-bold text-black">
                          <small style={{ fontSize: 12 }}>
                            {t("Upload_csv")}
                          </small>
                        </b>
                      </button>
                    </div>
                  </div>
                  <div className="my-10 row">
                    <div className="col-md-10 mx-auto ">
                      {showTableCSV && (
                        <MyComponent data={fileCSV} setCsvData={setFileCSV} />
                      )}
                      <table className="table">
                        {/* <tbody>
                          {Object.keys(transfer.usersForBulkyTransfer).map(
                            (value: any, index: number) => (
                              <tr key={index}>
                                {size[0] > 600 && (
                                  <td>
                                    <Avatar
                                      name={
                                        transfer.usersForBulkyTransfer[value]
                                          ?.full_name
                                      }
                                      size="30"
                                      round
                                    />
                                  </td>
                                )}

                                <td>
                                  <small className="capitalize">
                                    {
                                      transfer.usersForBulkyTransfer[value]
                                        ?.full_name
                                    }
                                  </small>
                                </td>
                                {size[0] > 600 && (
                                  <td>
                                    <small className="capitalize">
                                      {transfer.usersForBulkyTransfer[value]
                                        ?.is_individual
                                        ? "individual"
                                        : transfer.usersForBulkyTransfer[value]
                                          .company?.name}
                                    </small>
                                  </td>
                                )}

                                <td>
                                  <input
                                    type="number"
                                    onChange={(e) => {
                                      const amount = parseFloat(e.target.value);
                                      dispatch(
                                        updateUsersForBulkyTransferFees({
                                          id: value,
                                          amount,
                                          amount_cfa: amount * RATE,
                                        })
                                      );
                                    }}
                                    style={{ fontSize: 12 }}
                                    className="form-control"
                                    placeholder={t("eur_amount")}
                                    value={
                                      transfer.usersForBulkyTransfer[value]
                                        ?.enteredValue
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("Sent_amount_Cfa")}
                                    onChange={(e) => {
                                      const amount = parseFloat(e.target.value);
                                      dispatch(
                                        updateUsersForBulkyTransferFees({
                                          id: value,
                                          amount: (amount / RATE).toFixed(3),
                                          amount_cfa: amount,
                                        })
                                      );
                                    }}
                                    value={
                                      transfer.usersForBulkyTransfer[value]
                                        ?.value_cfa
                                    }
                                    style={{ fontSize: 12 }}
                                  />
                                </td>
                              </tr>
                            )
                          )}
                        </tbody> */}
                      </table>
                    </div>
                  </div>
                </div>

                {/* continue button */}
                <button
                  id="bulk-payment-details"
                  onClick={() => {
                    const blank = Object.values(
                      transfer?.usersForBulkyTransfer
                    ).filter(
                      // (item: any) => !item?.hasOwnProperty("enteredValue")
                      (item: any) =>
                        !item?.hasOwnProperty("amount_in_euros") ||
                        item?.amount_in_euros == ""
                    );
                    if (blank?.length > 0) {
                      toast.error(t("Please_enter_amount"));
                    } else {
                      // setActiveStep((prev) => prev + 1);
                      submitRequest(false);
                    }
                  }}
                  className="px-10 rounded-md btn"
                  style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  disabled={
                    // Object.keys(transfer.usersForBulkyTransfer).length === 0
                    // paymentMethod !== '' &&
                    fileCSV?.data === undefined
                      ? (fileCSV?.length > 0 &&
                          transfer?.usersForBulkyTransfer[0]?.country !== "") ||
                        (fileCSV?.length > 0 &&
                          transfer.bulkTransferData.country !== "")
                        ? false
                        : true
                      : fileCSV?.data?.length > 0 &&
                        transfer.bulkTransferData.country !== ""
                      ? false
                      : true
                  }
                >
                  <small className="mx-10 font-bold text-white">
                    {t("continue")}
                  </small>

                  {/* <button onClick={() => submitRequest(false)} id="bulk-payment-details">
                    <small className="mx-10 font-bold text-white">
                      {t("continue")}
                    </small>
                  </button> */}
                  {/* <small className="mx-10 font-bold text-white"
                    onClick={() => submitRequest()}>
                    {t("continue")}
                  </small> */}
                </button>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (activeStep === 1) {
      return (
        <div className="items-center justify-center w-full h-full">
          <div className="row">
            <div className="my-20 col-md-10 mx-auto">
              <div className="shadow-lg row">
                <div className="flex flex-row justify-between border-b-2 col-md-12">
                  <div className="flex-1">
                    <BeneficiarySummary
                      name={`${
                        Object.keys(transfer.usersForBulkyTransfer).length ||
                        fileCSV?.data === undefined
                          ? fileCSV?.length
                          : fileCSV?.data?.length
                      } ${t("People_selected")}`}
                      company={transfer.bulkTransferData.country}
                    />
                  </div>

                  <div className="flex flex-col items-end justify-center flex-1 w-1/3">
                    <small>{t("Balance")}</small>
                    <CashLayout cash={euroBalance} size="text-sm" />
                  </div>
                </div>

                <div className="p-4 col-md-6">
                  <InputField
                    extraClasses="bankNameDropDown mb-3"
                    handleChange={(text: any) =>
                      dispatch(updateBulkTData({ reason: text.target.value }))
                    }
                    value={transfer?.bulkTransferData?.reason}
                    placeholder={t("reason")}
                    label={t("reason")}
                    type="text"
                    name="reason"
                  />

                  {/* cash in methods */}
                  <div className="mb-3">
                    <label className="withdrawTitle">
                      {t("selectCashinMethod")}
                    </label>
                    <div className="bacnkCheckBoxWrapper flex flex-col gap-2 p-0 m-0 p-2 my-2">
                      {transfer.loggedInUserCountry?.cash_in_methods?.map(
                        (value: any, index: any) => {
                          return (
                            <div className="wrapperBankCheckBox innerBankBox m-0">
                              <div className="wrapperBankCheckBoxwrapper">
                                <input
                                  type="checkbox"
                                  className="default:ring-2"
                                  onChange={(e: any) =>
                                    handleCashMethod(
                                      e.target.checked,
                                      value.cash_in_method?.name.toLowerCase(),
                                      false
                                    )
                                  }
                                  // onBlur={handleBlur}
                                  name={value?.cash_in_method?.name}
                                  // checked={index === 0
                                  //   ? checkedCashMethod
                                  //   : index === 1 ? checkedCashMethodTwo
                                  //     : index === 2 ? checkedCashMethodThree
                                  //       : checkedCashMethodFour}
                                  checked={
                                    selectedCashInReset === true
                                      ? t(
                                          value?.cash_in_method?.name
                                            ?.toLowerCase()
                                            .split(" ")
                                            .join("_")
                                        ) ===
                                        t(payMethod?.toLowerCase())
                                          .substring(0, 1)
                                          .toUpperCase() +
                                          t(payMethod?.toLowerCase())
                                            .substring(1)
                                            .toLowerCase()
                                            .replaceAll("_", " ")
                                      : value.cash_in_method?.name.toLowerCase() ===
                                        payMethod
                                  }
                                />
                                <span className="checkmark"></span>
                              </div>
                              <label>
                                {t(
                                  value?.cash_in_method?.name
                                    ?.toLowerCase()
                                    .split(" ")
                                    .join("_")
                                )}
                              </label>
                            </div>
                          );
                        }
                      )}
                    </div>
                    {/* <SelectField
                      value={payMethod}
                      data={buildSelectInputData(transfer)}
                      setData={(value: any) => {
                        setPayMethod(value);
                      }}
                      width={true}
                      label={t("selectCashinMethod")}
                      hasOptionalInput={true}
                      classApply={false}
                    /> */}
                  </div>

                  <div className="py-3">
                    <button
                      onClick={() => setActiveStep((prev) => prev - 1)}
                      className="px-10 bg-gray-600 rounded-md btn"
                    >
                      <small className="mx-2 font-bold text-white">
                        {t("Back")}
                      </small>
                    </button>
                    {processingPayment ? (
                      <button
                        disabled
                        className="px-10 mx-3 rounded-md btn"
                        style={{
                          backgroundColor: "rgb(3, 115, 117)",
                          color: "white",
                        }}
                      >
                        {t("processing")}
                      </button>
                    ) : (
                      <button
                        id="bulk-payment-initiation"
                        onClick={() => submitRequest(true)}
                        className="px-10 mx-3 rounded-md btn"
                        style={{ backgroundColor: "rgb(3, 115, 117)" }}
                        disabled={processingPayment || fetchingFees}
                      >
                        <small className="mx-2 font-bold text-white">
                          {t("continue")}
                        </small>
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="px-4">
                    <div className="flex flex-row justify-between my-4">
                      <small>{t("amount")}</small>
                      <small>
                        <CashLayout
                          cash={
                            calculateSumFromArray(
                              fileCSV?.data === undefined
                                ? fileCSV
                                : fileCSV.data
                            )?.total
                            // calculateTotalFromArray(
                            //   transfer.usersForBulkyTransfer
                            // )?.total
                          }
                        />
                      </small>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <small>{t("fees")}</small>
                      <small>
                        {fetchingFees ? (
                          <CircularProgress size={14} />
                        ) : (
                          <span>
                            <CashLayout
                              cash={
                                // calculateSumFromArray(
                                //   fileCSV.data
                                // )?.total_cfa
                                // ||
                                fees
                              }
                            />
                          </span>
                        )}
                      </small>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <small style={{ color: "rgb(3, 115, 117)" }}>
                        {t("total")}
                      </small>
                      <small style={{ color: "rgb(3, 115, 117)" }}>
                        <CashLayout
                          cash={
                            fetchingFees === false
                              ? calculateSumFromArray(fileCSV.data)?.total +
                                  fees || 0 + fees
                              : calculateSumFromArray(fileCSV.data)?.total ||
                                0 + fees

                            // calculateTotalFromArray(
                            //   transfer.usersForBulkyTransfer
                            // )?.total || 0 + fees
                          }
                        />
                      </small>
                    </div>
                    <div className="flex flex-row justify-between my-4">
                      <small>{t("exchange_rate")}</small>
                      <small>1EUR = {spread_config.exchange_rate} CFA</small>
                    </div>

                    <div
                      className="flex flex-row justify-between my-4"
                      style={{ color: "rgb(3, 115, 117)" }}
                    >
                      <small>{t("Total_Received")}</small>
                      <small>
                        <CashLayout
                          cash={
                            calculateSumFromArray(fileCSV.data)?.total_cfa ||
                            spread_config.received_amount
                          }
                          isCfa={true}
                          size="text-sm"
                        />
                      </small>
                    </div>

                    <div className="flex flex-row justify-between my-4">
                      <small>{t("PaymentMode")}</small>
                      <small>
                        {selectedCashInReset === true
                          ? t(payMethod?.toLowerCase())
                              .substring(0, 1)
                              .toUpperCase() +
                            t(payMethod?.toLowerCase())
                              .substring(1)
                              .toLowerCase()
                              .replaceAll("_", " ")
                          : t(payMethod?.toLowerCase().split(" ").join("_"))}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (activeStep === 2) {
      return (
        <div className="h-full pt-10">
          <div className="p-10 row mx-auto flex flex-col items-center justify-center p-10 my-2 shadow-lg col-md-5">
            {/* <div className="flex flex-col items-center justify-center p-4 my-2 shadow-lg col-md-4"> */}
            <div className="col-md-3" />
            {/* <div className="p-4 col-md-6"> */}
            <div className="my-4 mb-4 text-center">
              <h3 className="text-2xl font-bold">
                {/* <h3 className="mb-3 text-2xl font-bold text-gray-900"> */}
                {t("payment_instruction")}
              </h3>
              <p>{t("pi_text")}</p>
            </div>

            {/* {dana_pay_bank_accounts.length === 0 ? (
                      <>
                        <div className="p-3 bg-red-100 shadow-md border-1">
                          <p className="my-2">
                            <b className="text-red-500">Oops sorry</b>
                          </p>
                          <p>
                            <small>
                              Sorry we don't have banks in your country at the moment,
                              Please send us an email here: <b>support@danapay.com</b>
                              , Make sure to include the two (IDs)
                            </small>
                          </p>
                          <br />

                          <p>
                            <small>
                              Transaction ID :{" "}
                              <b>
                                {responseObject &&
                                  responseObject?.details?.transaction_id}
                              </b>
                            </small>
                          </p>
                          <p>
                            <small>
                              ID is :{" "}
                              <b>{responseObject && responseObject?.details?.id}</b>
                            </small>
                          </p>
                          <div className="w-full my-4">
                            <ContactBtn
                              t={t}
                              message={`Hi Danapay. \n\n I could not see any bank accounts for my country. \n Here are the IDs for my transaction I made; Transaction ID: ${responseObject?.details?.transaction_id}, and Transfer ID: ${responseObject?.details?.id}. \n\nMy Details \nEmail:${user.email}.\nName:${user.full_name}.\nPhone:+${user.full_phone_number} `}
                              subject={`No bank Accounts in my country ${user.country}.`}
                              onClick={() => setBulkPaymentModal()}
                            />
                          </div>
                        </div>
                      </>
                    ) : ( */}
            <>
              <div className="bacnkCheckBoxWrapper w-full p-0 m-0 p-2 mb-4">
                {selectedBankState.length > 0 &&
                  selectedBankState.map((bank: any, index: number) => {
                    return (
                      <div className="wrapperBankCheckBox innerBankBox m-0">
                        <div className="wrapperBankCheckBoxwrapper">
                          <input
                            type="checkbox"
                            className="default:ring-2"
                            onChange={(e: any) => {
                              setSelectedBank(dana_pay_bank_accounts[index]);
                              setselectedBankState((prev: any) =>
                                prev.map((itm: any, ind: any) => {
                                  if (ind == index) {
                                    return { ...itm, checked: true };
                                  } else {
                                    return { ...itm, checked: false };
                                  }
                                })
                              );
                            }}
                            name={bank.bank_name}
                            checked={bank.checked}
                          />
                          <span className="checkmark"></span>
                        </div>
                        <label>{bank.bank_name}</label>
                      </div>
                    );
                  })}
              </div>

              {/* <select
                  className={`border-b-2 rounded bg-white w-full mb-4`}
                  // className={`border-2 rounded bg-white w-full mb-4 my-4 p-2`}
                  onChange={(v: any) =>
                    setSelectedBank(dana_pay_bank_accounts[v.target.value])
                  }
                >
                  <option>{t("select_bank")}</option>
                  {dana_pay_bank_accounts.map((bank: any, index: number) => (
                    <option value={index} key={index}>
                      {bank.bank_name}
                    </option>
                  ))}
                </select> */}
              <button
                onClick={() => setActiveStep((prev) => prev + 1)}
                className="w-full rounded-md btn"
                // className="rounded-md btn"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                disabled={selectedBank === null}
              >
                <small className="mx-10 font-bold text-white">
                  {t("continue")}
                </small>
              </button>
            </>
            {/* )} */}
            {/* </div> */}
          </div>
        </div>
      );
    }
    if (activeStep === 3) {
      return (
        <div className="pt-20">
          <div className="h-full row">
            <div className="h-screen py-4 col-md-8 mx-auto">
              {wasBalance ? (
                <div className="flex flex-col items-center justify-center p-4 text-center bg-white shadow-lg">
                  <img
                    src="../images/checked.png"
                    style={{ height: 60, width: 60, margin: 20 }}
                  />
                  {lang === "en" ? (
                    <p>
                      We are sending{" "}
                      <b>
                        <CashLayout
                          // changed it as it was empty
                          // cash={
                          //   calculateTotalFromArray(
                          //     transfer.usersForBulkyTransfer
                          //   )?.total
                          // }
                          cash={balaceTransferForBulk}
                        />
                      </b>{" "}
                      {currency} <br />. Your beneficiaries will receive a
                      message with the necessary details for the withdrawal.
                    </p>
                  ) : (
                    <p>
                      Nous envoyons{" "}
                      <b>
                        <CashLayout
                          // changed it as it was empty
                          // cash={
                          //   calculateTotalFromArray(
                          //     transfer.usersForBulkyTransfer
                          //   )?.total
                          // }
                          cash={balaceTransferForBulk}
                        />
                      </b>
                      <br />
                      Vos bénéficiaires recevront un message avec les détails
                      nécessaires pour le retrait.
                    </p>
                  )}
                  <button
                    onClick={() => {
                      dispatch(openBulkTransferDialog("close"));
                      dispatch(updateBulkTData({ users: {} }));
                      history.push("/");
                    }}
                    className="my-4 btn"
                    style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  >
                    <small className="font-bold text-white ">
                      {t("BackHome")}
                    </small>
                  </button>
                </div>
              ) : (
                <div className="p-4 bg-white shadow-lg">
                  <BankDetails
                    accountDetails={selectedBank}
                    // used amount from response
                    // amount={
                    //   calculateTotalFromArray(transfer.usersForBulkyTransfer)!
                    //     .total + fees
                    // }
                    amount={balaceTransferForBulk}
                    fees={fees}
                    callback={() => {
                      window.heap.track("Bulk payment initiation", {
                        email: user.email,
                        country: user.country,
                        phone: user.full_phone_number,
                        name: user.full_name,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    getUserContacts();
    getBalance();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // works for fee only if in repeat
  // fee calculatioin
  const getSelectedCashInMethod = async () => {
    try {
      setFetchingFees(true);
      const selectedCashInMethodArray = getCashInMethodName(
        transfer,
        t(
          transfer?.bulkTransferData?.paymentMethod
            ?.toLowerCase()
            .split(" ")
            .join("_")
        ).toLowerCase() !== payMethod
          ? payMethod.replace("_", " ")
          : transfer?.bulkTransferData?.paymentMethod === "card"
          ? "bank card"
          : transfer?.bulkTransferData?.paymentMethod?.replace("_", " ")
      );
      if (!selectedCashInMethodArray) {
        return;
      }
      if (selectedCashInMethodArray.cash_in_method.name !== "") {
        const receiving =
          transfer.loggedInUserCountry?.receiving_countries?.find(
            (receiving_country_data: any) =>
              receiving_country_data?.receiving_country?.name?.toLowerCase() ===
              transfer?.bulkTransferData?.country?.toLowerCase()
          );
        if (!receiving) {
          setErrorGot(t("no_receiving_country"));
          setFetchingFees(false);
          return;
        }
        if (activeStep === 1) {
          interface IKeys {
            id: string;
            identification_number: number;
            first_name: string;
            last_name: string;
            email: string;
            phone_number: string;
            amount_without_fees: string;
          }

          const beneficiaryArray =
            fileCSV?.data === undefined
              ? fileCSV.map(
                  (val: any, index: any): IKeys => ({
                    id: val.id,
                    identification_number: index + 1,
                    first_name: val.first_name,
                    last_name: val.last_name,
                    email: val.email,
                    phone_number: val.phone_number,
                    amount_without_fees: val.amount_without_fees,
                  })
                )
              : fileCSV?.data.map(
                  (val: any, index: any): IKeys => ({
                    id: val.id,
                    identification_number: index + 1,
                    first_name: val.first_name,
                    last_name: val.last_name,
                    email: val.email,
                    phone_number: val.phone_number,
                    amount_without_fees: val.amount_without_fees,
                  })
                );

          const filterOperator = transfer.danaPayCountries.filter(
            (e: any) =>
              e.name.toLowerCase() ===
              transfer.bulkTransferData.country.toLowerCase()
          );

          calculateBulkTransferFees(
            {
              euro_amount:
                calculateSumFromArray(
                  fileCSV?.data === undefined ? fileCSV : fileCSV?.data
                )?.total.toString() ||
                calculateTotalFromArray(transfer.usersForBulkyTransfer)?.total,
              sending_country_id: transfer.loggedInUserCountry.id,
              receiving_country_id: receiving.receiving_country.id,
              cashin_method_id: selectedCashInMethodArray?.cash_in_method?.id,
              beneficiaries: beneficiaryArray,
              source_user_id: user?.id,
              country_code:
                filterOperator.length > 0 ? filterOperator[0].country_code : "",
              // reason: transfer?.bulkTransferData?.reason
              reason: "fee calculation for bulk transfer",
            },
            user?.id
          )
            .then((response: any) => {
              setErrorGot("");
              setFees(0);
              setFees(response.fee);
              setFetchingFees(false);

              const s_config = response.fee_calculation.find((value: any) =>
                value.hasOwnProperty("spread")
              );

              if (s_config) {
                setSpreadConfig(s_config);
              }
              // toast.success(response.message);
            })
            .catch((error: any) => {
              setFetchingFees(false);
              const err = extractError(error);
              setErrorGot(translateError(err, t));
              toast.error(extractError(error));
              Sentry.captureException(error);
            });
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // set balance as default method if balance greater than 0 and if its not repeat
  React.useEffect(() => {
    try {
      if (Object.keys(transfer?.usersForBulkyTransfer).length < 1) {
        if (
          !isNaN(user?.client?.euro_balance) &&
          Number(user?.client?.euro_balance) > 0
        ) {
          dispatch(resetPayment("balance"));
          setPayMethod("balance");
        } else {
          dispatch(resetPayment(""));
          setPayMethod("");
        }
      } else {
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [user, transfer?.usersForBulkyTransfer]);

  // fee calculation on change
  React.useEffect(() => {
    if (checkedCashMethod === true) {
      // for repeat
      // getSelectedCashInMethod();
    }
    getSelectedCashInMethod();
    // works only if not repeat
    // calculateFees();
    // update_selected(favorites);
  }, [amountEuro, payMethod, paymentMethod, activeStep]);

  React.useEffect(() => {
    const countries = getLoggedInUserReceivingCountries(transfer, user, true);
    if (countries.length > 0) {
      if (!transfer?.bulkTransferData?.country) {
        dispatch(updateBulkTData({ country: countries[0] }));
      }
    }
  }, []);

  return (
    <div>
      <div
        className="shadow-md"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <div className="container flex flex-row items-center justify-between py-2 text-white">
          <p className="font-bold">{t("Bulk_Payments")}</p>

          <button
            // onClick={() => setBulkPaymentModal()}
            id="bulk-payment-cancel"
            style={{ height: 40, width: 40, borderRadius: 20 }}
            className="flex items-center justify-center shadow-lg"
            onClick={() => setopenFailDialogLoss(true)}
          >
            <i className="m-0 fa fa-close"></i>
          </button>
        </div>
      </div>
      <div className="container">
        {size[0] < 900 ? null : (
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        {activeStep === steps.length ? (
          <div className="flex flex-col justify-center w-full p-10 m-auto md:w-1/2 ">
            <p>All steps completed</p>
          </div>
        ) : (
          <div className="mx-3 overflow-y-scroll">{getStepContent()}</div>
        )}
      </div>

      {/* csv upload modal */}
      <Dialog open={openDialog} onClose={() => handleClose}>
        <div className="p-10 col-md-12">
          <div className="flex items-center justify-center mb-6">
            <p className="font-bold text-center text-2xl">
              {t("upload_beneficiaries_details")}
            </p>
            {/* <button onClick={() => {}}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button> */}
          </div>
          {/* <input
              type="file"
              name="file_upload"
              className="hidden"
              accept=".csv"
              onChange={upload}
            /> */}
          <FileUpload
            onDrop={(acceptedFiles: any) => {
              upload(acceptedFiles);
            }}
            accept=".csv"
            multiple={false}
          >
            <label className="flex justify-center p-8 transition bg-gray-100 border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
              <span className="flex items-center space-x-2">
                <span className="font-medium text-gray-600">
                  <p className="font-light text-center mb-2">
                    {t("click_here_to_upload")}
                  </p>
                  <p className="font-light text-center text-slate-400">
                    {t("accepted_formats")}
                  </p>
                </span>
              </span>
            </label>
          </FileUpload>
          <div className="flex items-center justify-center mt-6 mb-6">
            <p className="font-regular text-center text-1xl text-gray-400 mx-2">
              {t("download_template")}
            </p>
            <Link to="/sample.csv" target={"_blank"} download="sample.csv">
              {/* <a href="/sample.csv" download='sample.csv' target={'_blank'}> */}
              <i
                className="fa fa-download text-7xl"
                aria-hidden="true"
                style={{
                  color: "rgb(3, 115, 117)",
                  fontSize: "26px",
                }}
              ></i>
            </Link>
            {/* </a> */}

            {/* </button> */}
          </div>
          {processingPayment === true ? (
            <div>
              <small
                className="font-bold text-center justify-center text-xl"
                style={{ display: "flex" }}
              >
                {uploadingCSV[0]?.name}
              </small>
              <small
                className="font-bold text-center justify-center text-xl"
                style={{ display: "flex" }}
              >
                {t("uploading")}
              </small>
            </div>
          ) : (
            uploadingCSV.length > 0 && (
              <div>
                <small
                  className="font-bold text-center justify-center text-xl"
                  style={{ display: "flex" }}
                >
                  {uploadingCSV[0]?.name}
                </small>
                <small
                  className="font-bold text-center justify-center text-xl"
                  style={{ display: "flex" }}
                >
                  {t("file_upload")}
                </small>
              </div>
            )
          )}
          <div className="flex flex-row justify-between my-3">
            <button
              onClick={() => {
                setopenDialog(false);
                setUploadingCSV([]);
                setProcessingPayment(false);
              }}
              className="mr-2 w-2/4 px-10 rounded-md btn py-2.5"
              style={{ backgroundColor: "#000000" }}
            >
              <small className="mx-2 font-bold text-white">{t("back")}</small>
            </button>
            <button
              onClick={() => {
                setopenDialog(false);
                showTransfer("");
              }}
              className="ml-2 w-2/4 px-10 rounded-md btn py-2.5"
              style={{ backgroundColor: "#037375" }}
            >
              <small className="mx-2 font-bold text-white">
                {t("confirm")}
              </small>
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={openFailDialogLoss} onClose={() => handleClose}>
        {openFailDialogLoss === true &&
          confirmAlert({
            title: t("Lose_Csv_Toast"),
            overlayClassName: "zIndexClass",
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t("ok"),
                onClick: () => {
                  setopenFailDialogLoss(false);
                  setBulkPaymentModal();
                  window.heap.track("Bulk transfer canceled", {
                    email: user.email,
                    country: user.country,
                    phone: user.full_phone_number,
                    name: user.full_name,
                  });
                },
              },
              {
                label: t("cancel"),
                onClick: () => {
                  setopenFailDialogLoss(false);
                },
              },
            ],
          })}
        {/* <div className="p-10 col-md-12"> */}
        {/* <div className="flex items-center justify-center mb-6"> */}
        {/* <p className="font-bold text-center text-2xl">
              {t("Lose_Csv_Toast")}
            </p> */}
        {/* <button onClick={() => {}}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button> */}
        {/* </div> */}
        {/* <div className="flex flex-row justify-between my-3">
            <button
              onClick={() => {
                setopenFailDialogLoss(false);
              }}
              className="mr-2 w-2/4 px-10 rounded-md btn py-2.5"
              style={{ backgroundColor: "#000000" }}
            >
              <small className="mx-2 font-bold text-white">{t("cancel")}</small>
            </button>
            <button
              onClick={() => {
                setopenFailDialogLoss(false);
                setBulkPaymentModal();
              }}
              className="ml-2 w-2/4 px-10 rounded-md btn py-2.5"
              style={{ backgroundColor: "#037375" }}
            >
              <small className="mx-2 font-bold text-white">
                {t("ok")}
              </small>
            </button>
          </div> */}
      </Dialog>
      <Dialog open={openFailDialog} onClose={() => handleClose}>
        <div className="p-10 col-md-12">
          <div className="flex items-center justify-center mb-6">
            <p className="font-bold text-center text-2xl">
              {t("upload_beneficiaries_details")}
            </p>
            {/* <button onClick={() => {}}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button> */}
          </div>
          <div className="justify-center align-center flex circle-icon">
            <img
              src="/images/cancle-close.png"
              alt="cancle-check-circle.png"
              width={90}
            />
          </div>
          <div className="flex items-center justify-center mt-6 mb-6">
            <p className="font-regular text-center text-1xl text-gray-400">
              {t("Csv_Format_Error")}
            </p>
          </div>
          <div className="flex items-center justify-center mt-6 mb-6">
            <p className="font-regular text-center text-1xl text-gray-400">
              {t("download_template")}
            </p>
            {/* <button className="ml-3" onClick={() => { }}> */}
            <Link to="/sample.csv" target="_blank" download>
              <i
                className="fa fa-download text-7xl"
                aria-hidden="true"
                style={{
                  color: "rgb(3, 115, 117)",
                  fontSize: "26px",
                }}
              ></i>
            </Link>

            {/* </button> */}
          </div>
          <div className="flex flex-row justify-between my-3">
            <button
              onClick={() => {
                setopenDialog(true);
                setopenFailDialog(false);
              }}
              className="mr-2 w-2/4 px-10 rounded-md btn py-2.5"
              style={{ backgroundColor: "#000000" }}
            >
              <small className="mx-2 font-bold text-white">{t("retry")}</small>
            </button>
            <button
              onClick={() => {
                setopenFailDialog(false);
              }}
              className="ml-2 w-2/4 px-10 rounded-md btn py-2.5"
              style={{ backgroundColor: "#037375" }}
            >
              <small className="mx-2 font-bold text-white">
                {t("continue")}
              </small>
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={openSucessDialog} onClose={() => handleClose}>
        <div
          className=" flex-col flex p-10 col-md-12 justify-center success-box-model"
          style={{ alignItems: "center" }}
        >
          <div className="flex items-center justify-center mb-6">
            <p className="font-bold text-center text-2xl">
              {t("upload_beneficiaries_details")}
            </p>
          </div>
          {/* <i className="text-4xl fa fa-solid fa-circle-check bg-green-600"></i> */}
          <img
            src="/images/sucess-check-circle.png"
            alt="sucess-check-circle.png"
            width={90}
          />
          {/* <i className="fa text-4xl bg-green-600  fa-circle-check"></i> */}
          <p className="font-bold text-center my-2">
            {`${
              dataSucessCSV?.data === undefined
                ? dataSucessCSV.length
                : dataSucessCSV?.data?.length
            }`}{" "}
            {t("Upload_Rows")}
          </p>
          {/* <div className="flex flex-row justify-between mt-3"> */}
          <button
            onClick={() => {
              setopenSucessDialog(false);
              setFileCSV(dataSucessCSV);
              setshowTableCSV(true);
            }}
            className="ml-2 mt-4 w-2/4 px-10 rounded-md btn py-2.5 w-100"
            style={{ backgroundColor: "#037375" }}
          >
            <small
              className="mx-2 font-bold text-white"
              // onClick={() => toast.error(t("Upload_Csv_Toast"))}
            >
              {t("continue")}
            </small>
          </button>
          {/* </div> */}
        </div>
      </Dialog>
    </div>
  );
};

export default BulkPayment;
