import { Form, Formik } from "formik";
import React from "react";
import CountryMultiSelect from "../CountryMultiSelect";
import FormErrorText from "../FormErrorText";
import * as Sentry from "@sentry/browser";
import {
  addCompanyToState,
  editUser,
  setSignUpData,
} from "../../store/features/Auth/AuthSlice";
import {
  addCompany,
  addUser,
  editCompany,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import { extractErrorMessage } from "../../helpers";

const CompanyEnvisionedCountries = (props: any) => {
  const {
    t,
    companySendingEnvisionedCountries,
    setcompanyReceivingEnvisionedCountries,
    setcompanySendingEnvisionedCountries,
    companyReceivingEnvisionedCountries,
    countryListing,
    dispatch,
    Yup,
    signUpData,
    setprogressBarData,
    setActiveStep,
    setErrorsState,
    toast,
    country,
    user,
  } = props;

  return (
    <div className="p-8 rounded-lg shadow-lg">
      <p className="mb-3 font-bold text-center">
        {t("company_envisioned_countries")}
      </p>
      <Formik
        validateOnMount
        enableReinitialize
        key="companyenvisionedcountries"
        initialValues={{
          companySendingEnvisionedCountries:
            signUpData?.companySendingEnvisionedCountries ||
            user?.progression?.companySendingEnvisionedCountries ||
            companySendingEnvisionedCountries,
          companyReceivingEnvisionedCountries:
            signUpData?.companyReceivingEnvisionedCountries ||
            user?.progression?.companyReceivingEnvisionedCountries ||
            companyReceivingEnvisionedCountries,
        }}
        onSubmit={(data, { setSubmitting }) => {
          try {
            const receivingIds = countryListing
              .filter((country: any) =>
                data.companyReceivingEnvisionedCountries.includes(country.name)
              )
              .map((country: any) => country.id);
            const sendingIds = countryListing
              .filter((country: any) =>
                data.companySendingEnvisionedCountries.includes(country.name)
              )
              .map((country: any) => country.id);

            dispatch(
              setSignUpData({
                ...signUpData,
                // receiving_countries_ids: [...receivingIds, ...sendingIds],
                registered_id: `${new Date().getTime()}`,
                receiving_countries_ids: receivingIds,
                sending_countries_ids: sendingIds,
                ...data,
              })
            );
            saveRegistrationProgress({
              ...signUpData,
              // receiving_countries_ids: [...receivingIds, ...sendingIds],
              receiving_countries_ids: receivingIds,
              sending_countries_ids: sendingIds,
              ...data,
              registered_id: `${new Date().getTime()}`,
            });

            // xxxx

            setSubmitting(true);
            setErrorsState([]);
            try {
              if (
                user?.company !== null &&
                signUpData?.company !== undefined &&
                signUpData?.company !== null
              ) {
                const companyPayload = {
                  activity_id: signUpData.activity_id,
                  is_sole_proprietorship: signUpData.is_sole_proprietorship,
                  revenue_level_id: signUpData.revenue_level_id,
                  receiving_countries_ids: [...receivingIds, ...sendingIds],
                  registered_id: `${new Date().getTime()}`,
                  country_code:
                    parseInt(signUpData?.country_code) || parseInt(country),
                };
                //edit the company instead...
                editCompany(companyPayload, user?.company.id)
                  .then((res: any) => {
                    dispatch(addCompanyToState(res.company));
                    setprogressBarData({
                      progress: 100,
                      title: "company_verification",
                    });
                    setActiveStep(4);
                    dispatch(
                      setSignUpData({
                        ...signUpData,
                        company: res.company,
                      })
                    );
                  })
                  .catch((error: any) => {
                    toast.warning(t(error?.data?.message));
                    Sentry.captureException(error);
                    const err = extractErrorMessage(error);
                    if (err) {
                      setErrorsState((prevState: any[]) => [
                        ...prevState,
                        ...err,
                      ]);
                    }
                  })
                  .finally(() => setSubmitting(false));
              } else {
                let payload = {
                  ...signUpData,
                  // receiving_countries_ids: receivingIds,
                  // sending_countries_ids: sendingIds,
                };
                delete payload.address_line;
                delete payload.city;
                delete payload.companyReceivingEnvisionedCountries;
                delete payload.companySendingEnvisionedCountries;
                delete payload.is_sole_proprietorship;
                delete payload.company;
                delete payload.residency;
                delete payload.step;
                delete payload.revenue_level_id;
                addUser(payload, "business")
                  .then((res: any) => {
                    dispatch(editUser(res?.user));

                    const companyPayload = {
                      activity_id: signUpData.activity_id,
                      revenue_level_id: signUpData.revenue_level_id,
                      receiving_countries_ids: [...receivingIds, ...sendingIds],
                      registered_id: `${new Date().getTime()}`,
                      country_code: parseInt(country),
                      is_sole_proprietorship: signUpData.is_sole_proprietorship,
                    };

                    addCompany(companyPayload)
                      .then((res: any) => {
                        dispatch(addCompanyToState(res.company));
                        dispatch(
                          setSignUpData({
                            ...signUpData,
                            company: res.company,
                          })
                        );

                        setprogressBarData({
                          progress: 100,
                          title: "company_verification",
                        });
                        setActiveStep(4);
                      })
                      .catch((error: any) => {
                        toast.warning(t(error?.data?.message));
                        Sentry.captureException(error);
                        const err = extractErrorMessage(error);
                        if (err) {
                          setErrorsState((prevState: any[]) => [
                            ...prevState,
                            ...err,
                          ]);
                        }
                      })
                      .finally(() => setSubmitting(false));
                  })
                  .catch((error: any) => {
                    toast.warning(t(error?.data?.message));
                    Sentry.captureException(error);
                    setSubmitting(false);
                  });
              }
            } catch (error: any) {
              Sentry.captureException(error);
              setSubmitting(false);
            }
            // xxxxx
          } catch (error: any) {
            Sentry.captureException(error);
          }
        }}
        validationSchema={Yup.object().shape({
          companySendingEnvisionedCountries: Yup.array()
            .min(1, "transfer_countries_required")
            .required("required"),
          companyReceivingEnvisionedCountries: Yup.array()
            .min(1, "transfer_countries_required")
            .required("required"),
        })}
      >
        {({ errors, setFieldValue, isSubmitting, isValid, values }) => (
          <Form className="flex flex-col gap-3 company-registration">
            <div className="flex flex-col">
              <small className="mb-3 text-gray-700">
                <b>{t("sending_countries_1")}</b>
                {t("sending_countries_2")}
              </small>
              <CountryMultiSelect
                selectedValues={
                  values.companySendingEnvisionedCountries ||
                  companySendingEnvisionedCountries
                }
                placeholder={t("company_receiving_countries_placeholder")}
                list={countryListing}
                onChange={(e: any) => {
                  setcompanySendingEnvisionedCountries(e);
                  setFieldValue("companySendingEnvisionedCountries", e);
                }}
                customClasses="rounded-[12px] wrapperBankCheckBox innerBankBox bacnkCheckBoxWrapper wrapperBankCheckBox m-0"
              />
              {errors?.companySendingEnvisionedCountries && (
                <FormErrorText
                  errorMessage={errors?.companySendingEnvisionedCountries}
                />
              )}
            </div>
            <div className="flex flex-col">
              <small className="mb-3 text-gray-700">
                <b>{t("company_receiving_countries_1")}</b>
                {t("company_receiving_countries_2")}
              </small>
              <CountryMultiSelect
                selectedValues={
                  values.companyReceivingEnvisionedCountries ||
                  companyReceivingEnvisionedCountries
                }
                placeholder={t("company_receiving_countries_placeholder")}
                list={countryListing}
                onChange={(e: any) => {
                  setcompanyReceivingEnvisionedCountries(e);
                  setFieldValue("companyReceivingEnvisionedCountries", e);
                }}
                customClasses="rounded-[12px] wrapperBankCheckBox innerBankBox bacnkCheckBoxWrapper wrapperBankCheckBox m-0"
              />
              {errors?.companyReceivingEnvisionedCountries && (
                <FormErrorText
                  errorMessage={errors?.companyReceivingEnvisionedCountries}
                />
              )}
            </div>

            <div className="flex justify-between gap-3">
              <button
                className="flex-1 rounded-lg btn btn-block"
                style={{ backgroundColor: "#666" }}
                onClick={() => {
                  setprogressBarData({
                    progress: 50,
                    title: "company_activity_revenuelevel",
                  });
                  setActiveStep(2);
                }}
              >
                <span className="text-white capitalize md:text-md max-sm:!text-sm">
                  {t("back")}
                </span>
              </button>

              <button
                className="flex-1 rounded-lg btn btn-block text-white capitalize md:text-md max-sm:!text-sm text-[12.6px]"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                disabled={isSubmitting || !isValid}
                type="submit"
                data-individual="individual"
              >
                {t("next")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyEnvisionedCountries;
