import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { setSignUpData } from "../../store/features/Auth/AuthSlice";
import InputField from "../forms/InputField";
import CountryPicker from "../CountryPicker";
import * as Sentry from "@sentry/browser";

import FormErrorText from "../FormErrorText";
import { Alert } from "@material-ui/lab";
import { buildString } from "../../utilities/help";
import { saveRegistrationProgress } from "../../store/features/Auth/Auth";

const PersonalInfoScreen = (props: any) => {
  const {
    t,
    user,
    signUpData,
    phone,
    country,
    setErrorsState,
    typeOfUser,
    dispatch,
    setActiveStep,
    setProcessing,
    uservalue,
    Yup,
    setprogressBarData,
    setCountry,
    setIsIndividualUser,
    setisUserComingBack,
    setCountryName,
    setPhone,
    errorsState,
    setSelectedCountryReset,
    handleBack,
    processing,
  } = props;

  // contact/personal information schema
  const validationSchema = useMemo(() => {
    if (uservalue == "individual") {
      return Yup.object().shape({
        lastname: Yup.string().required("LNR"),
        firstname: Yup.string().required("FNR"),
        phone_number: Yup.string()
          .min(8, "the_phone_number_must_be_at_least_8_characters")
          .max(12, "the_phone_number_may_not_be_greater_than_12_characters")
          .required("PhoneNumberError"),
        country_code: Yup.string().required("CE"),
        city: Yup.string().required("CIE"),
        address_information: Yup.string().required("AIE"),
      });
    } else {
      return Yup.object().shape({
        lastname: Yup.string().required("LNR"),
        firstname: Yup.string().required("FNR"),
        phone_number: Yup.string()
          .min(8, "the_phone_number_must_be_at_least_8_characters")
          .max(12, "the_phone_number_may_not_be_greater_than_12_characters")
          .required("PhoneNumberError"),
        country_code: Yup.string().required("CE"),
      });
    }
  }, [uservalue]);

  const handleHeapAndProgressBar = (usertype: any, json: any) => {
    // heap events
    if (usertype.value === "individual") {
      setprogressBarData({
        progress: 40,
        title: "revenue_information",
      });
      window.heap.track("individual identity information set", {
        ...json,
      });
      window.heap.track("Has logged in as an individual", {
        email: user?.email,
      });
    } else {
      setprogressBarData({
        progress: 50,
        title: "Company_Information",
      });
      window.heap.track("company identity information set", {
        ...json,
      });
      window.heap.track("Has logged in as a company", {
        email: user?.email,
      });
    }
  };

  const [isEnterpreneurEntity, setisEnterpreneurEntity] = useState([
    { label: "yes", selected: false, value: true },
    { label: "no", selected: false, value: false },
  ]);

  return (
    <div className="flex justify-center pb-5 my-5">
      <div className="p-8 w-full bg-white rounded-lg shadow-lg">
        <h2 className="mb-6 font-bold text-center text-1xl">
          {t("enterPerson")}
        </h2>
        <Formik
          validateOnMount
          validateOnChange
          key="form1"
          initialValues={{
            lastname: user?.last_name || signUpData?.last_name || "",
            firstname: user?.first_name || signUpData?.first_name || "",
            phone_number: signUpData?.phone_number || user?.phone_number || "",
            country_code:
              signUpData?.country_code ||
              (user?.country_code && "+" + user?.country_code) ||
              "",
            address_information:
              signUpData?.address_line ||
              (uservalue == "individual" &&
                (user?.address_line || user?.progression?.address_line))
                ? user?.address_line || user?.progression?.address_line
                : "" || "",
            city:
              signUpData?.city ||
              (uservalue == "individual" &&
                (user?.city || user?.progression?.city))
                ? user?.city || user?.progression?.city
                : "" || "",
          }}
          onSubmit={(data: any, { setSubmitting }) => {
            try {
              setSubmitting(true);
              setErrorsState([]);
              const json = {
                email: user?.email,
                phone_number: data.phone_number || phone,
                country_code: parseInt(data.country_code) || parseInt(country),
                first_name: data.firstname,
                last_name: data.lastname,
                address_line: data.address_information,
                city: data?.city,
              };
              const usertype: any = typeOfUser.find((itm: any) => itm.active);

              setIsIndividualUser(usertype.value === "individual");

              setisUserComingBack(false);

              // add company to user profile if it exists
              // if (user?.company !== undefined && user?.company !== null) {
              if (uservalue !== "individual") {
                let payload: any = { ...json };
                delete payload.address_line;
                delete payload.city;

                if (user?.company !== undefined && user?.company !== null) {
                  payload.company = user?.company;
                }
                dispatch(setSignUpData({ ...signUpData, ...payload }));
                saveRegistrationProgress({
                  ...signUpData,
                  ...payload,
                  usertype: "business",
                  step: 1,
                }).finally(() => {
                  setSubmitting(false);
                  setActiveStep(2);
                  handleHeapAndProgressBar(usertype, json);
                });
              } else {
                let payload: any = { ...json };
                delete payload.is_sole_proprietorship;
                dispatch(
                  setSignUpData({
                    ...signUpData,
                    ...payload,
                    usertype: "individual",
                  })
                );
                saveRegistrationProgress({
                  ...signUpData,
                  ...payload,
                  usertype: "individual",
                  step: 1,
                }).finally(() => {
                  setSubmitting(false);
                  setActiveStep(2);
                  handleHeapAndProgressBar(usertype, payload);
                });
              }
            } catch (error: any) {
              setSubmitting(false);
              setProcessing(false);
              Sentry.captureException(error);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => (
            <Form className="individual-registration">
              <InputField
                extraClasses="mb-3 customInputStyle"
                name="lastname"
                handleChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("LastName")}
                value={values.lastname}
                label={t("LastName")}
                error={isSubmitting ? "" : t(`${errors.lastname}`)}
                touched={touched.lastname}
              />
              <InputField
                extraClasses="mb-3 customInputStyle"
                name="firstname"
                handleChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("FirstName")}
                value={values.firstname}
                label={t("FirstName")}
                error={isSubmitting ? "" : t(`${errors.firstname}`)}
                touched={touched.firstname}
              />

              {uservalue == "individual" && (
                <InputField
                  extraClasses="mb-3 customInputStyle"
                  name="address_information"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t("AddressInformation")}
                  value={values.address_information}
                  label={t("AddressInformation")}
                  error={isSubmitting ? "" : t(`${errors.address_information}`)}
                  touched={touched.address_information}
                />
              )}

              {/* country city selection */}

              {uservalue == "individual" && (
                <div className="flex flex-col flex-1">
                  <InputField
                    extraClasses="mb-3 customInputStyle"
                    name="city"
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t("City")}
                    value={values.city}
                    label={t("City")}
                    error={isSubmitting ? "" : t(`${errors.city}`)}
                    touched={touched.city}
                  />
                </div>
              )}

              <div className="mt-6 mb-6">
                <small>
                  {t("is_number")}{" "}
                  <b>
                    {country}
                    {phone}
                  </b>
                </small>
                <br />
                <CountryPicker
                  selected_mobile_code={values?.country_code}
                  setCountry={(text: any) => {
                    setCountry(text.mobileCode);
                    setCountryName(text.name);
                    handleChange("country_code")(text.mobileCode);
                  }}
                  cash_Out_Method_Name={""}
                  country={user?.country}
                  onChange={(text: string) => {
                    setPhone(text);
                    handleChange("phone_number")(text);
                  }}
                  value={values?.phone_number || phone}
                  withPhoneInput={true}
                  country_selected={true}
                  setSelectedCountry={(e: any) => setSelectedCountryReset(e)}
                />
                {errors.phone_number && (
                  <FormErrorText
                    errorMessage={isSubmitting ? "" : errors.phone_number}
                  />
                )}
                {errors.country_code && (
                  <FormErrorText
                    errorMessage={isSubmitting ? "" : errors.country_code}
                  />
                )}
              </div>

              <div>
                {errorsState.length > 0 && (
                  <div className="mb-4">
                    <Alert severity="error">
                      {errorsState.map((errorText: string, index: number) => (
                        <p>
                          <small key={index}>
                            {t(buildString(errorText?.toLowerCase()) || "")}
                          </small>
                        </p>
                      ))}
                    </Alert>
                  </div>
                )}
              </div>

              <div className="flex mt-4 justify-between gap-3">
                <button
                  className="flex-1 rounded-lg btn"
                  style={{ backgroundColor: "#666" }}
                  onClick={handleBack}
                >
                  <span className="text-white capitalize md:text-md max-sm:!text-sm">
                    {t("back")}
                  </span>
                </button>

                <button
                  className="flex-1 rounded-lg btn"
                  style={{ backgroundColor: "rgb(3, 115, 117)" }}
                  disabled={processing || !isValid || isSubmitting}
                  type="submit"
                >
                  <span className="text-white capitalize md:text-md max-sm:!text-sm">
                    {t("next")}
                  </span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalInfoScreen;
