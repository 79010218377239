import React from "react";
import { CircularProgress } from "@material-ui/core";
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert"; // Import
import * as Sentry from "@sentry/browser";
import {
  delete_uploaded_files,
  download_file,
} from "../../store/features/Auth/Auth";
import { performPDFDownload } from "../../utilities/help";
import { toast } from "material-react-toastify";
import { useTranslation } from "react-i18next";

const DocumentItem: React.FC<any> = ({
  row,
  index,
  transfer_id,
  getOperationMetaData,
  isDisabled,
}) => {
  const [deleting, setDeleting] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const { t } = useTranslation();

  const deleteFile = (row: any) => {
    if (isDisabled !== undefined && isDisabled) {
      return;
    }
    confirmAlert({
      title: t("deleting_head_text"),
      message: t("deleting_body_text"),
      overlayClassName: "zIndexClass",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            setDeleting(true);
            delete_uploaded_files(transfer_id, row.id)
              .then((response: any) => {
                setDeleting(false);
                toast.success("Document was deleted successfully");
                getOperationMetaData();
              })
              .catch((error: any) => {
                // toast.error(error.data.message);
                setDeleting(false);
                toast.warning(t(error?.data?.message));
                Sentry.captureException(error);
              });
          },
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const get_document = async (row: any) => {
    try {
      setDownloading(true);
      const result = await download_file(row.id);
      performPDFDownload(result, row.id);
      toast.success("Download is complete");
      setDownloading(false);
    } catch (error: any) {
      toast.error(error?.data?.message || error.message);
      setDownloading(false);
    }
  };

  return (
    <TableRow className="cursor-pointer hover:bg-gray-100" key={index}>
      <TableCell>
        <small>Transfer receipt </small>
      </TableCell>
      <TableCell>
        <small>{row.name}</small>
      </TableCell>
      <TableCell>
        {downloading ? (
          <CircularProgress size={14} />
        ) : (
          <small className="cursor-pointer" onClick={() => get_document(row)}>
            <i className="fa fa-download"></i>
          </small>
        )}
        {deleting ? (
          <CircularProgress size={14} />
        ) : !row.is_uploaded_by_admin ? (
          <small
            className="ml-3 cursor-pointer"
            onClick={() => deleteFile(row)}
          >
            <i
              className={`text-red-400 fa fa-trash${
                isDisabled !== undefined && isDisabled
                  ? " cursor-not-allowed opacity-50"
                  : ""
              }`}
            ></i>
          </small>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default DocumentItem;
