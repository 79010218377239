import { Form, Formik } from "formik";
import {
  addUser,
  detachCompany,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import FormErrorText from "../FormErrorText";
import * as Sentry from "@sentry/browser";
import { useEffect, useMemo, useState } from "react";
import { isEmpty } from "../../utilities/help";
import { editUser } from "../../store/features/Auth/AuthSlice";

const ResidencySelection = (props: any) => {
  const {
    t,
    toast,
    residencySelection,
    typeOfUser,
    user,
    signUpData,
    setActiveStep,
    setprogressBarData,
    Yup,
    activeStep,
    setresidencySelection,
    setresidencyLevelName,
    dispatch,
  } = props;

  const userFromAfrica = useMemo(() => {
    return [2, "2"].includes(signUpData?.country_code.toString().charAt(0));
  }, [signUpData]);

  const residencyListData = [
    {
      id: 1,
      name: "residency_option_1",
      val: "Non EU Person",
      description: "non_eu_description",

      selected: false,
    },
    {
      id: 2,
      name: "residency_option_2",
      val: "EU Person",
      description: "eu_description",
      selected: false,
    },
  ];
  const [residencyList, setresidencyList] = useState(residencyListData);
  useEffect(() => {
    if (!isEmpty(residencySelection)) {
      if (
        (userFromAfrica && residencySelection !== "WAEMU/CEMAC Person") ||
        (!userFromAfrica && residencySelection == "WAEMU/CEMAC Person")
      ) {
        setisDisabled(true);
      } else {
        setisDisabled(false);
      }

      setresidencyList((prev: any) =>
        prev.map((residency: any) =>
          residency.val == residencySelection
            ? { ...residency, selected: true }
            : { ...residency, selected: false }
        )
      );
    }
  }, [residencySelection, userFromAfrica]);

  const handleResidencyChange = (index: any, checked: any) => {
    if (checked) {
      setresidencyList((prev: any) =>
        prev.map((residency: any, residencyIndex: any) =>
          residencyIndex == index
            ? { ...residency, selected: true }
            : { ...residency, selected: false }
        )
      );
    }
  };

  const [isDisabled, setisDisabled] = useState(false);

  const handleSubmit = (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const usertype: any = typeOfUser.find((itm: any) => itm.active);
      let payload = { ...signUpData, residency: data.residency };
      delete payload.customJob;
      delete payload.company;
      delete payload.is_sole_proprietorship;

      saveRegistrationProgress(payload).then(() => {
        // if company is attached, remove it
        if (
          (user?.company !== null && user?.company !== undefined) ||
          (signUpData?.company !== null && signUpData?.company !== undefined)
        ) {
          detachCompany()
            .then(() => {
              addUser(payload, usertype.value)
                .then((res: any) => {
                  dispatch(editUser(res?.user));
                  setActiveStep(4);
                  setprogressBarData({
                    progress: 100,
                    title: "documents_and_selfie",
                  });
                  setresidencySelection(data.residency);
                })
                .catch((error: any) => {
                  toast.warning(t(error?.data?.message));
                  Sentry.captureException(error);
                })
                .finally(() => setSubmitting(false));
            })
            .catch((error: any) => {
              toast.warning(t(error?.data?.message));
              Sentry.captureException(error);
            });
        } else {
          addUser(payload, usertype.value)
            .then((res: any) => {
              dispatch(editUser(res?.user));
              setActiveStep(4);
              setprogressBarData({
                progress: 100,
                title: "documents_and_selfie",
              });
              setresidencySelection(data.residency);
            })
            .catch((error: any) => {
              toast.warning(t(error?.data?.message));
              Sentry.captureException(error);
            })
            .finally(() => setSubmitting(false));
        }
      });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="p-8 rounded-lg shadow-lg">
      <p className="mb-3 font-bold text-center">{t("residency_selection")}</p>
      <Formik
        validateOnMount
        enableReinitialize
        key="residenceSelection"
        initialValues={{
          residency: residencySelection || "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          handleSubmit(data, setSubmitting);
        }}
        validationSchema={Yup.object().shape({
          residency: Yup.string().required("residency_required"),
        })}
      >
        {({ errors, isSubmitting, handleChange, isValid, values }) => (
          <Form className="flex flex-col gap-3 company-registration">
            <div className="flex flex-col">
              <small className="text-gray-700">{t("residency_required")}</small>

              {/* newui */}
              <div className="flex flex-col gap-2 p-2 bacnkCheckBoxWrapper">
                {residencyList.map((residency: any, index: any) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="m-0 wrapperBankCheckBox innerBankBox"
                      >
                        <div className="wrapperBankCheckBoxwrapper min-w-fit">
                          <input
                            type="checkbox"
                            className="default:ring-2"
                            name={t(residency.name)}
                            onChange={(e) => {
                              if (
                                (userFromAfrica && index == 2) ||
                                (!userFromAfrica && index !== 2)
                              ) {
                                setisDisabled(false);
                              } else {
                                setisDisabled(true);
                              }

                              handleChange("residency")(residency.val);
                              handleResidencyChange(index, e.target.checked);
                              setresidencyLevelName(residency.val);
                              // }
                            }}
                            checked={residency.selected}
                          />
                          <span className="checkmark"></span>
                        </div>
                        <label
                          className={`md:text-md max-sm:!text-sm !text-[${
                            userFromAfrica && index == 2
                              ? "#4c4c4c"
                              : !userFromAfrica && index != 2
                              ? "#4c4c4c"
                              : "#8b8b8b"
                          }]`}
                        >
                          {t(residency.name)}
                        </label>
                      </div>

                      {residency.selected && (
                        <small className="innerBoxDesc mb-2 innerBankBox">
                          {t(residency.description)}
                        </small>
                      )}
                      {residency.selected && !userFromAfrica && index == 2 && (
                        <FormErrorText
                          classNames="innerBoxDesc mb-2 innerBankBox"
                          errorMessage={"umo_description_error"}
                        />
                      )}
                    </>
                  );
                })}
              </div>

              {errors?.residency && (
                <FormErrorText errorMessage={errors?.residency} />
              )}
            </div>

            <div className="flex justify-between gap-3">
              <button
                className="flex-1 rounded-lg btn btn-block"
                style={{ backgroundColor: "#666" }}
                disabled={activeStep === 0}
                onClick={() => {
                  setActiveStep("individualTransferInfo");
                  setprogressBarData({
                    progress: 60,
                    title: "receiving_countries",
                  });
                }}
              >
                <span className="text-white capitalize md:text-md max-sm:!text-sm">
                  {t("back")}
                </span>
              </button>

              <button
                id="proceedtosumsub"
                className="flex-1 rounded-lg btn btn-block"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                disabled={isSubmitting || !isValid || isDisabled}
                type="submit"
                data-individual="individual"
              >
                <span
                  className="text-white capitalize md:text-md max-sm:!text-sm"
                  data-individual="individual"
                >
                  {t("next")}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResidencySelection;
