import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import {
  addBeneficiary,
  openBulkTransferDialog,
  openDeposit,
  setBeneFound,
  setStep,
  setTransferDialogOpen,
  updateBulkTData,
} from "../../store/features/Transfer/TransferSlice";
import { cancelTransfer } from "../../store/features/Transfer/Transfer";
import { resetAuth } from "../../store/features/Auth/AuthSlice";
import { clearLocalStorageValues } from "../../utilities/help";
import { toast } from "material-react-toastify";

const steps = ["beneficiary", "Amount", "payment", "Confirmation"];

const PayLine = () => {
  const { status }: any = useParams(); // cancelled | successful
  const history = useHistory();
  const [cancelling, setCancelling] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // withdraw method, and other data
  const withdrawMethod = window.localStorage.getItem("withdrawMethod");
  const mfiAccountNumber = window.localStorage.getItem("mfiAccountNumber");
  const iban = window.localStorage.getItem("iban");
  const reciepentMobile = window.localStorage.getItem("reciepentMobile");
  const fullPhoneNnumber = window.localStorage.getItem("full_phone_number");
  const email = window.localStorage.getItem("email");

  const close = () => {
    clearLocalStorageValues([
      "appref",
      "withdrawMethod",
      "full_phone_number",
      "mfiAccountNumber",
      "iban",
      "reciepentMobile",
      "email",
    ]);
    const local_payment = localStorage.getItem("payment_details");
    const payment_details = local_payment ? JSON.parse(local_payment) : null;
    const ext_pay_id = localStorage.getItem("payment_id");
    const ext_link = localStorage.getItem("ext_mm_process_link");
    if (ext_pay_id || ext_link || payment_details) {
      localStorage.removeItem("payment_id");
      localStorage.removeItem("ext_mm_process_link");
      localStorage.removeItem("payment_details");
      localStorage.removeItem("user:key");
      dispatch(resetAuth());
      window.location?.replace(
        `${payment_details?.data?.redirect_url}?payment_id=${payment_details.id}`
      );
    } else {
      dispatch(setBeneFound(false));
      dispatch(addBeneficiary(null));
      dispatch(setStep(0));
      dispatch(setTransferDialogOpen(false));
      dispatch(openBulkTransferDialog("close"));
      dispatch(updateBulkTData({ users: {} }));
      dispatch(openDeposit(false));
      setCancelling(false);
      history.push("/");
    }
  };

  const cancel_payment = () => {
    // call the cancel end point ....
    const online_payment_id: any = localStorage.getItem("online_payment_id");
    setCancelling(true);
    cancelTransfer(online_payment_id)
      .then((res) => {
        localStorage.removeItem("online_payment_id");
        close();
      })
      .catch((error: any) => {
        setCancelling(false);
        toast.warning(error?.data?.message);
        Sentry.captureException(error);
      });
  };

  return (
    <div>
      <div
        className="flex flex-row items-center justify-between px-4 py-3"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <p className="font-bold text-white">{t("payment")}</p>
        <a onClick={close} className="font-bold text-white cursor-pointer">
          Close
        </a>
      </div>
      {/* <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}

      <div className="flex flex-1 md:my-20 my-10 justify-center row">
        <div
          className={`flex max-md:flex-col relative items-center gap-3 h-min justify-center p-10 bg-white rounded-lg shadow-lg col-md-8 col-lg-6 col-10 ${
            status !== "successful" && "max-w-[500px]"
          }`}
        >
          <div className="flex mobileicon items-center justify-center">
            <div
              className={`hidden max-md:flex mobileicon ${
                status === "successful" ? "bg-green-600" : "bg-red-600"
              }`}
              style={{
                width: "50px",
                height: "50px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                color: "#ffffff",
                fontSize: "100px",
              }}
            >
              {status === "successful" ? (
                <i
                  style={{ fontSize: 20, margin: 0 }}
                  className="text-4xl fa fa-check"
                ></i>
              ) : (
                <i
                  style={{ fontSize: 20, margin: 0 }}
                  className="text-4xl fa fa-close"
                ></i>
              )}
            </div>
          </div>

          {/* messages */}
          <div className="flex w-full md:ml-20 max-md:items-center flex-col gap-4">
            <div className="maininfo flex gap-3 items-center">
              <div
                className={`absolute left-10 flex max-md:hidden ${
                  status === "successful" ? "bg-green-600" : "bg-red-600"
                }`}
                style={{
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  color: "#ffffff",
                  fontSize: "100px",
                }}
              >
                {status === "successful" ? (
                  <i
                    style={{ fontSize: 20, margin: 0 }}
                    className="text-4xl fa fa-check"
                  ></i>
                ) : (
                  <i
                    style={{ fontSize: 20, margin: 0 }}
                    className="text-4xl fa fa-close"
                  ></i>
                )}
              </div>
              <p className="py-2 sm:text-xl text-left max-md:text-center font-bold">
                {status === "successful" && t("payline_main_info")}
                {status === "cancelled" && t("payline_cancelled")}
                {status !== "cancelled" &&
                  status !== "successful" &&
                  t("bridgeapi_failed")}
              </p>
            </div>

            <p className="py-2 md:text-base text-sm text-left max-md:text-center  text-gray-600">
              {status === "cancelled" && t("payline_cancelled_text")}
              {status !== "cancelled" &&
                status !== "successful" &&
                t("bridgeapi_failed_text")}
              {withdrawMethod == "Mobile Money" && status === "successful" && (
                <>
                  {t("payline_mobileMoney_info_1")}
                  &nbsp;
                  <b className="underline">{reciepentMobile}</b>
                  &nbsp;
                  {t("payline_mobileMoney_info_2")}
                </>
              )}
              {withdrawMethod == "Bank Account" && status === "successful" && (
                <>
                  {t("payline_bankAccount_info_1")}
                  &nbsp;
                  <b className="underline">{iban}</b>
                  &nbsp;
                  {t("payline_bankAccount_info_2")}
                </>
              )}
              {withdrawMethod == "Microfinance" && status === "successful" && (
                <>
                  {t("payline_microfinance_info_1")}
                  &nbsp;
                  <b className="underline">{mfiAccountNumber}</b>
                  &nbsp;
                  {t("payline_microfinance_info_2")}
                </>
              )}
            </p>

            {(withdrawMethod == null || withdrawMethod == undefined) &&
              status === "successful" && (
                <p className="md:text-base text-sm">
                  {t("danapay_bank_transfer_main_info_1")}
                  &nbsp;
                  <b className="underline">{fullPhoneNnumber}</b>
                  &nbsp;
                  {t("danapay_bank_transfer_main_info_2")}
                  &nbsp;
                  <b className="underline">{email}</b>
                  &nbsp;
                  <br />
                  <div className="">
                    <p className="my-2 font-bold">
                      {t("danapay_bank_transfer_instruction")}
                    </p>
                    <ul className="list-disc">
                      <li
                        className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                        onClick={() =>
                          window.open(
                            "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215906-acceder-a-son-compte-danapay-quand-on-a-recu-des-fonds",
                            "_blank"
                          )
                        }
                      >
                        {t("danapay_bank_transfer_instruction_1")}
                      </li>
                      <li
                        className=" underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                        onClick={() =>
                          window.open(
                            "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215915-retirer-ses-fonds-vers-un-compte-mobile",
                            "_blank"
                          )
                        }
                      >
                        {t("danapay_bank_transfer_instruction_2")}
                      </li>
                      <li
                        className="underline text-blue-600 underline text-blue-600 hover:text-blue-800 hover:cursor-pointer"
                        onClick={() =>
                          window.open(
                            "https://intercom.help/cartezi-technology-5ce69f6b7243/fr/articles/9215918-retirer-ses-fonds-vers-un-compte-bancaire",
                            "_blank"
                          )
                        }
                      >
                        {t("danapay_bank_transfer_instruction_3")}
                      </li>
                    </ul>
                  </div>
                </p>
              )}

            <button
              type="button"
              style={{
                backgroundColor: "rgb(3, 115, 117)",
                width: "200px",
                padding: "10px",
                borderRadius: 5,
              }}
              className="cursor-pointer md:text-base btn text-sm"
              onClick={() =>
                status == "successful" ? close() : cancel_payment()
              }
              disabled={cancelling}
            >
              <small className="font-bold text-white">
                {!cancelling ? t("backHome") : t("cancelling")}
              </small>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayLine;
