const SelectBankModal = (props: any) => {
  const {
    t,
    selectedBankState,
    setBankIndex,
    setselectedBankState,
    bankIndex,
    setSelectedBank,
    setActiveStep,
    dana_pay_bank_accounts,
  } = props;
  return (
    <div className="flex justify-center 5542">
      <div className="p-4 shadow-lg col-md-8 max-w-[600px] col-11">
        <div className="my-4 mb-4 text-center">
          <h3 className="text-2xl font-bold">{t("payment_instruction")}</h3>
          <p>{t("pi_text")}</p>
        </div>
        <>
          <div className="bacnkCheckBoxWrapper flex flex-col gap-2 m-0 p-0 p-2 mb-4">
            {selectedBankState.length > 0 &&
              selectedBankState.map((bank: any, index: number) => {
                return (
                  <div className="wrapperBankCheckBox m-0 innerBankBox">
                    <div className="wrapperBankCheckBoxwrapper">
                      <input
                        type="checkbox"
                        className="default:ring-2"
                        onChange={(e: any) => {
                          setBankIndex(index);
                          setselectedBankState((prev: any) =>
                            prev.map((itm: any, ind: any) => {
                              if (ind == index) {
                                return {
                                  ...itm,
                                  checked: true,
                                };
                              } else {
                                return {
                                  ...itm,
                                  checked: false,
                                };
                              }
                            })
                          );
                        }}
                        name={bank.bank_name}
                        checked={bank.checked}
                      />
                      <span className="checkmark"></span>
                    </div>
                    <label>{bank.bank_name}</label>
                  </div>
                );
              })}
          </div>
          <button
            onClick={() => {
              if (bankIndex !== null) {
                setSelectedBank(dana_pay_bank_accounts[bankIndex]);

                setActiveStep(3);
              }
            }}
            className="w-full rounded-md btn"
            style={{
              backgroundColor: "rgb(3, 115, 117)",
            }}
          >
            <small className="mx-10 font-bold text-white">
              {t("continue")}
            </small>
          </button>
        </>
      </div>
    </div>
  );
};

export default SelectBankModal;
