import React, { useState, useEffect } from "react";
import TransactionsCard from "../components/TransactionsCard/TransactionsCard";
import {
  downloadCSV,
  downloadCSVFile,
  getAllTransfers,
  getTransfers,
  handle_search,
} from "../store/features/Transfer/Transfer";
import * as Sentry from "@sentry/browser";
import _, { filter } from "lodash";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import useResize from "../hooks/useResize";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import {
  cleanObject,
  performCSVDownload,
  performCSVDownloadnew,
} from "../utilities/help";
import { toast } from "material-react-toastify";
import DataTable from "react-data-table-component";
import { statues, ots } from "../../src/components/TransactionListItem/data";
import moment from "moment";
import Country_comp from "../components/Country_comp";
import CashLayout from "../components/CashLayout";
import StatusButtons from "../components/StatusButtons";
import { setSelected } from "../store/features/Transfer/TransferSlice";
import "react-datepicker/dist/react-datepicker.css";
import { extractError } from "../utility";
import SearchBar from "material-ui-search-bar";
import DownloadCSVModal from "../components/History/DownloadCSVModal";
import HistoryFilterModal from "../components/History/HistoryFilterModal";

const icons: any = {
  All: "fa-square",
  Transfer: "fa fa-paper-plane-o",
  "Bulk Transfer": "fa fa-flash",
  Deposits: "fa fa-plus",
  Withdraws: "fa fa-minus",
};

const types_map: any = {
  Withdraws: "cashout",
  Deposits: "cashin",
  "Bulk Transfer": "bulk_transfer",
  Transfer: "transfer",
  All: "all",
};

// const menu: any = ["All", "Transfer", "Bulk Transfer", "Deposits", "Withdraws"];

const History: React.FC = () => {
  const { t } = useTranslation();
  const {
    transfer,
    auth: { favorites, user },
    transfer: { loggedInUserCountry },
  } = useAppSelector((state) => state.persistedReducer);
  const menu: any =
    user?.is_company_owner === true
      ? ["All", "Transfer", "Bulk Transfer", "Deposits", "Withdraws"]
      : ["All", "Transfer", "Deposits", "Withdraws"];
  const dispatch = useAppDispatch();
  const [w, h] = useResize();
  const [open, setIsOPen] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("All");
  const [filters, setFilters] = useState<any>({
    from: null,
    to: null,
    contact: null,
    country: null,
    type: null,
    search: null,
    contact_id: null,
    transferType: null,
    page: 1,
    per_page: 10,
  });
  const [data, setData] = useState({
    names: [],
    countries: [],
    pay_methods: [],
  });
  const [transactions, setTransactions] = useState(transfer.transfers);
  const [transactions_clone, setTransactionsClone] = useState(
    transfer.transfers
  );
  const [downloading, setDownloading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [configCSV, setConfigCSV] = useState("default");
  const [searched, setSearched] = useState<any>("");
  const [selectedCountryReset, setSelectedCountryReset] =
    useState<boolean>(false);

  // new server pagination
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (pageNumber: any) => {
    getAllUserTransfer(pageNumber, perPage);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    getAllUserTransfer(page, newPerPage);
  };

  // set all data according to conditions for sorting purposes
  const setAllDataForTransactions = (data: any) => {
    try {
      setTransactions(() =>
        data.map((itm: any) => {
          return {
            ...itm,

            tableStartDate:
              itm?.logged_in_transactor === "receiver"
                ? itm?.transfer?.started_at !== undefined &&
                  itm?.transfer?.started_at !== null
                  ? moment(itm?.transfer?.started_at).format()
                  : itm?.transfer?.started_at == null &&
                    itm?.transfer?.done_at !== null
                  ? moment(itm?.transfer?.created_at).format()
                  : "-"
                : itm?.started_at !== undefined && itm?.started_at !== null
                ? moment(itm?.started_at).format()
                : itm?.started_at == null && itm?.done_at !== null
                ? moment(itm?.created_at).format()
                : "-",

            tableCompletedDate:
              itm?.transfer?.done_at !== undefined &&
              itm?.transfer?.done_at !== null
                ? moment(itm?.transfer?.done_at).format()
                : "-",
          };
        })
      );
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // offline search filter
  useEffect(() => {
    try {
      const filtered = transactions_clone?.filter((item: any) => {
        const bulkTotal =
          item?.operation_type === "bulk_transfer"
            ? item?.transfer?.bulk_transfer_recipient_details.reduce(
                (sum: number, user: any) =>
                  sum + parseFloat(user.amount_in_euros),
                0
              )
            : item?.transfer?.amount_without_fee === undefined
            ? item?.amount_without_fee
            : item?.transfer?.amount_without_fee;
        const fullName =
          item?.operation_type === "bulk_transfer" &&
          item?.transfer?.bulk_transfer_recipient_details.filter(
            (valueFullName: any) => {
              const FullName =
                valueFullName.last_name.toLowerCase().toString() +
                " " +
                valueFullName.first_name.toLowerCase().toString();
              if (FullName.includes(searched.toLowerCase().toString())) {
                return valueFullName;
              }
            }
          );
        return item?.operation_type
          ?.toLowerCase()
          .replace("_", " ")
          .includes(searched.toLowerCase())
          ? item?.operation_type
              ?.toLowerCase()
              .replace("_", " ")
              .includes(searched.toLowerCase())
          : moment(item?.created_at)
              ?.format("DD/MM/YYYY H:mm:ss")
              .includes(searched)
          ? moment(item?.created_at)
              ?.format("DD/MM/YYYY H:mm:ss")
              .includes(searched)
          : Number(bulkTotal)
              .toFixed(2)
              .toString()
              ?.includes(searched.toString())
          ? Number(bulkTotal)
              .toFixed(2)
              .toString()
              ?.includes(searched.toString())
          : item?.destination_user?.country
              ?.toLowerCase()
              .toString()
              .includes(searched.toString().toLowerCase())
          ? item?.destination_user?.country
              ?.toLowerCase()
              .toString()
              .includes(searched.toString().toLowerCase())
          : item?.logged_in_transactor === "receiver" &&
            item?.operation_type === "direct_transfer"
          ? item?.source_user?.full_name
              ?.toLowerCase()
              .toString()
              .includes(searched.toString().toLowerCase())
          : item?.destination_user?.full_name
              ?.toLowerCase()
              .toString()
              .includes(searched.toString().toLowerCase())
          ? item?.destination_user?.full_name
              ?.toLowerCase()
              .toString()
              .includes(searched.toString().toLowerCase())
          : item?.destination_user?.full_phone_number?.includes(searched)
          ? item?.destination_user?.full_phone_number?.includes(searched)
          : item?.status
              ?.toLowerCase()
              .replace("_", " ")
              .includes(searched.toLowerCase())
          ? item?.status
              ?.toLowerCase()
              .replace("_", " ")
              .includes(searched.toLowerCase())
          : `${item?.transfer?.bulk_transfer_recipient_details?.length} Beneficiaries`.includes(
              searched
            )
          ? `${item?.transfer?.bulk_transfer_recipient_details?.length} Beneficiaries`.includes(
              searched
            )
          : fullName?.length > 0 && fullName;
      });
      if (filtered !== undefined && filtered.length > 0) {
        return setAllDataForTransactions(filtered);
      } else {
        setTransactions([]);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [searched]);

  const get_all_data = () => {
    try {
      const pay_methods = [
        ...loggedInUserCountry?.cash_in_methods,
        ...loggedInUserCountry?.cashout_methods,
      ]?.map((val: any) => {
        if (val?.cash_in_method) {
          return val?.cash_in_method?.payment_type?.name;
        } else {
          return val?.cashout_method?.payment_type?.name;
        }
      });

      const receiving_countries = loggedInUserCountry?.receiving_countries?.map(
        (item: any) => item?.receiving_country.name
      );
      const contacts =
        favorites &&
        favorites
          .map((fav: any) => fav?.favorite?.full_name)
          .filter((val: string) => val.trim() !== "")
          .sort();

      setData((prev: any) => {
        return {
          ...prev,
          countries: receiving_countries,
          pay_methods: pay_methods !== undefined ? pay_methods : [],
          names: contacts !== undefined ? contacts : [],
        };
      });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // paginated api call
  const getAllUserTransfer = (page: number, limit: number, reset?: string) => {
    setLoading(true);

    try {
      // dont append type if not present
      if (
        filters.transferType == null ||
        filters.transferType == "all" ||
        (reset !== undefined && reset == "reset")
      ) {
        getTransfers(page, limit)
          .then((res: any) => {
            setAllDataForTransactions(res.data);
            setTransactionsClone(res.data);
            setTotalRows(res.total);

            setPerPage(limit);
            setPage(page);
          })
          .catch((error: any) => {
            setSearching(false);
            toast.warning(t(error?.data?.message));
            Sentry.captureException(error);

            // getTransfers(page, 8).then((res) => setTransactions(res.data));
          })
          .finally(() => setLoading(false));
      } else {
        const params = `transferType=${filters.transferType}&page=${page}&per_page=${limit}`;
        handle_search(params)
          .then((res: any) => {
            setAllDataForTransactions(res.data);
            setTransactionsClone(res.data);
            setTotalRows(res.total);

            setPerPage(limit);
            setPage(page);
          })
          .catch((error: any) => {
            setSearching(false);
            toast.warning(t(error?.data?.message));
            Sentry.captureException(error);

            // getTransfers(page, 8).then((res) => setTransactions(res.data));
          })
          .finally(() => setLoading(false));
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const filter_by_type = (val: any) => {
    try {
      setSearching(true);
      setValue(types_map[val]);
      setFilters((prev: any) => {
        return { ...prev, transferType: types_map[val] };
      });
      apply_filters({ ...filters, transferType: types_map[val] });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const search = (term: string) => {
    try {
      setSearched(term);
      //transactions_clone
      if (term === "") {
        setTransactions(transactions_clone);
        return;
      }

      const filter_transactions = transactions_clone.filter((value: any) => {
        // flatten the object for each transfer
        const flat_obj = {
          ...value,
          ...value.destination_user,
          ...value.source_user,
          ...value.transfer,
        };

        // get only the values from the object and remove any duplicates
        const values_only = new Set(Object.values(flat_obj));

        //remove the null values in the array
        // check if the term exists in the values_only array...
        const index = Array.from(values_only)?.findIndex((element: any) => {
          if (element === null) {
            return false;
          } else {
            // convert all elements to string
            // check if any value is a substring
            if (typeof element === "string") {
              //you can also use indexes return element.toString()?.toLowerCase() === term?.toLowerCase();
              return element?.toLowerCase().includes(term?.toLowerCase());
            } else {
              return false;
            }
          }
        });

        return index > -1;
      });

      if (filter_transactions.length === 0) {
        const func = _.debounce(() => {
          setSearching(true);
          // this is disabled not used
          handle_search(`search=${term}`)
            .then((response) => {
              setSearching(false);
              setAllDataForTransactions(response.data);
            })
            .catch((error: any) => {
              setSearching(false);
              toast.warning(t(error?.data?.message));
              Sentry.captureException(error);
            });
        }, 1000);

        func();
      } else {
        setAllDataForTransactions(filter_transactions);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const apply_filters = (filterData: any) => {
    try {
      const { contact, country, to, from, type, transferType } = filterData;

      let user_id = null;
      if (contact !== null) {
        const user = favorites.find(
          (fav: any) =>
            fav.favorite.full_name?.toLowerCase() === contact?.toLowerCase()
        );
        user_id = user?.favorite.id;
      }

      // Removes al the  keys with null and All as the values....
      const payload = cleanObject({
        contact: user_id,
        country,
        from:
          filters?.from !== null
            ? moment(filters?.from).format("YYYY-MM-DD")
            : null,
        to:
          filters?.to !== null
            ? moment(filters?.to).format("YYYY-MM-DD")
            : null,
        type,
        transferType: transferType !== "all" ? transferType : null,
        page: 1,
        per_page: 10,
      });

      const params = new URLSearchParams(payload);
      setSearching(true);
      handle_search(params.toString())
        .then((response: any) => {
          setSearching(false);
          setAllDataForTransactions(response.data);
          setTransactionsClone(response.data);

          setTotalRows(response.total);

          setPage(1);
          setPerPage(10);
        })
        .catch((error: any) => {
          setSearching(false);
          toast.error(extractError(error));

          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const renderMenu = () => {
    if (w < 1000) {
      return (
        <div className="flex flex-row justify-around">
          {menu.map((val: any, index: any) => (
            <div
              onClick={() => filter_by_type(val)}
              key={val + index}
              className={`cursor-pointer flex-1 flex justify-center item-center p-3 mx-3 hover-class`}
              style={{
                backgroundColor: value === types_map[val] ? "#037375" : "#eee",
                color: value === types_map[val] ? "#fff" : "#aaa",
              }}
            >
              <i className={`m-0 fa ${icons[val]}`} />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <>
          {menu.map((val: any, index: any) => (
            <span
              key={val + index + "1"}
              onClick={() => filter_by_type(val)}
              className={` pb-1 font-bold rounded-lg cursor-pointer hover-class ${
                w < 1000 ? "px-2 mx-2" : "px-4 mx-1"
              }`}
              style={{
                backgroundColor:
                  value === types_map[val]
                    ? "#037375"
                    : w < 1000
                    ? "#fff"
                    : "#eee",
                color: value === types_map[val] ? "#fff" : "#222",
                borderRadius: 100,
                height: w < 1000 ? 50 : 20,
                width: w < 1000 ? 50 : 20,
              }}
            >
              {w < 1000 ? (
                <i className={`m-0 fa ${icons[val]}`} />
              ) : (
                <small style={{ fontSize: 10 }}>{t(val)}</small>
              )}
            </span>
          ))}
        </>
      );
    }
  };

  const download_csv_file = async () => {
    try {
      const payload: any = {
        type: filters?.type?.toLowerCase() ?? "",
        country: filters?.country ?? "",
        from:
          filters?.from !== null
            ? moment(filters?.from).format("YYYY-MM-DD")
            : "",
        to:
          filters?.to !== null ? moment(filters?.to).format("YYYY-MM-DD") : "",
        contact: filters?.contact_id ?? "",
        configCSV,
        transferType:
          filters?.transferType !== "all" ? filters?.transferType : "",
      };

      for (let val in payload) {
        if (payload[val]?.trim() === "" || payload[val]?.trim() == "All") {
          delete payload[val];
        }
      }

      if (payload.transferType === null) {
        delete payload.transferType;
      }

      setDownloading(true);
      // const response_data: any = await downloadCSV(payload);
      // if (typeof response_data === "object" && "success" in response_data) {
      //   toast.error(response_data.response);
      //   setDownloading(false);
      //   return;
      // } else {
      try {
        const newreponse: any = await downloadCSVFile(payload);
        const filnameFromResponse = newreponse?.headers["content-disposition"]
          ?.split("filename=")
          ?.filter((item: any) => item.toString().includes("."))
          .join("");
        performCSVDownloadnew(newreponse.data, filnameFromResponse);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setDownloading(false);
        setDownloading(false);
        setOpenDownloadModal(false);
        return;
      }
      // }
      // performCSVDownload(response_data, "danapay", configCSV);
      setDownloading(false);
      setOpenDownloadModal(false);
      toast.success(t("download_done"));
    } catch (error: any) {
      toast.error(t("error_downloading"));
      setDownloading(false);
    }
  };

  useEffect(() => {
    getAllUserTransfer(page, perPage);
  }, []);

  useEffect(() => {
    get_all_data();
  }, []);

  const OpenModal = (paymentId: any) => {
    dispatch(setSelected(paymentId));
  };

  const columns: any = [
    {
      selector: (row: any) => {
        return (
          <div className="cursor-pointer" onClick={() => OpenModal(row)}>
            {row?.logged_in_transactor === "receiver" &&
            row?.operation_type === "direct_transfer" ? (
              <span
                className="flex items-center justify-center bg-orange-200"
                style={{ height: 35, width: 35, borderRadius: 20 }}
              >
                <i
                  className={
                    row?.transfer?.bulk_transfer_recipient_details?.length > 0
                      ? "fa fa-flash  m-0 text-green-500"
                      : `fa fa-arrow-down  m-0 text-orange-500`
                  }
                ></i>
              </span>
            ) : row?.logged_in_transactor === "sender" &&
              row?.operation_type === "direct_transfer" ? (
              <span
                className="flex items-center justify-center bg-green-200"
                style={{ height: 35, width: 35, borderRadius: 20 }}
              >
                <i
                  className={
                    row?.transfer?.bulk_transfer_recipient_details?.length > 0
                      ? "fa fa-flash  m-0 text-green-500"
                      : `fa fa-arrow-up  m-0 text-green-500`
                  }
                ></i>
              </span>
            ) : row.operation_type === "instant_transfer" ? (
              <>
                {user?.id === row?.source_user?.id ? (
                  <span
                    className="flex items-center justify-center bg-green-200"
                    style={{ height: 35, width: 35, borderRadius: 20 }}
                  >
                    <i className={`fa fa-arrow-up  m-0 text-green-500`}></i>
                  </span>
                ) : (
                  <span
                    className="flex items-center justify-center bg-orange-200"
                    style={{ height: 35, width: 35, borderRadius: 20 }}
                  >
                    <i className={`fa fa-arrow-down  m-0 text-orange-500`}></i>
                  </span>
                )}
              </>
            ) : (
              <span
                className="flex items-center justify-center bg-green-200"
                style={{ height: 35, width: 35, borderRadius: 20 }}
              >
                <i
                  className={`${
                    ots[row?.operation_type]?.icon
                  } m-0 text-green-500`}
                ></i>
              </span>
            )}
          </div>
        );
      },
    },
    {
      name: "Type",
      sortable: true,
      selector: (row: any) =>
        row?.logged_in_transactor === "receiver" &&
        (row?.operation_type === "direct_transfer" ||
          row?.operation_type === "instant_transfer")
          ? t("Received_transfer")
          : t(
              row?.operation_type === "instant_transfer"
                ? "InstantPayment"
                : row?.operation_type === "withdraw"
                ? "payout"
                : row?.transfer?.bulk_transfer_recipient_details?.length > 0
                ? "bulkPayment"
                : row?.operation_type == "campaign_referral_reward"
                ? t("Campaign_Reward")
                : row?.operation_type
            ),
      cell: (row: any) => {
        return (
          <p
            className="customDesign"
            style={{ width: "100%" }}
            onClick={() => OpenModal(row)}
          >
            {row?.logged_in_transactor === "receiver" &&
            (row?.operation_type === "direct_transfer" ||
              row?.operation_type === "instant_transfer")
              ? t("Received_transfer")
              : t(
                  row?.operation_type === "instant_transfer"
                    ? "InstantPayment"
                    : row?.operation_type === "withdraw"
                    ? "payout"
                    : row?.transfer?.bulk_transfer_recipient_details?.length > 0
                    ? "bulkPayment"
                    : row?.operation_type == "campaign_referral_reward"
                    ? t("Campaign_Reward")
                    : row?.operation_type
                )}
          </p>
        );
      },
    },
    // start at
    {
      name: t("starting_date"),
      minWidth: "172px",
      selector: (row: any) => row?.tableStartDate,
      cell: (row: any) => {
        return (
          <p
            style={{ width: "172px" }}
            className="capitalize customDesign"
            onClick={() => OpenModal(row)}
          >
            {row?.tableStartDate == "-"
              ? "-"
              : moment(row?.tableStartDate).format("DD/MM/YYYY   H:mm:ss")}
          </p>
        );
      },
      sortable: true,
    },
    // completed at
    {
      name: t("complete_date"),
      grow: 2,
      minWidth: "172px",
      sortable: true,
      selector: (row: any) => row?.tableCompletedDate,
      cell: (row: any) => {
        return (
          <p
            style={{ width: "100%" }}
            className="capitalize customDesign"
            onClick={() => OpenModal(row)}
          >
            {row?.tableCompletedDate == "-"
              ? "-"
              : moment(row?.tableCompletedDate).format("DD/MM/YYYY   H:mm:ss")}
          </p>
        );
      },
    },
    {
      name: t("Country"),
      selector: (row: any) =>
        row?.destination_user === null
          ? row?.transfer?.bulk_transfer_recipient_details[0]?.country
          : row?.destination_user?.country,
      cell: (row: any) => {
        return (
          <p
            className="customDesign"
            style={{ width: "100%" }}
            onClick={() => OpenModal(row)}
          >
            <Country_comp
              eng_country_name={
                row?.destination_user === null
                  ? row?.transfer?.bulk_transfer_recipient_details &&
                    row?.transfer?.bulk_transfer_recipient_details[0]?.country
                  : row?.destination_user?.country
              }
            />
          </p>
        );
      },
      sortable: true,
    },
    {
      name: t("Contact"),
      selector: (row: any) =>
        row?.logged_in_transactor === "receiver" &&
        (row?.operation_type === "direct_transfer" ||
          row?.operation_type === "instant_transfer")
          ? row?.source_user?.full_name?.trim().toLowerCase()
          : row?.destination_user === null
          ? row?.transfer?.bulk_transfer_recipient_details === undefined
            ? 0 + " Beneficiaries"
            : row?.transfer?.bulk_transfer_recipient_details?.length +
              " Beneficiaries"
          : row?.destination_user?.full_name?.trim().toLowerCase() ||
            row?.destination_user?.full_phone_number,
      cell: (row: any) => {
        return (
          <p
            style={{ width: "100%" }}
            className="capitalize customDesign"
            onClick={() => OpenModal(row)}
          >
            {row?.logged_in_transactor === "receiver" &&
            (row?.operation_type === "direct_transfer" ||
              row?.operation_type === "instant_transfer")
              ? row?.source_user?.full_name?.trim().toLowerCase()
              : row?.destination_user === null
              ? row?.transfer?.bulk_transfer_recipient_details === undefined
                ? 0 + " Beneficiaries"
                : row?.transfer?.bulk_transfer_recipient_details?.length +
                  " Beneficiaries"
              : row?.destination_user?.full_name?.trim().toLowerCase() ||
                row?.destination_user?.full_phone_number}
          </p>
        );
      },
      sortable: true,
    },
    {
      name: t("amount"),
      selector: (row: any) =>
        row?.operation_type === "bulk_transfer"
          ? row?.transfer?.bulk_transfer_recipient_details.reduce(
              (sum: number, user: any) =>
                sum + parseFloat(user.amount_in_euros),
              0
            )
          : row?.transfer?.amount_without_fee === undefined
          ? row?.amount_without_fee
          : row?.transfer?.amount_without_fee,
      cell: (row: any) => {
        return (
          <p className="customDesign" onClick={() => OpenModal(row)}>
            <CashLayout
              cash={
                row?.operation_type === "bulk_transfer"
                  ? row?.transfer?.bulk_transfer_recipient_details.reduce(
                      (sum: number, user: any) =>
                        sum + parseFloat(user.amount_in_euros),
                      0
                    )
                  : row?.transfer?.amount_without_fee === undefined
                  ? row?.amount_without_fee
                  : row?.transfer?.amount_without_fee
              }
              exchange_rate={row?.exchange_rate_applied}
            />
          </p>
        );
      },
      sortable: true,
    },
    {
      name: t("status"),
      wrap: true,
      minWidth: "172px",
      sortable: true,
      selector: (row: any) =>
        row?.status === "suspicious" ? t("suspicious") : t(row?.status),
      cell: (row: any) => {
        return (
          <small
            className="px-2 mt-1 capitalize font-semiBold badge rounded-pill"
            style={{
              fontSize: 10,
              color:
                statues[
                  row.status.toLowerCase() === "suspicious"
                    ? "transfer_in_progress"
                    : row.status.toLowerCase()
                ] &&
                statues[
                  row.status.toLowerCase() === "suspicious"
                    ? "transfer_in_progress"
                    : row.status.toLowerCase()
                ]?.text,
              backgroundColor:
                statues[
                  row.status.toLowerCase() === "suspicious"
                    ? "transfer_in_progress"
                    : row.status.toLowerCase()
                ] &&
                statues[
                  row.status.toLowerCase() === "suspicious"
                    ? "transfer_in_progress"
                    : row.status.toLowerCase()
                ]?.bg,
            }}
            onClick={() => OpenModal(row)}
          >
            {row?.aborted == true
              ? t("aborted")
              : row?.status === "suspicious"
              ? t("suspicious")
              : t(row?.status)}
          </small>
        );
      },
    },
    {
      minWidth: "172px",
      selector: (row: any) => {
        return (
          <p style={{ width: "16%" }}>
            <StatusButtons transfer={row} transcationsObject={transfer} />
          </p>
        );
      },
    },
  ];

  const onDateChange = (text: any, e: any) => {
    if (text === "from") {
      setFilters((prev: any) => {
        return { ...prev, from: e };
      });
    } else {
      setFilters((prev: any) => {
        return { ...prev, to: e };
      });
    }
  };

  const cancelSearch = () => {
    setSearched("");
    apply_filters(filters);
  };

  // custom data table sorting
  const customSort = (rows: any, selector: any, direction: any) => {
    try {
      return rows.sort((rowA: any, rowB: any) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        const aField = selector(rowA);
        const bField = selector(rowB);

        const isDateAField = new Date(aField).toString();
        const isDateBField = new Date(bField).toString();

        let comparison = 0;

        // sorting for date
        if (
          isDateAField !== "Invalid Date" &&
          isDateBField !== "Invalid Date"
        ) {
          const data1 = new Date(aField);
          const data2 = new Date(bField);
          if (data1 < data2) {
            comparison = -1;
          } else {
            comparison = 1;
          }
          return direction === "desc" ? comparison * -1 : comparison;
        } else if (
          isDateAField !== "Invalid Date" &&
          isDateBField == "Invalid Date"
        ) {
          comparison = -1;
          return comparison;
        } else if (
          isDateAField == "Invalid Date" &&
          isDateBField !== "Invalid Date"
        ) {
          comparison = 1;
          return comparison;
        }

        // normal sorting
        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }

        return direction === "desc" ? comparison * -1 : comparison;
      });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        height: "100vh",
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <div className="mb-3">
        <div className="flex flex-row items-center mt-2">
          <div className="w-full">
            <div style={{ margin: "1rem" }}>
              <SearchBar
                className="searchUploadCustome gray-datepicker"
                value={searched}
                onChange={(e: any) => setSearched(e)}
                onCancelSearch={() => cancelSearch()}
              />
            </div>
          </div>
          <div>
            <a
              onClick={() => {
                setIsOPen(true);
              }}
              className="flex flex-row items-center px-4 py-2 mx-2 rounded-md cursor-pointer"
              style={{
                backgroundColor: "rgb(3, 115, 117)",
                color: "#fff",
                height: 40,
              }}
            >
              <i className="m-0 fa fa-filter" style={{ fontSize: 10 }} />
              <small
                className="ml-2 text-xs font-bold"
                style={{ fontSize: 11 }}
              >
                {t("filter")}
              </small>
            </a>
          </div>
        </div>
        <div className="flex flex-row justify-between w-full my-3">
          <div>{renderMenu()}</div>
          <div>
            <a
              className="mx-3 cursor-pointer"
              onClick={() => setOpenDownloadModal(true)}
            >
              {w < 1000 ? (
                ""
              ) : (
                <small className="font-bold text-gray-600">
                  {t("download_CSV")}
                </small>
              )}
              <i
                className="m-0 mx-2 fa fa-download"
                style={{ fontSize: 12, color: "#037375" }}
              />
            </a>
            {/* <a className="mx-3 cursor-pointer">
              {w < 1000 ? (
                ""
              ) : (
                <small className="font-bold text-gray-600">
                  {t("download_receipts")}
                </small>
              )}
              <i
                className="m-0 mx-2 fa fa-file-pdf-o"
                style={{ fontSize: 12, color: "#037375" }}
              />
            </a> */}
          </div>
        </div>
      </div>
      <div>
        {searching ? (
          <div
            className="flex flex-col items-center justify-center"
            style={{ height: 300 }}
          >
            <CircularProgress size={30} />
          </div>
        ) : (
          <>
            {/* <TransactionsCard
              transactions={transactions}
              fetchNext={() => setPage((prev) => prev + 1)}
              fetchPrevious={() => setPage((prev) => prev - 1)}
              title="All Transactions"
              page={page}
            /> */}

            <DataTable
              columns={columns}
              data={transactions}
              pagination
              paginationServer
              progressPending={loading}
              progressComponent={
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ height: 300 }}
                >
                  <CircularProgress size={30} />
                </div>
              }
              pointerOnHover={true}
              onRowClicked={(row: any) => OpenModal(row)}
              sortFunction={customSort}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
            {/* <div className="my-3">
              {total > 0 && (
                <Pagination
                  currentPage={page}
                  itemsPerPage={8}
                  onPageChange={(pageNumber) => handlePageChange(pageNumber)}
                  totalItems={total}
                  pageNeighbours={1}
                  customClassNames={{
                    rpbItemClassName: "page-item",
                    rpbItemClassNameActive: "item--active",
                  }}
                />
              )}
            </div> */}
          </>
        )}
      </div>

      {/* filter modal */}
      <HistoryFilterModal
        t={t}
        open={open}
        onClose={() => setIsOPen(!open)}
        fromDate={filters?.from}
        toDate={filters?.to}
        onDateChange={onDateChange}
        setFilters={setFilters}
        filters={filters}
        apply_filters={apply_filters}
        setSelectedCountryReset={setSelectedCountryReset}
        data={data}
        favorites={favorites}
        setValue={setValue}
        getAllUserTransfer={getAllUserTransfer}
        perPage={perPage}
      />
      {/* download csv modal */}
      <DownloadCSVModal
        open={openDownloadModal}
        t={t}
        setData={setConfigCSV}
        value={configCSV}
        onClick={download_csv_file}
        downloading={downloading}
        onCancel={() => setOpenDownloadModal(false)}
      />
    </div>
  );
};

export default History;
