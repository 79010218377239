import React, { useState, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  cancelTransfer,
  completeTLPayment,
  currentBalance,
} from "../../store/features/Transfer/Transfer";
import {
  addBeneficiary,
  openBulkTransferDialog,
  openDeposit,
  setBeneFound,
  setStep,
  setTLError,
  setTransactionToRepeat,
  setTransferDialogOpen,
  updateBulkTData,
} from "../../store/features/Transfer/TransferSlice";
import { translateError } from "../../utilities/help";
import { current } from "@reduxjs/toolkit";
import {
  editUser,
  loginUser,
  resetAuth,
  updateBalance,
} from "../../store/features/Auth/AuthSlice";
import { toast } from "material-react-toastify";

const TrueLayer = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(2);
  const [processing, setProcessing] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const { transferResult } = useAppSelector(
    (state) => state.persistedReducer.transfer
  );

  const history = useHistory();
  const steps = [
    t("beneficiary"),
    t("Amount"),
    t("payment"),
    t("Confirmation"),
  ];

  const finishPayment = (code: string) => {
    const payload = {
      payment_id: transferResult?.payment?.id,
      code,
    };
    completeTLPayment(payload)
      .then((res: any) => {
        window.location?.replace(res.connect_url);
      })
      .catch((error: any) => {
        toast.warning(error?.data?.message);
        Sentry.captureException(error);
        dispatch(setTLError(translateError(error.data.message, t)));
        dispatch(openBulkTransferDialog("close"));
        dispatch(updateBulkTData({ users: {} }));
        history.push("/dashboard/home");
      });
  };

  const redirect_to_client_site = () => {
    const local_payment = localStorage.getItem("payment_details");
    const payment_details = local_payment ? JSON.parse(local_payment) : null;
    const ext_pay_id = localStorage.getItem("payment_id");
    const ext_link = localStorage.getItem("ext_mm_process_link");
    localStorage.removeItem("appref");

    if (ext_pay_id || ext_link || payment_details) {
      localStorage.removeItem("payment_id");
      localStorage.removeItem("ext_mm_process_link");
      localStorage.removeItem("payment_details");
      localStorage.removeItem("user:key");
      dispatch(resetAuth());
      window.location?.replace(
        `${payment_details?.data?.redirect_url}?payment_id=${payment_details.id}`
      );
    } else {
      dispatch(setBeneFound(false));
      dispatch(addBeneficiary(null));
      dispatch(setStep(0));
      dispatch(setTransferDialogOpen(false));
      dispatch(openBulkTransferDialog("close"));
      dispatch(updateBulkTData({ users: {} }));
      dispatch(openDeposit(false));
      history.push("/dashboard/home");
    }
  };

  //force login user ... bug somewhere in this file that logout a user on redirect.
  const login_user = () => {
    setProcessing(true);
    currentBalance()
      .then((res: any) => {
        dispatch(updateBalance(res.client.euro_balance));
        dispatch(editUser(res));
        dispatch(
          loginUser({
            user: res,
            access_token: localStorage.getItem("user:key"),
          })
        );
        redirect_to_client_site();
      })
      .catch((error: any) => {
        toast.warning(error?.data?.message);
        Sentry.captureException(error);
      });
  };

  const backHome = () => {
    login_user();
  };

  useEffect(() => {
    const code = new URLSearchParams(search).get("code");
    const payment_id: any = new URLSearchParams(search).get("payment_id");
    const error = new URLSearchParams(search).get("error");
    if (code) {
      finishPayment(code);
    } else if (payment_id && !error) {
      setActiveStep(3);
    } else if (error) {
      if (error === "access_denied") {
        // Access denied for any cancellation by the user.
        const online_payment_id: any =
          localStorage.getItem("online_payment_id");
        setCancelling(true);
        cancelTransfer(online_payment_id)
          .then((res) => {
            localStorage.removeItem("online_payment_id");
            history.push("/dashboard/home");
            setCancelling(false);
          })
          .catch((error: any) => {
            setCancelling(false);
            toast.warning(error?.data?.message);
            Sentry.captureException(error);
          });
      } else {
        dispatch(setTLError(t("payment_failed_error")));
      }
    }
  }, []);

  return (
    <div>
      <div
        className="flex flex-row items-center justify-between px-4 py-3"
        style={{ backgroundColor: "rgb(3, 115, 117)" }}
      >
        <p className="font-bold text-white">Instant Transfer</p>
        {processing ? (
          <p>Processing...</p>
        ) : (
          <a onClick={backHome} className="font-bold text-white cursor-pointer">
            Close
          </a>
        )}
      </div>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 2 && (
        <div className="py-10 mx-4 row">
          <div className="col-md-4"></div>
          <div className="py-10 text-center shadow-lg col-md-4">
            <p className="py-2 text-4xl font-bold text-center">
              {t("pending_title")}
            </p>
            <p className="py-2 text-sm text-gray-600">
              {t("pending_title_description")}
            </p>
            <p className="py-10 text-sm text-blue-300">{t("pending_footer")}</p>
          </div>
          <div className="col-md-4"></div>
        </div>
      )}

      {activeStep === 3 && (
        <div className="p-10 mx-4 row">
          <div className="col-md-4"></div>
          <div className="p-10 text-center shadow-md shadow-lg col-md-4 rounded-2">
            <p className="py-2 text-4xl font-bold text-center">
              {t("Your_payment_has_been_title")}
            </p>
            <p className="py-2 text-sm text-gray-600">
              {t("Your_payment_has_been")}
            </p>
            <br />
            {cancelling ? (
              <p>{t("cancelling")}</p>
            ) : (
              <a
                onClick={backHome}
                className="px-4 py-2 text-white cursor-pointer fon4t-bold btn btn-sm"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
              >
                <small className="text-white">{t("backHome")}</small>
              </a>
            )}
          </div>
          <div className="col-md-4"></div>
        </div>
      )}
    </div>
  );
};

export default TrueLayer;
