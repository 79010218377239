import CashLayout from "../CashLayout";
import * as Sentry from "@sentry/browser";

const WithdrawAmountModal = (props: any) => {
  const {
    size,
    steps,
    t,
    userBalance,
    toWithDraw,
    setToWithDraw,
    euro_balance,
    rate,
    fees,
    currency,
    handleNext,
    user,
    toast,
    loading,
  } = props;

  const showBalanceError = () => {
    try {
      const balance = parseFloat(euro_balance) * rate;
      let cash = toWithDraw;

      if (currency !== "CFA") {
        cash = parseFloat(toWithDraw) * rate;
      }

      if (balance === 0) {
        return (
          <small className="text-red-600" style={{ fontSize: 10 }}>
            {t("with_0_balance")}
          </small>
        );
      } else if (cash > balance) {
        return (
          <small className="text-red-600" style={{ fontSize: 10 }}>
            {t("expectAmount")} {(parseFloat(euro_balance) * rate).toFixed(2)}
          </small>
        );
      }
    } catch (error: any) {
      Sentry.captureException("error:===>", error);
    }
  };

  return (
    <>
      {size[0] < 900 && (
        <div className="py-4 col-md-12">
          <h2>{steps[0]}</h2>
        </div>
      )}
      <div className="text-center mx-auto col-md-6 p-10 my-10 rounded-lg shadow-lg">
        <div className="my-2">
          <small> {t("Available_outstanding_balance")}</small>
        </div>
        <CashLayout cash={userBalance} />

        <h2 className="my-4 text-sm text-gray-600 font-regular">
          {t("type_withdraw_amount")}
        </h2>

        <div className="text-left col-md-12 col-sm-12 col-xs-12">
          <div className="flex flex-row items-center p-1 border rounded">
            <input
              type="number"
              className="flex flex-1 border-0 form-control focus:outline-none"
              placeholder={t("Type_amount")}
              onChange={(e: any) => setToWithDraw(e.target.value)}
              onBlur={(e: any) => setToWithDraw(e.target.value)}
              value={toWithDraw}
            />
            <a
              onClick={() => {
                const value = parseFloat(euro_balance) * rate;
                setToWithDraw(value);
              }}
              className="px-3"
            >
              <small
                className="font-bold text-green-600"
                style={{ color: "rgb(3, 115, 117)" }}
              >
                {t("Withdraw_All")}
              </small>
            </a>
          </div>

          {showBalanceError()}
          <div className="flex flex-row justify-between pt-2 my-2 text-[12.8px]">
            {t("fees")}
            <div>
              {(fees * rate).toFixed(2)} {currency}
            </div>
          </div>
          <hr />

          <div className="flex flex-row justify-between my-2">
            {t("total")}
            <p className="font-bold" style={{ color: "rgb(3, 115, 117)" }}>
              {(fees * rate + parseFloat(toWithDraw)).toFixed(2)} {currency}
            </p>
          </div>
        </div>

        <button
          className="px-10 mt-3 mb-4 rounded-lg btn text-white leading-6 text-[12.6px] font-bold"
          style={{ backgroundColor: "rgb(3, 115, 117)" }}
          onClick={() => {
            try {
              const userHas =
                currency === "CFA"
                  ? parseFloat(euro_balance) * rate
                  : parseFloat(euro_balance);
              const convertedFee = currency === "CFA" ? fees * rate : fees;
              const withdrawing = parseInt(toWithDraw) + convertedFee;

              if (userHas >= withdrawing) {
                handleNext();
                window.heap.track("Withdraw details", {
                  email: user.email,
                  country: user.country,
                  phone: user.full_phone_number,
                  name: user.full_name,
                });
              } else {
                toast.error(t("insufficient"));
              }
            } catch (error: any) {
              Sentry.captureException(error);
            }
          }}
          disabled={parseFloat(toWithDraw) <= 0 || toWithDraw === "" || loading}
        >
          {t("ConfirmCashout")}
        </button>
      </div>
    </>
  );
};

export default WithdrawAmountModal;
