import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ls from "@livesession/sdk";
import * as Sentry from "@sentry/react";
import package_json from "../package.json";

ls.init("e5cf5e65.eaf5a12f", { keystrokes: true });
ls.newPageView();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  maxBreadcrumbs: 50,
  debug: true,
  environment: process.env.REACT_APP_ENV,
  release: package_json.version || "1.0.0-dev",
  enabled: process.env.REACT_APP_ENV !== "Development",
});

if (process.env.REACT_APP_ENV == "Production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
