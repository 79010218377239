import React from "react";

const DeliveryWithdrawBox = (props: any) => {
  const { isDeliveryPayoutPresent, setwidthdrawMethod, widthdrawMethod, t } =
    props;
  return (
    <>
      {isDeliveryPayoutPresent && (
        <>
          <div className="wrapperBankCheckBox m-0 innerBankBox">
            <div className="wrapperBankCheckBoxwrapper">
              <input
                type="checkbox"
                className="default:ring-2"
                onChange={(e) => {
                  e.target.checked && setwidthdrawMethod("delivery");
                }}
                name="Micro finance"
                checked={widthdrawMethod == "delivery"}
              />
              <span className="checkmark"></span>
            </div>
            <label>{t("delivery")}</label>
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryWithdrawBox;
