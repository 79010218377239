import React, { useState, useEffect } from "react";
import bg from "../../images/danapay_login.png";
import bg_en from "../../images/danapay_login_en.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import * as Sentry from "@sentry/browser";
import {
  resetAuth,
  saveUserFavorites,
  setAccounts,
  setSignUpData,
  updateDanaPayBankAccounts,
} from "../../store/features/Auth/AuthSlice";
import LangSwitch from "../../components/LangSwitch";
import { useTranslation } from "react-i18next";
import "../../helpers/i18n";
import { toast } from "material-react-toastify";

import {
  setTransferFromType,
  setExternalApiPaymentId,
  fetchTransferRecords,
  updateCountries,
  addLoggedInUserCountry,
} from "../../store/features/Transfer/TransferSlice";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import GoogleCaptchaVerfication from "./GoogleCaptchaVerfication";
import { customerIoIdentify } from "../../utilities/customeriohelpers";
import { Tab, Tabs } from "@material-ui/core";
import LoginWithEmail from "../../components/Login/LoginWithEmail";
import LoginWithPhone from "../../components/Login/LoginWithPhone";
import { isValidData } from "../../utilities/help";
import SumsubRejectModal from "../../components/Sumsub/SumsubRejectModal";

const Login = () => {
  const { t } = useTranslation();
  const {
    auth: { user, access_token, isLoggedIn, lang, refreshCaptcha },
    transfer: { danaPayCountries },
  } = useAppSelector((state: any) => state.persistedReducer);
  const [errorMessage, showErrorMessage] = useState<any>([]);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const queryURL = new URLSearchParams(search).get("payment_id");
  const email = new URLSearchParams(search).get("email");
  const [captchaToken, setCaptchaToken] = useState<any>("");

  useEffect(() => {
    if (queryURL) {
      dispatch(setTransferFromType("external_api"));
      dispatch(setExternalApiPaymentId(queryURL));
      if (isLoggedIn && access_token && user) {
        history.push("/complete_form");
      } else {
        // clean local storage
      }
    } else {
      localStorage.removeItem("user:key");
      dispatch(resetAuth());
      dispatch(fetchTransferRecords([]));
      dispatch(saveUserFavorites([]));
      dispatch(updateCountries([[], []]));
      dispatch(addLoggedInUserCountry(null));
      dispatch(setAccounts([]));
      dispatch(updateDanaPayBankAccounts([]));
    }
  }, []);

  const setLoggedUserCountry = (user_info: any) => {
    try {
      const danapayCountries = danaPayCountries.find(
        (cc: any) => cc?.country_code === user_info?.country_code
      );
      if (danapayCountries !== undefined) {
        dispatch(addLoggedInUserCountry(danapayCountries));
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  // set signupdata if any user data found
  const setRegistrationData = (user: any) => {
    if (user !== undefined && user !== null) {
      let userProfile: any = {};
      if (isValidData(user?.first_name)) {
        userProfile["first_name"] = user?.first_name;
      }
      if (isValidData(user?.last_name)) {
        userProfile["last_name"] = user?.last_name;
      }
      if (isValidData(user?.address_line)) {
        userProfile["address_line"] = user?.address_line;
      }
      if (isValidData(user?.city)) {
        userProfile["city"] = user?.city;
      }
      if (isValidData(user?.first_name)) {
        userProfile["first_name"] = user?.first_name;
      }
      if (isValidData(user?.activity_id)) {
        userProfile["activity_id"] = user?.activity_id;
      }
      if (isValidData(user?.job)) {
        userProfile["job"] = user?.job;
      }
      if (isValidData(user?.monthly_revenue)) {
        userProfile["monthly_revenue"] = user?.monthly_revenue;
      }
      if (isValidData(user?.company)) {
        userProfile["company"] = user?.company;
      }
      if (isValidData(user?.meta)) {
        userProfile["meta"] = user?.meta;
      }
      if (isValidData(user?.country_code)) {
        userProfile["country_code"] = user?.country_code;
      }
      if (isValidData(user?.residency)) {
        userProfile["residency"] = user?.residency;
      }
      if (isValidData(user?.phone_number)) {
        userProfile["phone_number"] = user?.phone_number;
      }

      if (Object.keys(userProfile).length > 0) {
        dispatch(setSignUpData(userProfile));
      }
    }
  };

  useEffect(() => {
    // Your effect

    return () => {
      // Cleanup
    };
  }, []);

  const handleHeapAndCustomerioEvents = (result: any, data: any) => {
    window.heap.track("Logged In", {
      email: result?.user?.email,
    });
    customerIoIdentify({
      id: result?.user?.id,
      email: result?.user?.email,
      first_name: result?.user?.first_name,
      last_name: result?.user?.last_name,
      "User Type":
        result.user?.is_individual &&
        result.user?.client.type !== "temporary-customer"
          ? "Individual"
          : result.user?.is_company_owner
          ? "Company"
          : "Undefined",
    });
    if (
      result.user?.is_individual &&
      result.user?.client.type !== "temporary-customer"
    ) {
      window.heap.track("Has logged in as an individual", {
        email: result?.user?.email,
      });
    } else if (result.user?.is_company_owner) {
      window.heap.track("Has logged in as a company", {
        email: result?.user?.email,
      });
    }
    if (result?.access_token) {
      window?.heap?.identify(data?.email, "email");
    }
  };

  // login type
  const [loginType, setloginType] = useState(0);

  const handleLoginType = (event: React.ChangeEvent<{}>, newValue: number) => {
    setloginType(newValue);
  };
  const [isUserRejected, setisUserRejected] = useState(false);

  return (
    <div className="w-screen h-screen m-0 overflow-hidden row">
      <SumsubRejectModal
        open={isUserRejected}
        onClick={() => (window.location.href = "https://www.danapay.com")}
      />

      <div className="h-full p-0 col-md-5">
        <div className="flex flex-row justify-between pt-4 pl-4">
          <LangSwitch />
        </div>
        <div className="flex flex-col px-6 py-4 m-auto justify-center h-screen sm:py-1 sm:px-4 md:py-1 md:px-4 lg:px-10 lg:py-4">
          {/* <div
            className="px-6 py-4 m-auto bg-white rounded-lg"
            style={{ width: "95%" }}
          > */}
          {email && (
            <div className="p-2 mb-10 text-orange-700 bg-orange-100">
              <p>
                <small>{t("sent_credentials")}</small>
              </p>
              <p>
                <small className="font-bold">{email}</small>
              </p>
            </div>
          )}

          <h2 className="mb-2 text-2xl font-bold" style={{ width: "80%" }}>
            {t("welcome")}
          </h2>
          <p className="mb-8 text-base">{t("loginText")}</p>

          <div className="mb-4">
            <Tabs
              value={loginType}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#037375",
                },
              }}
              onChange={handleLoginType}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <Tab
                style={{ textTransform: "none" }}
                label={
                  <div className="md:text-base text-sm">{t("login_email")}</div>
                }
              />
              <Tab
                style={{ textTransform: "none" }}
                label={t("login_phone")}
                className="md:text-base text-sm"
              />
            </Tabs>
          </div>

          {loginType == 0 && (
            <LoginWithEmail
              t={t}
              setisUserRejected={setisUserRejected}
              showErrorMessage={showErrorMessage}
              captchaToken={captchaToken}
              dispatch={dispatch}
              setLoggedUserCountry={setLoggedUserCountry}
              history={history}
              toast={toast}
              handleHeapAndCustomerioEvents={handleHeapAndCustomerioEvents}
              setCaptchaToken={setCaptchaToken}
              refreshCaptcha={refreshCaptcha}
              errorMessage={errorMessage}
              yup={yup}
              setRegistrationData={setRegistrationData}
            />
          )}
          {loginType == 1 && (
            <LoginWithPhone
              t={t}
              setisUserRejected={setisUserRejected}
              showErrorMessage={showErrorMessage}
              captchaToken={captchaToken}
              dispatch={dispatch}
              setLoggedUserCountry={setLoggedUserCountry}
              history={history}
              toast={toast}
              handleHeapAndCustomerioEvents={handleHeapAndCustomerioEvents}
              setCaptchaToken={setCaptchaToken}
              refreshCaptcha={refreshCaptcha}
              errorMessage={errorMessage}
              yup={yup}
              setRegistrationData={setRegistrationData}
            />
          )}

          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
          >
            <GoogleCaptchaVerfication
              parentCallBack={(e: any) => setCaptchaToken(e)}
            />
          </GoogleReCaptchaProvider>
          <div className="mt-10 text-center">
            <small>
              {t("click_here_to")}
              <Link
                to="/join"
                className="font-bold rounded"
                style={{ color: "rgb(3, 115, 117)" }}
                data-register="register"
              >
                {t("register")}
              </Link>
            </small>
          </div>
        </div>
      </div>

      <div
        className="h-full p-0 shadow-lg col-md-7"
        style={{
          backgroundImage: `url(${lang === "en" ? bg_en : bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};

export default Login;
