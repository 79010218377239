import { useDispatch } from "react-redux";
import {
  addBeneficiary,
  addTransferCountry,
  setBeneFound,
} from "../../store/features/Transfer/TransferSlice";
import { toast } from "material-react-toastify";
import { buildString } from "../../utilities/help";
import { addContact } from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import { useState } from "react";

const AddBeneficiaryWithPhone = (props: any) => {
  const {
    t,
    country,
    phone,
    firstName,
    lastName,
    setFirstName,
    setLastName,
    transfer,
    setEmail,
    email,
    loading,
    setPhone,
    setCountry,
    typeOfUser,
    contacts_details,
    company_contacts_details,
    setLoading,
    setEmailError,
    emailError,
  } = props;
  const dispatch = useDispatch();

  const removeBeneficiary = () => {
    dispatch(addBeneficiary(null));
    dispatch(addTransferCountry(null));
    dispatch(setBeneFound(false));
    setPhone("");
    setCountry({ dialCode: "" });
  };

  const createNewContact = () => {
    try {
      const selectedUser: any = typeOfUser.find((user: any) => user.active);

      const { first_name, last_name, email } = contacts_details;
      const {
        company_name,
        first_name: company_first_name,
        last_name: company_last_name,
        email: company_email,
      } = company_contacts_details;

      const contact_country = transfer.danaPayCountries.find(
        (val: any) => val.country_code === country["dialCode"]
      );

      if (
        (contact_country?.preferred_notification_channel === "mail" &&
          !/^\S+@\S+\.\S+$/.test(email) &&
          selectedUser.value == "individual") ||
        (contact_country?.preferred_notification_channel === "mail" &&
          !/^\S+@\S+\.\S+$/.test(company_email) &&
          selectedUser.value == "business")
      ) {
        toast.error(t("email_not_valid"));
        return;
      } else {
        setEmailError("");
      }

      if (
        (selectedUser.value == "individual" &&
          (last_name.length === 0 || first_name.length === 0)) ||
        (selectedUser.value == "business" &&
          (company_first_name.length === 0 ||
            company_last_name.length === 0 ||
            company_name.length === 0))
      ) {
        toast.error(t("fields_required"));
        return;
      }

      let payload_data: any;

      if (selectedUser.value == "individual") {
        payload_data = {
          first_name,
          last_name,
          email,
          is_individual: true,
          country_code: country["dialCode"],
          phone_number: buildString(phone) || "",
        };
      } else {
        payload_data = {
          first_name: company_first_name,
          last_name: company_last_name,
          email: company_email,
          company_name,
          is_individual: false,
          country_code: country["dialCode"],
          phone_number: buildString(phone) || "",
        };
      }

      if (!payload_data["email"]) {
        delete payload_data["email"];
      }
      setLoading(true);
      addContact(payload_data)
        .then((data: any) => {
          setLoading(false);
          dispatch(setBeneFound(true));
          dispatch(addBeneficiary(data.beneficiary));
          dispatch(addTransferCountry(data.transferCountry));
        })
        .catch((error: any) => {
          Sentry.captureException(error);
          setLoading(false);
          if (error.status === 422 || error.status === 500) {
            const err: any = Object.values(error.data.errors)[0];
            toast.error(err[0]);
          }
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-3"></div>
      <div className="col-md-6">
        <div className="flex flex-col items-center justify-center">
          <h3 className="my-2 text-2xl font-bold">{t("beneficiary")}</h3>
          <p className="text-center">
            <b>+{`${country["dialCode"]}${phone}`}</b> {t("text_tell_us_more")}
          </p>
          <br />
          <br />
        </div>

        <div className="p-4 my-4 shadow-md border-1">
          <label>
            <small>{t("FirstName")}</small>
          </label>
          <input
            type="text"
            className="mb-2 form-control "
            onChange={(e: any) => setFirstName(e.target.value)}
            value={firstName}
          />
          <label>
            <small>{t("LastName")}</small>
          </label>

          <input
            type="text"
            className="mb-2 form-control"
            onChange={(e: any) => setLastName(e.target.value)}
            value={lastName}
          />

          <label>
            <small>
              {t("email")}{" "}
              {transfer.preferredComChannel === "sms" && (
                <span>[{t("optional")} ]</span>
              )}
            </small>
          </label>
          <input
            type="email"
            required={transfer.preferredComChannel === "mail"}
            className="mb-2 form-control"
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          {emailError.length > 0 && (
            <small className="text-red-600">{emailError}</small>
          )}
        </div>
        {loading ? (
          <button
            className="w-full rounded-lg btn btn-dark"
            disabled
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
          >
            <span className="text-white capitalize">{t("loading")}...</span>
          </button>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <button
                className="w-full rounded-lg btn btn-dark"
                onClick={() => removeBeneficiary()}
              >
                {t("cancel")}
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="w-full rounded-lg btn"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                onClick={() => createNewContact()}
                data-transfer-payment-details="paymentdetails"
              >
                <span
                  className="text-white capitalize"
                  data-transfer-payment-details="paymentdetails"
                >
                  {t("continue")}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-3"></div>
    </div>
  );
};

export default AddBeneficiaryWithPhone;
