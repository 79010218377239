import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import NotActiveComp from "../../components/NotActiveComp";
import "../../helpers/i18n";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { toast } from "material-react-toastify";
import * as yup from "yup";
import PhoneNumberModal from "../../components/Profile/PhoneNumberModal";
import AccountsInfo from "../../components/Profile/AccountsInfo";
import UserInfo from "../../components/Profile/UserInfo";
import CompanyInfo from "../../components/Profile/CompanyInfo";
import AccountsScreen from "../../components/Profile/AccountsScreen";
import EmailUpdateModal from "../../components/Profile/EmailUpdateModal";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tabs: any = {
  true: [
    {
      name: "user_information",
      key: "user_info",
    },
    {
      name: "accounts_information",
      key: "acc_info",
    },
  ],
  false: [
    {
      name: "user_information",
      key: "user_info",
    },
    {
      name: "accounts_information",
      key: "acc_info",
    },
    {
      name: "comp_info",
      key: "company_info",
    },
  ],
};

const Profile = () => {
  const { t } = useTranslation();
  const {
    auth: {
      user,
      bank_accounts,
      mobile_accounts,
      mfi_accounts,
      selected_account,
      dana_pay_bank_accounts,
    },
    transfer: { loggedInUserCountry },
  } = useAppSelector((state) => state.persistedReducer);
  const [optModalOpen, setOptModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [size, setSize] = useState([0, 0]);
  const [hasMMOption, setHasMMOption] = useState(false);
  const [dpOpen, setDPOpen] = useState(false);
  const [accountScreenOpen, setaccountScreenOpen] = useState(false);
  const [accountType, setAccountType] = useState<any>(null);
  const [otp_code, setOtpCode] = useState("");
  const [email_otp_code, setEmailOtpCode] = useState("");
  const [dphkey, setDphKey] = useState("");
  const [email_address, setEmailAddress] = useState(user?.email);
  const [selectedCountry, setSelectedCountry] = useState<any>(user?.country);
  const [selectedCountryReset, setSelectedCountryReset] =
    useState<boolean>(false);
  const [new_phone, setNewPhone] = useState(() => {
    if (user?.phone_number) {
      return user?.phone_number;
    } else {
      return "";
    }
  });

  const [tabValue, setTabValue] = useState({
    name: "user_information",
    key: "user_info",
  });

  const [country, setCountry] = useState(() => {
    if (user?.country_code) {
      return user?.country_code;
    } else {
      return "";
    }
  });

  const dispatch = useAppDispatch();

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    setHasMMOption(() => {
      const find = loggedInUserCountry?.cashout_methods?.find((method: any) => {
        return method?.cashout_method?.payment_type.name === "mobile_money";
      });
      return find ? true : false;
    });
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <div className="lg:mx-3 sm:mx-1 md:mx-1">
        <NotActiveComp
          showLoader={false}
          t={t}
          setWithDrawOpen={null}
          startBulkPayments={null}
          startTransfer={null}
          size={size}
        />
        <div>
          <div className="flex flex-row items-center py-2">
            {tabs[user?.is_individual.toString()] &&
              tabs[user?.is_individual.toString()].map(
                (tab: any, index: number) => (
                  <a
                    key={index}
                    style={{
                      fontSize: 12,
                      borderBottomColor:
                        tab.key === tabValue.key ? "rgb(3, 115, 117)" : "#eee",
                      borderBottomWidth: 2,
                      color:
                        tab.key === tabValue.key ? "rgb(3, 115, 117)" : "#555",
                    }}
                    className="pb-2 mr-10 capitalize cursor-pointer"
                    onClick={() => setTabValue(tab)}
                  >
                    <b>{t(tab.name)}</b>
                  </a>
                )
              )}
          </div>
          {/* <div className="p-2 pt-4 overflow-y-scroll "> */}
          {tabValue.key === "user_info" && (
            <UserInfo
              t={t}
              user={user}
              dispatch={dispatch}
              toast={toast}
              yup={yup}
              setCountry={setCountry}
              new_phone={new_phone}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
              setNewPhone={setNewPhone}
              setSelectedCountryReset={setSelectedCountryReset}
              setDphKey={setDphKey}
              country={country}
              dphkey={dphkey}
              setOptModalOpen={setOptModalOpen}
            />
          )}

          {/* accounts tab */}
          {tabValue.key === "acc_info" && (
            <AccountsInfo
              t={t}
              openAccountScreen={() => setaccountScreenOpen(true)}
              setAccountType={setAccountType}
              mfi_accounts={mfi_accounts}
              hasMMOption={hasMMOption}
              mobile_accounts={mobile_accounts}
              bank_accounts={bank_accounts}
              dana_pay_bank_accounts={dana_pay_bank_accounts}
              setDPOpen={setDPOpen}
            />
          )}

          {tabValue.key === "company_info" && !user?.is_individual && (
            <CompanyInfo
              t={t}
              user={user}
              yup={yup}
              country={country}
              toast={toast}
              dispatch={dispatch}
            />
          )}
          {/* </div> */}
        </div>
      </div>

      {/* accounts modal */}
      <AccountsScreen
        onClose={() => setaccountScreenOpen(false)}
        open={accountScreenOpen}
        onDPClose={() => setDPOpen(false)}
        Transition={Transition}
        selected_account={selected_account}
        dpOpen={dpOpen}
        accountType={accountType}
        dana_pay_bank_accounts={dana_pay_bank_accounts}
      />

      {/* update phone modal */}
      <PhoneNumberModal
        t={t}
        open={optModalOpen}
        onClose={() => setOptModalOpen(false)}
        otp_code={otp_code}
        setOtpCode={setOtpCode}
        toast={toast}
        country={country}
        new_phone={new_phone}
        dispatch={dispatch}
        setEmailOtpCode={setEmailOtpCode}
        user={user}
      />

      {/* email field and update button commented out to operate this modal above */}
      <EmailUpdateModal
        open={emailModalOpen}
        email_otp_code={email_otp_code}
        t={t}
        user={user}
        email_address={email_address}
        setEmailOtpCode={setEmailOtpCode}
        toast={toast}
        dispatch={dispatch}
        onClose={() => setEmailModalOpen(!emailModalOpen)}
      />
    </>
  );
};

export default Profile;
