import { Alert } from "@material-ui/lab";
import Accounts from "../Accounts";
import * as Sentry from "@sentry/browser";

const WithdrawPaymentModal = (props: any) => {
  const {
    size,
    steps,
    t,
    checkingForRestriction,
    dispatch,
    setType,
    setMethod,
    type,
    startCashOut,
    isUserRestricted,
    setRepeatWithDraw,
    setSelectedBank,
    selected_account,
    cashoutMethod,
    loading,
    handlePrev,
    error,
    setCashoutMethod,
  } = props;

  const handleCashoutChange = (paymentType: any) => {
    try {
      setCashoutMethod((prev: any) => {
        return prev.map((prevItem: any) => {
          if (prevItem?.cashout_method?.payment_type?.name == paymentType) {
            return { ...prevItem, isChecked: true };
          } else {
            return { ...prevItem, isChecked: false };
          }
        });
      });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="py-4 my-4 mx-auto col-md-10 shadow-lg row">
      {size[0] < 900 && (
        <div className="py-4 col-md-12">
          <h2>{steps[1]}</h2>
        </div>
      )}
      <div className="text-center mx-auto col-md-6">
        <h2 className="text-xl font-semibold">{t("Enter_withdraw_Details")}</h2>
        <div className="py-2 row g-3 col-md-6 mx-auto col-sm-12 col-xs-12">
          <select
            disabled={checkingForRestriction}
            className={`border-b-2 rounded bg-white w-full mb-4 py-2`}
            onChange={(text) => {
              dispatch(setSelectedBank(null));
              const val = text.target.value.split("|");
              handleCashoutChange(val[1].toLowerCase());

              // set cashout method id and type on toggle of select
              setMethod(val[0]);
              setRepeatWithDraw(false);
              if (
                val[1].toLowerCase() === "manual_bank_transfer" ||
                val[1].toLowerCase() === "bank_transfer"
              ) {
                setType("bank");
              } else if (val[1].toLowerCase() === "mobile_money") {
                setType("mobile");
              } else if (val[1].toLowerCase() === "mfi") {
                setType("mfi");
              } else if (val[1].toLowerCase() === "delivery") {
                setType("delivery");
              }
            }}
          >
            {cashoutMethod &&
              cashoutMethod
                .filter((cashoutitm: any) => {
                  if (isUserRestricted) {
                    return (
                      cashoutitm?.cashout_method?.payment_type?.name ==
                        "bank_transfer" ||
                      cashoutitm?.cashout_method?.payment_type?.name ==
                        "manual_bank_transfer"
                    );
                  } else {
                    return true;
                  }
                })
                .map((opt: any, index: number) => (
                  <option
                    selected={opt.isChecked}
                    value={`${opt?.cashout_method?.id}|${opt?.cashout_method?.payment_type?.name}`}
                    key={index}
                  >
                    {t(
                      opt.cashout_method.name
                        .toLowerCase()
                        .replaceAll(" ", "_")
                        .replaceAll("_", " ")
                    )}
                  </option>
                ))}
          </select>
        </div>
      </div>

      {/* accounts ui */}
      {type === "bank" ? (
        <Accounts type="bank" selected_account={selected_account} />
      ) : type === "mobile" ? (
        <Accounts type="mobile" selected_account={selected_account} />
      ) : type === "mfi" ? (
        <Accounts type="mfi" selected_account={selected_account} />
      ) : (
        <div className="mx-2 lg:mx-4 md:mx-2 sm:mx-2 accountsdiv">
          <div className="flex flex-row items-center justify-center p-2 lg:mx-10 sm:mx-1 md:mx-2">
            <p style={{ color: "rgb(3, 115, 117)" }} className="font-bold">
              {t("delivery_cashout_banner")}
            </p>
          </div>
        </div>
      )}

      <div className="col-md-4 gap-3 mx-auto flex justify-center">
        <button
          className="px-4 mt-3 mb-4 border rounded-lg btn leading-6 text-[12.6px] font-bold"
          onClick={handlePrev}
        >
          {t("back")}
        </button>
        {loading ? (
          <button
            className="px-4 mt-3 mb-4 rounded-lg btn leading-6 text-[12.6px] text-white font-bold"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
          >
            {t("processing")}
          </button>
        ) : (
          <button
            className="px-4 mt-3 mb-4 rounded-lg btn leading-6 text-[12.6px] text-white font-bold"
            style={{ backgroundColor: "rgb(3, 115, 117)" }}
            onClick={() => {
              startCashOut();
            }}
          >
            {t("Send_and_Continue")}
          </button>
        )}
        {error && <Alert severity="error">{error}</Alert>}
      </div>
    </div>
  );
};

export default WithdrawPaymentModal;
