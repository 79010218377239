import { Form, Formik } from "formik";
import { editUser, setSignUpData } from "../../store/features/Auth/AuthSlice";
import CountryMultiSelect from "../CountryMultiSelect";
import FormErrorText from "../FormErrorText";
import {
  addUser,
  detachCompany,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import { isFromAfrica } from "../../utilities/help";
import { toast } from "material-react-toastify";

const IndividualTransferInfo = (props: any) => {
  const {
    t,
    transferReceivingCountries,
    countryListing,
    dispatch,
    signUpData,
    setprogressBarData,
    setActiveStep,
    Yup,
    settransferReceivingCountries,
    setresidencyLevelName,
    typeOfUser,
    user,
  } = props;

  const handleUserCreation = (data: any, setSubmitting: any, formData: any) => {
    try {
      setSubmitting(true);
      const usertype: any = typeOfUser.find((itm: any) => itm.active);
      let payload = { ...formData };
      delete payload.customJob;
      delete payload.company;

      const onSuccess = () => {
        setActiveStep(4);
        setprogressBarData({
          progress: 100,
          title: "documents_and_selfie",
        });
      };

      const onFail = (error: any) => {
        setSubmitting(false);
        toast.warning(t(error?.data?.message));
        Sentry.captureException(error);
      };

      // if company is attached, remove it
      if (
        (user?.company !== null && user?.company !== undefined) ||
        (signUpData?.company !== null && signUpData?.company !== undefined)
      ) {
        detachCompany()
          .then(() => {
            addUser(payload, usertype.value)
              .then((res: any) => {
                dispatch(editUser(res?.user));
                onSuccess();
              })
              .catch((error: any) => {
                onFail(error);
              })
              .finally(() => setSubmitting(false));
          })
          .catch((error: any) => {
            onFail(error);
          });
      } else {
        addUser(payload, usertype.value)
          .then((res: any) => {
            dispatch(editUser(res?.user));
            onSuccess();
          })
          .catch((error: any) => {
            onFail(error);
          })
          .finally(() => setSubmitting(false));
      }
    } catch (error: any) {
      setSubmitting(false);
      Sentry.captureException(error);
    }
  };

  const handleSubmit = (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const isUserAfricanResident = isFromAfrica(signUpData?.country_code);
      const receivingIds = countryListing
        .filter((country: any) => data.countrySelection.includes(country.name))
        .map((country: any) => country.id);

      // send user to sumsub if from africa else send to residency selection
      if (isUserAfricanResident) {
        setresidencyLevelName("WAEMU/CEMAC Person");
        dispatch(
          setSignUpData({
            ...signUpData,
            receiving_countries_ids: receivingIds,
            residency: "WAEMU/CEMAC Person",
          })
        );
        saveRegistrationProgress({
          ...signUpData,
          residency: "WAEMU/CEMAC Person",
          receiving_countries_ids: receivingIds,
        }).finally(() => {
          handleUserCreation(data, setSubmitting, {
            ...signUpData,
            receiving_countries_ids: receivingIds,
            residency: "WAEMU/CEMAC Person",
          });
        });
      } else {
        dispatch(
          setSignUpData({
            ...signUpData,
            receiving_countries_ids: receivingIds,
          })
        );
        saveRegistrationProgress({
          ...signUpData,
          receiving_countries_ids: receivingIds,
        }).finally(() => {
          setSubmitting(false);
          setprogressBarData({
            progress: 80,
            title: "residency_details",
          });
          setActiveStep("residenceSelection");
        });
      }
    } catch (error) {
      setSubmitting(false);
      Sentry.captureException(error);
    }
  };

  const validationSchema = Yup.object().shape({
    countrySelection: Yup.array()
      .min(1, "transfer_countries_required")
      .required("required"),
  });

  return (
    <div className="p-8 rounded-lg shadow-lg">
      <p className="mb-3">{t("transfer_countries")}</p>
      <Formik
        validateOnMount
        enableReinitialize
        key="individualTransferInfo"
        initialValues={{
          countrySelection: transferReceivingCountries,
        }}
        onSubmit={(data: any, { setSubmitting }) => {
          handleSubmit(data, setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, setFieldValue, isSubmitting, isValid }) => (
          <Form className="flex flex-col gap-3 company-registration">
            <div className="flex flex-col">
              <small className="mb-3 text-gray-700">
                {t("receiving_countries")}
              </small>
              <CountryMultiSelect
                selectedValues={transferReceivingCountries}
                placeholder={t("transfer_countries_placeholder")}
                list={countryListing}
                onChange={(e: any) => {
                  settransferReceivingCountries(e);
                  setFieldValue("countrySelection", e);
                }}
                customClasses="rounded-[12px] wrapperBankCheckBox innerBankBox bacnkCheckBoxWrapper wrapperBankCheckBox m-0"
              />
              {errors?.countrySelection && (
                <FormErrorText errorMessage={errors?.countrySelection} />
              )}
            </div>

            <div className="flex justify-between gap-3">
              <button
                className="flex-1 rounded-lg btn btn-block"
                style={{ backgroundColor: "#666" }}
                onClick={() => {
                  setprogressBarData({
                    progress: 40,
                    title: "revenue_information",
                  });
                  setActiveStep(2);
                }}
              >
                <span className="text-white capitalize md:text-md max-sm:!text-sm">
                  {t("back")}
                </span>
              </button>

              <button
                className="flex-1 rounded-lg btn btn-block"
                style={{ backgroundColor: "rgb(3, 115, 117)" }}
                disabled={isSubmitting || !isValid}
                type="submit"
                data-individual="individual"
              >
                <span
                  className="text-white capitalize md:text-md max-sm:!text-sm"
                  data-individual="individual"
                >
                  {t("next")}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IndividualTransferInfo;
